import { useModalContext } from '@/components/modal/ModalContext';
import { openConfirmDeleteDialog } from '@/components/shared/ConfirmDeleteDialog';
import { Dropdown } from '@/components/shared/Form/controls/Dropdown';
import { ToggleInlineButton } from '@/components/shared/FormButton/FormButton';
import { ScreenReaderOnly } from '@/components/shared/ScreenReaderOnly';
import {
  Table, TableCell, TableHeader, TableRow, TruncatingTableCell
} from '@/components/shared/Table';
import { useAsync } from '@/hooks/useAsync';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  deleteFilesAsync,
  FileCategory,
  FileModel,
  FileStatusOption,
  FileStatusOptionType,
  updateFileStatusAsync,
  useFileLink,
} from '@/services/fileServices';
import { Guid } from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { isValidFileExtension } from '@/utils/file';
import { IconButton, TruncatingText } from '@instech/components';
import {
  InfoCircle, Load, Trash
} from '@instech/icons';
import {
  FunctionComponent, useCallback, useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { InformationBox } from '@/components/shared/InformationBox';
import { AvailableInformationModalErrorHandler } from '../../core/AvailableInformationModalErrorHandler';
import {
  Field,
  FileMetaInformation, FileMetaInformationRow,
  InformationHeader,
} from '../../core/Components';
import { FileDownloadOrUpload } from '../../core/FileDownloadOrUpload';
import { NewAvailableForm } from '../../core/NewAvailableForm';
import {
  enclosed, getPiiText, transformFiles
} from '../../utils/availableInfoUtils';
import { UploadPlaceholderFileForm } from './UploadPlaceholderFileForm';

const IconWrapper = styled.div`
  display: flex;
  height: 40px;
  width: 42px;
  align-items: center;
  justify-content: center;
  & button {
    padding: 0;
  }`;

const ScrollAnchor = styled.div`
  position: relative;
  // The negative top position is neccessary to offset the padding and margin of the modal container.
  top: -70px;
`;

const InformationRowStyled = styled.div`
  background-color: ${props => props.theme.marineBlue20};
  grid-column: span 3;
  padding: 8px;
  border-left: ${props => `4px solid ${props.theme.marineBlue}`};
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${props => props.theme.marineBlue};
`;

const InformationBoxStyled = styled(InformationBox)`
  margin-bottom: 30px;
`;

interface InfoProps {
  item: FileModel;
  i: number;
  fileStatusList: FileStatusOption[];
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}
const AvailableInfoRow: FunctionComponent<InfoProps> = ({ item, i, isEditing, setIsEditing, fileStatusList }) => {
  const caseId = useCaseIdFromUrl();
  const { data } = useFileLink(item.id, item.isPlaceholderForRequestedFile);
  const { open } = useModalContext();

  const statusOptions = fileStatusList
    .filter(x => !x.requireFile === item.isPlaceholderForRequestedFile)
    .map(x => ({ id: x.status, value: x.name }));

  // Hide enclosed status for filetypes that are not supported
  const reducedOptions = statusOptions.filter(x => x.id !== enclosed);

  const closeUploadForm = useCallback(
    () => {
      setIsEditing(false);
    },
    [setIsEditing],
  );

  const {
    execute: deleteAvailableInformationRow,
    status,
    error
  } = useAsync(() => deleteFilesAsync(caseId, item.id), false);

  if (error) {
    throw new Error(`Deletion of file ${item.id} failed`);
  }

  async function updateFileStatus(newFileStatus: FileStatusOptionType) {
    if (newFileStatus === 'Enclosed' && !item.fileName.includes('.pdf')) {
      return;
    }
    await updateFileStatusAsync(caseId, item, newFileStatus);
  }

  return (
    <>
      <TableRow key={item.id} even={i % 2 === 0}>
        <TruncatingTableCell>
          <Field padding="0 10px 0 16px">
            <TruncatingText text={item.title} padding="0px 10px 0px 0px" />
            <FileDownloadOrUpload
              fileName={item.fileName}
              url={data?.url ?? ''}
              handleUpload={() => setIsEditing(true)}
            />
          </Field>
          <FileMetaInformationRow>
            <FileMetaInformation>{getPiiText(item.personallyIdentifiableInformation)}</FileMetaInformation>
            <FileMetaInformation>{`${item.uploadedBy} - ${getFriendlyDate(item.uploadedAt)}`}</FileMetaInformation>
          </FileMetaInformationRow>
        </TruncatingTableCell>
        <TableCell>
          <Dropdown
            name="availableInfoFileStatus"
            options={(!isValidFileExtension(item.fileName, ['.pdf']) ? reducedOptions : statusOptions) as any}
            selectedOption={item.status}
            onChange={e => updateFileStatus(e.target.value)}
            disabled={isEditing}
          />
        </TableCell>
        <TableCell button center>
          <IconWrapper>
            {status === 'pending'
              ? <IconButton icon={<Load width="22px" height="22px" />} width="22px" height="22px" />
              : (
                <IconButton
                  icon={<Trash width="22px" height="22px" />}
                  width="22px"
                  height="22px"
                  onClick={() => openConfirmDeleteDialog({ name: item.title, callback: deleteAvailableInformationRow, open })}
                />
              )}
          </IconWrapper>
        </TableCell>
      </TableRow>
      {isEditing && (
        <UploadPlaceholderFileForm
          handleClose={closeUploadForm}
          fileItem={item}
        />
      )}
    </>
  );
};

const OpenButton: FunctionComponent<{ marginTop?: string, onClick: () => any }> = ({ marginTop, onClick }) => (
  <ToggleInlineButton
    label="Available Information"
    onClick={onClick}
    marginTop={marginTop}
  />
);

const infoTexts = [
  `Relevant information should be listed here.
  Information with the status “Available” and “Enclosed” is uploaded to the portal and is available to the Claims Handler.
  “Enclosed” information will form an integrated part of your issued survey report
  and should only be deliberately chosen IF the enclosure is considered VITAL for the understanding of the Survey Report.`,

  `The status “Requested” is used when you are requesting relevant information. The claims handler can sort the “Requested”
  information to easily forward the request to Owners.`
];

interface Props {
  items: FileModel[];
  fileStatusList: FileStatusOption[];
  categories: FileCategory[];
}
export const AvailableInfoEditPage: FunctionComponent<Props> = ({ items = [], fileStatusList, categories }) => {
  const categoryData = transformFiles(items, categories);
  const [editingFileId, setEditingFileId] = useState<Guid | undefined>(undefined);
  const pageRef = useRef<any>(null);
  const [error, setError] = useState<Error>();
  const scrollToTop = useCallback(
    () => {
      if (pageRef.current?.scrollIntoView) {
        // eslint-disable-next-line no-unused-expressions
        pageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    [],
  );
  const [formIsOpen, setFormIsOpen] = useState(items.length === 0);

  useEffect(() => {
    if (formIsOpen) {
      scrollToTop();
    }
  }, [formIsOpen, scrollToTop]);

  if (error) {
    return <AvailableInformationModalErrorHandler error={error} clearError={() => setError(undefined)} />;
  }

  const showNewItemForm = formIsOpen || items.length === 0;

  const hasItems = items.length > 0;

  return (
    <>
      <div>
        <InformationBoxStyled paragraphs={infoTexts} />
        <ScrollAnchor ref={pageRef} />
        {!showNewItemForm && (
          <OpenButton onClick={() => setFormIsOpen(true)} />
        )}
        {showNewItemForm && (
          <NewAvailableForm
            categories={categories}
            fileStatusList={fileStatusList}
            handleClose={() => setFormIsOpen(false)}
            canClose={hasItems}
            setError={setError}
          />
        )}
      </div>
      {categoryData.map(({ category, containsEnclosed, files }) => (
        <div key={category}>
          <InformationHeader>{category}</InformationHeader>
          <Table layout="1fr 250px 56px">
            <TableHeader>Document name</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader><ScreenReaderOnly>Remove item</ScreenReaderOnly></TableHeader>
            {files.map((item, i) => (
              <AvailableInfoRow
                i={i}
                key={item.id}
                item={item}
                fileStatusList={fileStatusList}
                isEditing={editingFileId === item.id}
                setIsEditing={(value: boolean) => setEditingFileId(value ? item.id : undefined)}
              />
            ))}
            {containsEnclosed && (
              <InformationRowStyled>
                <InfoCircle />
                The document will be enclosed to your issued survey report and only to be used IF considered VITAL for the understanding of the Survey Report.
              </InformationRowStyled>
            )}
          </Table>
        </div>
      ))}
      {/** Only show this 2nd 'Add' button if there are 3 or more elements in list */}
      {items.length > 2 && <OpenButton onClick={() => setFormIsOpen(true)} />}
    </>
  );
};
