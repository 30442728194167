import { TabbedToolPane } from '@/components/shared/ToolPane/TabbedToolPane';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCase } from '@/services/dashboardServices';
import { useUserInfo } from '@/services/userInfoService';
import { UserInfo } from '@/types';
import { Loader } from '@instech/components';
import { FunctionComponent, Suspense } from 'react';
import styled from 'styled-components';
import { ClaimsHandlerDetails } from './core/ClaimsHandlerDetails';
import { SurveyorDetails } from './core/SurveyorDetails';

const StyledLoader = styled(Loader)`
  width: 100%;
  height: 100%;
`;

const claimsHandlerTab = (userInfo: UserInfo | undefined, readonly?: boolean) => ({
  title: 'Your Claims Handler',
  key: 'claims-handler-tab',
  component: (
    <ClaimsHandlerDetails
      contactInfo={userInfo}
      unassignedText="No claims handler currently assigned"
      buttonText="Immediate Feedback to Claims Handler"
      readonlyFeedback={readonly}
    />
  )
});

const surveyorsTab = () => ({
  title: 'Surveyors',
  key: 'surveyors-tab',
  component: (
    <SurveyorDetails />
  )
});

interface Props {
  readonly?: boolean;
}
export const AssignedUsersPane: FunctionComponent<Props> = ({ readonly = false }) => {
  const caseId = useCaseIdFromUrl();
  const { data: caseData, error: caseError } = useCase(caseId);
  const claimsHandlerId = caseData?.users?.primaryClaimsHandlerId;
  const { data: userInfo, error: userInfoError } = useUserInfo(claimsHandlerId);

  const loadingError = caseError || userInfoError;
  if (loadingError) {
    throw new Error(loadingError);
  }

  const loadingUsers = claimsHandlerId && !userInfo;
  if (loadingUsers) {
    return <StyledLoader />;
  }

  return (
    <Suspense fallback={<StyledLoader data-testid="assigned-users-pane-loader" />}>
      <TabbedToolPane
        tabs={[
          claimsHandlerTab(userInfo, readonly),
          surveyorsTab()
        ]}
      />
    </Suspense>
  );
};
