import { useModalContext } from '@/components/modal/ModalContext';
import { SlimButton } from '@instech/components';
import { FC } from 'react';
import { RowBackground } from '../../Components';
import { AddNewOccurrenceModalProps, showAddNewOccurrenceModal } from './AddNewOccurrenceModal';

const AddNewOccurrenceButton: FC<AddNewOccurrenceModalProps> = props => {
  const modal = showAddNewOccurrenceModal(props);
  const { open } = useModalContext();
  const openModal = () => open(modal);

  return (
    <RowBackground>
      <SlimButton onClick={openModal} disabled={!props.userCanEdit}>
        ADD NEW OCCURRENCE
      </SlimButton>
    </RowBackground>
  );
};

export default AddNewOccurrenceButton;
