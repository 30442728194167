import { CompanyParticular } from '@/types';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { FormRow } from '@instech/components';
import { Edit, Trash } from '@instech/icons';
import styled from 'styled-components';
import { ParticularField } from '../../core/ParticularField';

interface Props {
  item: CompanyParticular;
  onDelete: () => void;
  toggleEdit: () => void;
}

const Container = styled(FormRow)`
  margin-bottom: 0px;
  margin-right: -8px;
  padding-top: 6px;
`;

export const CompanyParticularRowView = ({ item, onDelete, toggleEdit }: Props) => (
  <Container key={item.id} marginBottom="12px">
    <ParticularField
      title={item.title}
      description={item.description}
      source={item.source} />
    <ContextMenu
      id={item.id}
      buttonColor="marineBlue"
      options={[
        {
          icon: <Edit />,
          label: 'Edit',
          onClick: toggleEdit
        },
        {
          icon: <Trash />,
          label: 'Delete',
          onClick: onDelete
        },
      ]} />
  </Container>
);
