import { ErrorComponentProps } from '@instech/components';
import { FC } from 'react';
import { ErrorView, ErrorViewButtons } from './core/ErrorView';
import { errorStatuses } from './types';

const errorMessage = `
  The page you were trying to reach at this address doesn't
  seem to exist. The link may be broken or the page may no longer
  be available. Try searching for your topic or go to your homepage.
`;

export const ErrorNotFound: FC<ErrorComponentProps> = ({ reset }) => (
  <ErrorView
    type={errorStatuses.notFound}
    title="Not found"
    message={errorMessage}>
    <ErrorViewButtons reset={reset} />
  </ErrorView>
);
