import { ExpandableThumbnail } from '@/components/shared/Image';
import { EditButton, RemoveButton } from '@/components/shared/LinkButton/LinkButtons';
import { ImagePreview, useImagePreviewById } from '@/services/imageServices';
import { Guid } from '@/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Reorder } from '@instech/icons';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $dragging: boolean }>`
  position: relative;
  display: flex;
  // 80% transparent background color as HEX value
  background-color: ${props => `${props.theme.lightBlue}CC`};
  padding: 10px 20px 10px 0px;
  ${props => props.$dragging && css`
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.09);
    z-index: 1;
  `};
`;

const ImageArea = styled.div`
  flex-shrink: 0;
`;

const TextArea = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  word-break: break-word;
  color: ${props => props.theme.marineBlue75};
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
`;

const Description = styled.span`
  display: block;
  margin-bottom: 10px;
`;

const ImageButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px -4px; 
  button {
    height: 40px;
  }
`;

const ReorderContainer = styled.div`
  display:flex;
  align-items: center;
`;

const ReorderButton = styled(Reorder) <{ $dragging: boolean }>`
  color: ${props => props.theme.marineBlue60};
  padding: 16px;
  cursor: ${props => props.$dragging ? 'grabbing' : 'grab'};
`;

interface ImageProps {
  id: Guid;
  onEdit: (image: ImagePreview) => void;
  onRemove: (image: ImagePreview) => void;
}
export const SelectedImage = ({ id, onEdit, onRemove }: ImageProps) => {
  const { data: image } = useImagePreviewById(id, 'small');
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const transformString = CSS.Translate.toString(transform);

  // Might want a loader here, but the data is almost guaranteed to be loaded already
  if (!image) {
    return null;
  }

  return (
    <Wrapper
      key={image.id}
      style={{ transition, transform: transformString }}
      $dragging={isDragging}
      {...attributes}
      ref={setNodeRef}>
      <ReorderContainer>
        <ReorderButton $dragging={isDragging} {...listeners} />
      </ReorderContainer>
      <ImageArea>
        <ExpandableThumbnail image={image} width="95px" height="95px" />
      </ImageArea>
      <TextArea>
        <Name>{image.title}</Name>
        <Description>
          {image.description.trim().length ? image.description : <i>No description</i>}
        </Description>
        <ImageButtonRow>
          <EditButton onClick={() => onEdit(image)} />
          <RemoveButton onClick={() => onRemove(image)} />
        </ImageButtonRow>
      </TextArea>
    </Wrapper>
  );
};
