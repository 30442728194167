import { Crop, useImagePreviewById } from '@/services/imageServices';
import { ImageView as ImageViewComponent } from '@instech/components';
import {
  ComponentProps, FunctionComponent, memo
} from 'react';

type ImageViewProps = Omit<ComponentProps<typeof ImageViewComponent>, 'image' | 'error'>;

interface Props extends ImageViewProps {
  imageId: string;
  crop: Crop;
}
export const ImageView: FunctionComponent<Props> = memo(({ imageId, crop, ...rest }) => {
  const { data: image, error } = useImagePreviewById(imageId, crop);

  return <ImageViewComponent image={image} error={error} {...rest} />;
});
