import { MoneyField } from '@/components/shared/Form/FormFields';
import { ClaimInterest } from '@/types';
import { TextField } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import { interestIsLOH } from './utils';

const TextFieldContainer = styled.div`
  grid-column: 1 / span 4;
  max-width: 400px;
`;

interface Props {
  name: string;
  label: string;
  isDisabled: boolean;
  interest: ClaimInterest;
}
export const InterestDeductibleField: FC<Props> = ({ name, label, isDisabled, interest }) => (
  <TextFieldContainer>
    {interestIsLOH(interest) ? (
      <TextField
        name={name}
        label={label}
        disabled={isDisabled}
        placeholder="Enter Deductible"
      />
    ) : (
      <MoneyField
        name={name}
        label={label}
        disabled={isDisabled}
        placeholder="Enter Deductible"
      />
    )}
  </TextFieldContainer>
);
