import { FunctionComponent } from 'react';
import { useSWRConfig } from 'swr';
import styled from 'styled-components';
import { Form } from 'formik';
import { Guid } from '@/types';
import {
  CompanyPdfDesign,
  companyPdfDesignApiRoute,
  updatePdfDesign,
  PdfColorProfile,
  PdfLogoAlignment,
  useCompanyPdfDesign
} from '@/services/companyPdfDesignServices';
import { PdfDesignPreview } from '@/components/shared/PdfDesign/PdfDesignPreview';
import {
  Loader, ButtonGroup, Pane,
  Dropdown, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { CloseButton, SaveButton } from '@/components/shared/FormButton/FormButton';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  padding-bottom: 32px;
`;

/* Helper component to vert-horiz center contents */

interface CompanyPdfDesignFormData {
  colorProfile: {
    label: string,
    value: PdfColorProfile,
  };
  logoAlignment: {
    label: string,
    value: PdfLogoAlignment,
  };
  footerText: string;
  logoId?: Guid;
}

const getInitialFormData = (data: CompanyPdfDesign): CompanyPdfDesignFormData => ({
  colorProfile: {
    label: data.colorProfile,
    value: data.colorProfile
  },
  footerText: data.footerText,
  logoAlignment: {
    label: data.logoAlignment,
    value: data.logoAlignment
  },
  logoId: data.logoId
});

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const colorProfileOptions = () => [
  {
    label: 'Standard colors',
    value: 'Standard',
  }
];
const ColorProfileField = () => (
  <Dropdown
    name="colorProfile"
    label="Color profile"
    options={colorProfileOptions()}
  />
);

const logoOptions = () => [
  {
    label: 'Standard - Right aligned',
    value: 'Right',
  },
  {
    label: 'Left aligned',
    value: 'Left',
  }
];
const LogoAlignmentField = () => (
  <Dropdown
    name="logoAlignment"
    label="Align logo"
    options={logoOptions()}
  />
);
const FooterTextField = () => (
  <TextField
    label="Footer text (optional)"
    name="footerText"
    placeholder="Footer text"
  />
);

const mapToPayload =
  (id: string | undefined, companyId: string, { colorProfile, logoAlignment, ...restFormValues }: CompanyPdfDesignFormData): CompanyPdfDesign => ({
    id,
    companyId,
    ...restFormValues,
    colorProfile: colorProfile.value,
    logoAlignment: logoAlignment.value
  });

export const PdfDesign: FunctionComponent<{ companyId: Guid }> = ({ companyId }) => {
  const { mutate } = useSWRConfig();
  const { data } = useCompanyPdfDesign(companyId);

  if (!data) {
    return <Loader />;
  }

  const initialFormData = getInitialFormData(data);

  return (
    <Pane title="PDF design" margin="32px 0px 0px">
      <Formik
        initialValues={{ ...initialFormData }}
        onSubmit={async (newValues, { resetForm }) => {
          const payload = mapToPayload(data.id, data.companyId, newValues);

          const response = await updatePdfDesign(payload);
          await mutate(`${companyPdfDesignApiRoute}/companies/${data.companyId}`, response.data, false);

          resetForm({ values: getInitialFormData(response.data) });
        }}>
        {({ values, handleSubmit, isSubmitting, dirty, resetForm }) => (
          <Form>
            <Layout>
              <LeftSide>
                <div>
                  <ColorProfileField />
                  <LogoAlignmentField />
                  <FooterTextField />
                </div>
              </LeftSide>
              <PdfDesignPreview
                colorProfile={values.colorProfile.value}
                footerText={values.footerText}
                logoAlignment={values.logoAlignment.value}
                companyId={companyId}
              />
            </Layout>
            <ButtonGroup alignRight>
              <CloseButton label="Reset" onClick={resetForm} disabled={!dirty} />
              <SaveButton
                onClick={handleSubmit}
                isSubmitting={isSubmitting}
                disabled={!dirty}
              />
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Pane>
  );
};
