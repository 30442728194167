import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  updateVesselInformationAsync, VesselInformation, vesselInformationApiUrl
} from '@/services/vesselInformationServices';
import { FunctionComponent } from 'react';
import { useSWRConfig } from 'swr';
import { VesselInformationFormInline } from '../shared/VesselInformationFormInline';
import { fields, validationSchema } from './core';

interface InformationProps extends VesselInformation {
  onToggleEdit: (id: string) => void;
}

interface Model {
  title: string;
  value: string;
  editable: boolean;
  includeInReport: boolean;
}

export const InformationRowEdit: FunctionComponent<InformationProps> = ({
  id,
  title,
  value,
  editable,
  includeInReport,
  onToggleEdit
}) => {
  const { mutate } = useSWRConfig();
  const caseId = useCaseIdFromUrl();

  const onSubmit = async (model: Model) => {
    const submitValues = { ...model, id };
    await updateVesselInformationAsync(caseId, submitValues);
    await mutate(vesselInformationApiUrl(caseId));
    onToggleEdit(id);
  };

  const handleCancel = () => {
    onToggleEdit(id);
  };

  const model: Model = {
    title,
    value,
    editable,
    includeInReport
  };
  return (
    <VesselInformationFormInline
      model={model}
      fields={fields}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      onCancel={handleCancel} />
  );
};
