import { FunctionComponent, useState } from 'react';
import { useModalContext } from '@/components/modal/ModalContext';
import { addFavourite, removeFavourite } from '@/services/caseFavoritesServices';
import { LinkedTableRow } from '@/components/shared/FilterableTable';
import { dashboardRoute } from '@/utils/routeTemplates';
import {
  concatString, getClaimNumberFromClaimReference, setStringOrDash
} from '@/utils/text';
import { getFriendlyDate } from '@instech/components';
import { Occurrence } from '@/types';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { showMaximumFavouritesModal } from '../../ClaimsHandlerAssignments/core/MaximumFavoritesModal';
import { ReportStatus, TableCell } from '../../Components';

interface Props {
  even: boolean;
  fields: {
    id: string;
    dateOfLoss?: string;
    vessel?: string;
    claimsHandler?: string;
    dateRegistered?: string;
    lastUpdated?: string;
  }
  occurrence?: Occurrence;
  favourite: boolean | undefined;
}

interface PrimaryProps extends Props {
  reportVersion?: ReportVersion;
}

const PrimaryTableRow: FunctionComponent<PrimaryProps> = ({ even, fields, occurrence, favourite, reportVersion }) => {
  const { open, close } = useModalContext();
  const [starred, setStarred] = useState(favourite);
  const caseId = fields.id;
  const modal = showMaximumFavouritesModal({ close });

  const starHandler = async () => {
    if (starred) {
      await removeFavourite(caseId);
      setStarred(!starred);
      return;
    }

    const added = await addFavourite(caseId);
    if (!added) open(modal);
    setStarred(added);
  };

  return (
    <LinkedTableRow
      even={even}
      to={dashboardRoute(fields.id)}
      starred={starred}
      starHandler={starHandler}
    >
      <TableCell favourite={starred}>
        <ReportStatus caseId={caseId} reportVersion={reportVersion} />
      </TableCell>

      <TableCell
        favourite={favourite}>
        {setStringOrDash(concatString(occurrence?.interests?.map(x => getClaimNumberFromClaimReference(x.claimReference))))}
      </TableCell>
      <TableCell favourite={starred}>{setStringOrDash(fields.vessel)}</TableCell>
      <TableCell favourite={starred}>{getFriendlyDate(occurrence?.dateOfLoss)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(occurrence?.name)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(occurrence?.fleetName)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(concatString(occurrence?.interests?.map(x => x.interest)))}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(concatString(occurrence?.interests?.map(x => x.deductible ?? '')))}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(fields.claimsHandler)}</TableCell>
    </LinkedTableRow>
  );
};

const SecondaryTableRow: FunctionComponent<Props> = ({ even, fields, occurrence, favourite }) => {
  const isTbaByClaimsHandler = occurrence?.interests.length === 1 && occurrence.interests[0].claimReference === '';
  return (
    <LinkedTableRow
    even={even}
    to={dashboardRoute(fields.id)}
  >
      <TableCell />
      {isTbaByClaimsHandler && <TableCell favourite={favourite}>TBA by Claims Handler</TableCell>}
      {!isTbaByClaimsHandler && (
        <TableCell favourite={favourite}>
          {setStringOrDash(concatString(occurrence?.interests.map(x => getClaimNumberFromClaimReference(x.claimReference))))}
        </TableCell>
      )}
      <TableCell />
      <TableCell favourite={favourite}>{getFriendlyDate(occurrence?.dateOfLoss)}</TableCell>
      <TableCell favourite={favourite}>{setStringOrDash(occurrence?.name)}</TableCell>
      <TableCell favourite={favourite}>{setStringOrDash(occurrence?.fleetName)}</TableCell>
      {isTbaByClaimsHandler && (
        <>
          <TableCell favourite={favourite}>TBA by Claims Handler</TableCell>
          <TableCell favourite={favourite}>TBA by Claims Handler</TableCell>
        </>
      )}
      {!isTbaByClaimsHandler && (
        <>
          <TableCell favourite={favourite}>{setStringOrDash(concatString(occurrence?.interests.map(x => x.interest)))}</TableCell>
          <TableCell favourite={favourite}>{setStringOrDash(concatString(occurrence?.interests.map(x => x.deductible ?? '')))}</TableCell>
        </>
      )}
      <TableCell />
      <TableCell />
    </LinkedTableRow>
  );
};

export interface MappedAssignment {
  id: string;
  vessel: string;
  isClosed: boolean;
  dateRegistered: string;
  claimsHandler: string;
  occurrences: Occurrence[];
  favorite: boolean;
  reportVersion: ReportVersion
}

interface TableRowProps {
  item: MappedAssignment;
  index: number;
}

export const TableRow: FunctionComponent<TableRowProps> = ({ item, index }) => {
  if (item.occurrences.length > 1) {
    return (
      <>
        <PrimaryTableRow
          even={index % 2 === 0}
          fields={item}
          occurrence={item.occurrences[0]}
          favourite={item.favorite}
          reportVersion={item.reportVersion}
        />
        {item.occurrences.slice(1).map(occurrence => (
          <SecondaryTableRow
            key={occurrence.id}
            even={index % 2 === 0}
            fields={{ id: item.id }}
            occurrence={occurrence}
            favourite={item.favorite}
          />
        ))}
      </>
    );
  }
  return (
    <PrimaryTableRow
      even={index % 2 === 0}
      fields={item}
      occurrence={item.occurrences && item.occurrences[0]}
      favourite={item.favorite}
      reportVersion={item.reportVersion}
    />
  );
};
