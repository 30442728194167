import { PiiFields } from '@/components/shared/Form/fields/PiiFields';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import {
  ImagePreview, UpdateImageRequest, useUpdateImageMetadata
} from '@/services/imageServices';
import { Guid } from '@/types';
import { trimString } from '@/utils/text';
import { yesNoOption } from '@/utils/validationSchemas';
import { boolToYesNo } from '@/utils/yesNoType';
import {
  FormikWithPrompt as Formik,
  TextArea, TextField
} from '@instech/components';
import { Form } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { EditedImageMeta } from './EditedImageMeta';

interface EditImageSchema {
  title: string;
  description: string;
  pii: string;
  piiIncludesEu?: string;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(60, 'Cannot be longer than 60 characters')
    .required('Required'),
  description: Yup.string()
    .max(256, 'Cannot be longer than 256 characters')
    .default(''),
  pii: yesNoOption().required('Required'),
  piiIncludesEu: yesNoOption().when('pii', {
    is: 'Yes',
    then: yesNoOption().required('Required')
  })
});

const Container = styled.div`
  padding: 20px;
`;

const FieldArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const initializeValues = (image: ImagePreview): EditImageSchema => {
  const { personallyIdentifiableInformation, ...restImage } = image;
  return ({
    ...restImage,
    pii: personallyIdentifiableInformation ? 'Yes' : 'No',
    piiIncludesEu: personallyIdentifiableInformation ? boolToYesNo(personallyIdentifiableInformation?.includesEu) : undefined
  });
};

interface EditImageFormProps {
  image: ImagePreview;
  onSave: (result: ImagePreview) => void;
  onClose: () => void;
  onDelete?: (imageId: Guid) => void;
}
export const EditImageForm: FC<EditImageFormProps> = ({ image, onSave, onClose, onDelete }) => {
  const { updateImageMetadataAsync } = useUpdateImageMetadata(image.caseId);

  const handleSubmitForm = async (values: EditImageSchema) => {
    const pii = values.pii === 'Yes'
      ? { includesEu: values.piiIncludesEu === 'Yes' }
      : undefined;

    const submitValues: UpdateImageRequest = {
      ...values,
      title: trimString(values.title),
      personallyIdentifiableInformation: pii,
    };

    const result = await updateImageMetadataAsync(image.id, submitValues);

    if (result) {
      onSave(result);
    }
  };

  return (
    <Container>
      <EditedImageMeta image={image} onDelete={onDelete} />
      <Formik
        initialValues={initializeValues(image)}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}>
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Form>
            <FieldArea>
              <TextField
                name="title"
                label="Image title"
                placeholder="Enter a title"
                useSpellcheck={false} />
              <TextArea
                name="description"
                label="Description"
                placeholder="Enter a description"
                maxLength={256} />
            </FieldArea>
            <PiiFields piiLabel="This image contains Personal Identifiable Information" />
            <SaveCloseButtons
              closeLabel="Cancel"
              onSave={handleSubmit}
              onClose={onClose}
              isSubmitting={isSubmitting}
              disableSave={!dirty}
              alignRight
              reverse />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
