import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Dropzone, FormField, FormRow, TextArea, TextField, FormikWithPrompt as Formik
} from '@instech/components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { InformationBox, InformationRow } from '@/components/shared/InformationBox';
import { useUploadImage } from '@/services/imageServices';
import { yesNoToBool } from '@/utils/yesNoType';
import { YesOrNo } from '@/types';
import {
  imageRequired, stringRequiredMaxLength, yesNoOption
} from '@/utils/validationSchemas';
import { PiiFields } from '@/components/shared/Form/fields/PiiFields';

interface FormModel {
  imagesToolImage: any;
  title: string;
  description: string;
  pii?: YesOrNo;
  piiIncludesEu?: YesOrNo;
}
const imageSchema: FormModel = {
  imagesToolImage: null,
  title: '',
  description: '',
  pii: undefined,
  piiIncludesEu: undefined
};

const validationSchema = Yup.object().shape({
  imagesToolImage: imageRequired(),
  title: stringRequiredMaxLength(),
  description: Yup.string()
    .max(256, 'Cannot be longer than 256 characters'),
  pii: yesNoOption().required('Required'),
  piiIncludesEu: yesNoOption().when(
    'pii',
    {
      is: 'Yes',
      then: yesNoOption().required('Required')
    }
  )
});

const infoText = [
  'All uploaded images will be available to the Claims Handler.',

  `The image library will give an overview of the images you have uploaded and intend to use for your reporting.
  Please consider the number of images included in the report to what will be relevant for the understanding of the case,
  the scope of damage, the repairs, the cause, cost and time considerations, including work not concerning average if any.`
];

interface Props {
  caseId: string;
}
export const UploadImageForm: FunctionComponent<Props> = ({ caseId }) => {
  const { uploadImageAsync } = useUploadImage(caseId);

  const handleFormSubmit = async (values: FormModel, helpers: FormikHelpers<FormModel>) => {
    const submitValues = {
      ...values,
      image: values.imagesToolImage,
      pii: values.pii === 'Yes',
      piiIncludesEu: yesNoToBool(values.piiIncludesEu)
    };
    const result = await uploadImageAsync(submitValues);
    if (result) {
      helpers.resetForm();
    }
  };

  return (
    <Formik
      initialValues={{ ...imageSchema }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}>
      {({ values, dirty, isSubmitting, handleSubmit, resetForm }) => (
        <Form>
          <InformationRow>
            <InformationBox paragraphs={infoText} />
          </InformationRow>
          <FormRow marginBottom={values.imagesToolImage && '30px'}>
            <Dropzone name="imagesToolImage" />
          </FormRow>
          {values.imagesToolImage && (
            <>
              <FormRow gutter="40px">
                <FormField>
                  <TextField name="title" label="Image title" placeholder="Enter a title" useSpellcheck={false} />
                </FormField>
              </FormRow>
              <FormRow marginBottom="10px">
                <FormField>
                  <TextArea name="description" label="Description" placeholder="Enter a description" maxLength={256} />
                </FormField>
              </FormRow>
              <PiiFields piiLabel="This image contains Personal Identifiable Information" />
              <SaveCloseButtons
                closeLabel="Cancel"
                onSave={handleSubmit}
                onClose={resetForm}
                isSubmitting={isSubmitting}
                disableSave={!dirty}
                alignRight
                reverse />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
UploadImageForm.propTypes = {
  caseId: PropTypes.string.isRequired
};
