import { NotifyTooltip } from '@/components/shared/Tooltip';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useCaseStatus } from '@/services/caseStatusServices';
import { useCase } from '@/services/dashboardServices';
import { useOccurrences } from '@/services/occurrenceServices';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { Interest, Occurrence } from '@/types';
import { Info, MenuDots } from '@instech/icons';
import React, { useCallback } from 'react';
import { ContextMenu } from '@instech/components';
import { useModalContext } from '@/components/modal/ModalContext';
import { showChangeInsuranceYearModal }
  from '@/components/pages/Dashboard/ClaimsHandlerDashboard/Occurrences/UpdateOccurrenceModals/ChangeInsuranceYear/ChangeInsuranceYearModal';
import styled from 'styled-components';
import { DashboardPane } from '../core/DashboardPane';
import { Container } from './Components';
import { OccurrenceRow } from './OccurrenceRow';
import { AddNewOccurrenceButton } from './UpdateOccurrenceModals';

const OccurrenceList = ({ userCanEdit } : { userCanEdit: boolean}) => {
  const caseId = useCaseIdFromUrl();
  const { data: occurrences, mutate, isValidating: isValidatingOccurrences } = useOccurrences({ suspense: true });
  const { data: reportCase, isValidating: isValidatingCase } = useCase(caseId, true);

  const isValidating = isValidatingOccurrences || isValidatingCase;

  const updateInterest = async (occurrenceId: string, newInterest: Interest) => {
    if (!occurrences) return;

    const newOccurrences = occurrences.map(occurrence => {
      if (occurrence.id !== occurrenceId) {
        return occurrence;
      }

      const newInterests = occurrence.interests.map(interest =>
        interest.claimId !== newInterest.claimId ? interest : newInterest
      );

      return { ...occurrence, interests: newInterests };
    });

    await mutate(newOccurrences, false);
  };

  const onOccurrencesUpdate = useCallback(async (update: (occurrences: Occurrence[]) => Occurrence[]) => {
    if (occurrences === undefined) {
      return;
    }
    await mutate(update(occurrences), false);
  }, [mutate, occurrences]);

  return (
    <Container>
      {!isValidating && reportCase && occurrences?.map((occurrence, index) => (
        <OccurrenceRow
          key={occurrence.id}
          occurrence={occurrence}
          surveyCase={reportCase}
          index={index}
          onOccurrencesUpdate={onOccurrencesUpdate}
          updateInterest={updateInterest}
          userCanEdit={userCanEdit}
        />
      ))}
      {!isValidating && userCanEdit && (
        <AddNewOccurrenceButton onOccurrencesUpdate={onOccurrencesUpdate} occurrenceClaimId={reportCase?.claimId ?? null} userCanEdit={userCanEdit} />
      )}
      <NotifyTooltip id="occurrence-list-notify" bodyMode />
    </Container>
  );
};

const Rotated = styled.div`
  transform: rotate(90deg);
`;

const OccurrencesContextMenu = () => {
  const modal = showChangeInsuranceYearModal({});
  const { open } = useModalContext();

  return (
    <ContextMenu
      icon={<Rotated><MenuDots /></Rotated>}
      options={[
        {
          label: 'Change Insurance Year',
          onClick: () => open(modal),
        }
      ]}
  />
  );
};

export const Occurrences = () => {
  const caseId = useCaseIdFromUrl();
  const { data: reportCase } = useCase(caseId, true);
  const { data: caseStatus } = useCaseStatus();
  const { data: currentUser } = useCurrentUserInfo();

  // Test if current user is primary claims handler, as only they can edit
  const userCanEdit = reportCase?.users?.primaryClaimsHandlerId === currentUser?.id && !caseStatus?.isClosed;

  return (
    <DashboardPane
      title={(
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <span>Occurrences</span>
          { userCanEdit && <OccurrencesContextMenu /> }
        </div>
      )}
      icon={<Info />}
      chapterLink="occurrences"
      padding="0"
    >
      <OccurrenceList userCanEdit={userCanEdit} />
    </DashboardPane>
  );
};
