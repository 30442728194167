import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { refreshOccurrencesAsync } from '@/services/occurrenceServices';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { UpdateInterestFunc } from '../types';
import { RefreshClaimButton } from './RefreshClaimButton';
import { useRefreshState } from './useRefreshState';

const ClaimFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.warning};
  margin: 0;
`;

interface ClaimFieldProps {
  occurrenceClaimId: string | null;
  occurrenceId: string;
  updateInterest: UpdateInterestFunc;
  userCanEdit: boolean;
  hasEdited: number;
}
export const RefreshClaim: FC<ClaimFieldProps> = ({ occurrenceClaimId, occurrenceId, updateInterest, userCanEdit, hasEdited }) => {
  const caseId = useCaseIdFromUrl();
  const { refreshState, refreshFuncs } = useRefreshState();

  const refreshClaimIsDisabled = occurrenceClaimId === null;

  // When hasEdited ticks up, call reducer function to reset state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refreshFuncs.resetState(), [hasEdited]);

  const refreshClaim = async () => {
    if (refreshClaimIsDisabled) return;
    refreshFuncs.startRefresh();
    const { data: interest, isError } = await refreshOccurrencesAsync(caseId, occurrenceId, occurrenceClaimId);

    if (isError) {
      refreshFuncs.errorRefreshing();
    } else {
      refreshFuncs.doneRefreshing();
      await updateInterest(occurrenceId, interest);
    }
  };

  if (!userCanEdit) {
    return null;
  }

  return (
    <ClaimFieldContainer>
      <RefreshClaimButton
        onClick={refreshClaim}
        disabled={refreshClaimIsDisabled}
        isLoading={refreshState.isRefreshing}
        hasRefreshed={refreshState.hasRefreshed} />
      {refreshState.hasRefreshError && (
        <ErrorText>Could not refresh claim</ErrorText>
      )}
    </ClaimFieldContainer>
  );
};
