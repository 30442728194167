/* eslint-disable no-param-reassign */
import { RemoveButton } from '@/components/shared/LinkButton/LinkButtons';
import { ReadOnlyField } from '@/components/shared/ReadOnlyField';
import { fields, validationSchema } from '@/components/tools/VesselParticulars/information/core';
import { newEntryModel } from '@/components/tools/VesselParticulars/information/VesselInformation';
import {
  VesselInformation,
  VesselInformationAdd
} from '@/services/vesselInformationServices';
import { Pane } from '@instech/components';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  InformationWithDelete,
  InformationLayoutPadded,
  StyledInputForm
} from './Components';

export const VesselInfoFields: FC = () => {
  const { values, setFieldValue } = useFormikContext<{ vesselInformation: VesselInformation[] }>();

  if (!values.vesselInformation) {
    return null;
  }

  const addParticular = (model: VesselInformationAdd) => {
    const newEntry: VesselInformation = {
      id: uuidv4(),
      editable: false,
      includeInReport: model.includeInReport,
      value: model.value,
      title: model.title,
      source: {
        date: new Date().toDateString(),
        name: 'Claims handler'
      }
    };
    const particulars = [...values.vesselInformation, newEntry];
    void setFieldValue('vesselInformation', particulars);
  };

  const deleteParticular = (id: string) => {
    const particulars = values.vesselInformation.filter(item => item.id !== id);
    void setFieldValue('vesselInformation', particulars);
  };

  return (
    <Pane title="Vessel Information" padding="0px" margin="16px 0px 0px">
      <InformationLayoutPadded>
        {values.vesselInformation.map(field => (
          <InformationWithDelete key={field.id}>
            <ReadOnlyField key={field.id} title={field.title} value={field.value} />
            <RemoveButton onClick={() => deleteParticular(field.id)} />
          </InformationWithDelete>
        ))}
      </InformationLayoutPadded>
      <StyledInputForm
        formId="vesselInfo"
        model={newEntryModel}
        fields={fields}
        onSubmit={model => addParticular(model as VesselInformationAdd)}
        closeForm={() => { }}
        validationSchema={validationSchema}
        cancelIsReset
      />
    </Pane>
  );
};
