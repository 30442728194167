import { claimInterests, Interest } from '@/types';
import { formatNumber } from '@/utils/number';
import { isRealNumber } from '@/utils/predicates';
import { TruncatingText } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import { FC } from 'react';
import {
  FieldIcon, FieldRow, FieldText, FieldTitle
} from './Components';

const formatDeductibleValue = (interest: Interest) => {
  // LOH interest uses a non-numeric display format (ex. 14/90/90)
  if (interest.claimInterest === claimInterests.lossOfHire) {
    return interest.deductible ?? '-';
  }
  const deductibleNumber = Number(interest.deductible);
  return isRealNumber(deductibleNumber) ? formatNumber(deductibleNumber) : '-';
};

interface Props {
  label: string;
  interest: Interest;
}
export const DeductibleField: FC<Props> = ({ label, interest }) => {
  const isDeductibleChanged = interest.originalDeductible !== null &&
    String(interest.deductible) !== String(interest.originalDeductible?.value);
  const displayValue = formatDeductibleValue(interest);
  return (
    <FieldRow>
      <FieldTitle>
        {label}
        {isDeductibleChanged && (
          <FieldIcon
            data-tip="Deductible value from INS+ has been overridden"
            data-for="occurrence-list-notify">
            <WarningFilled />
          </FieldIcon>
        )}
      </FieldTitle>
      <FieldText>
        <TruncatingText text={displayValue} padding="0px 10px 0px 0px" wrap />
      </FieldText>
    </FieldRow>
  );
};
