import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TextField, DateRangePicker } from '@instech/components';
import { CertificateSection, Overview } from '../Overview';

const Field = styled.div`
  width: 325px;
`;

interface ISMCertificateProps {
  title: string;
  fieldName: string;
}
const ISMCertificate: FunctionComponent<ISMCertificateProps> = ({ title, fieldName }) => (
  <div>
    <div>{title}</div>
    <Field>
      <DateRangePicker name={fieldName} startLabel="Issue date" endLabel="Expiry date" />
    </Field>
  </div>
);

const StyledOverview = styled(Overview)`
  height: 38px;
  margin-bottom: 16px;
`;

interface ISMCertificatesProps {
  header: string;
  fieldName: string;
}
export const ISMCertificates: FunctionComponent<ISMCertificatesProps> = ({ header, fieldName }) => (
  <CertificateSection>
    <StyledOverview header={header} />
    <TextField
      name={`${fieldName}.issuedBy`}
      label="Issued by"
      placeholder='e.g. "Bureau Veritas"'
    />
    <ISMCertificate title="DOC" fieldName={`${fieldName}.doc`} />
    <ISMCertificate title="SMC" fieldName={`${fieldName}.smc`} />
  </CertificateSection>
);
