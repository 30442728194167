import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { CompanyLogoLinkModel, Guid } from '@/types';
import { useCompanyLogo } from '@/services/companiesService';
import {
  Loader, IconButton, Pane
} from '@instech/components';
import { Change } from '@instech/icons';
import { UploadForm } from './core/UploadForm';

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledImage = styled.img`
  height: 100px;
  max-width: 100%;
  object-fit: scale-down;
`;

interface ImagePreviewProps {
  data: CompanyLogoLinkModel;
  companyName: string;
}
const ImagePreview: FunctionComponent<ImagePreviewProps> = ({ data, companyName }) => (
  <ImageWrapper>
    <StyledImage src={data.url} alt={`Company logo for ${companyName}`} />
  </ImageWrapper>
);

interface Props {
  companyId: Guid;
  companyName: string;
}
export const ImageUploader: FunctionComponent<Props> = ({ companyId, companyName }) => {
  const [showUploadForm, setShowUploadForm] = useState(false);

  const { data, error, isValidating } = useCompanyLogo(companyId);

  if (error) {
    throw error;
  }

  if (!data?.url && isValidating) {
    return <Loader />;
  }

  const hasExistingLogo = Boolean(data);

  return (
    <Pane
      title="Company logo"
      button={
        (!hasExistingLogo || showUploadForm)
          ? undefined
          : <IconButton icon={<Change />} onClick={() => setShowUploadForm(true)} />
      }
    >
      {!hasExistingLogo || showUploadForm ? (
        <UploadForm
          companyId={companyId}
          hasExistingLogo={hasExistingLogo}
          handleClose={() => setShowUploadForm(false)}
        />
      ) : (
        <ImagePreview
          data={data as CompanyLogoLinkModel}
          companyName={companyName}
        />
      )}
    </Pane>
  );
};
