import client from '@/services/client';
import {
  ClaimsHandlerDashboardAssignment, Guid, SurveyorDashboardAssignment
} from '@/types';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
import { useCurrentUserInfo } from './userInfoService';

interface DashboardFetcherProps {
  url: string;
  status: boolean;
  claimsHandlerIds: string[];
}

const getClaimsHandlerUrl = (id: string, openStatus: boolean) => (
  `dashboardAssignments/${id}/cases?caseStatus=${openStatus ? 'open' : 'closed'}`
);

const getSurveyorUrl = (id: string, openStatus: boolean) => (
  `dashboardAssignments/surveyor/${id}/cases?openCases=${openStatus}`
);

const fetchFromUrl = async (url: string) => {
  const result = await client.get(url).then(res => res.data);
  return result;
};

const dashboardFetcher = async (props: DashboardFetcherProps) => {
  const requestUrls = props.claimsHandlerIds.map(id => getClaimsHandlerUrl(id, props.status));
  // Will return array of arrays, which must then be made .flat()
  const result = await Promise.all(requestUrls.map(requestUrl => fetchFromUrl(requestUrl)));
  return result.flat();
};

export const useDashboardAssignments = (openStatus: boolean, claimsHandlers: Guid[]) => {
  const { data: currentUser } = useCurrentUserInfo();

  const fetcherProps: DashboardFetcherProps = useMemo(() => ({
    url: 'dashboardAssignments/filteredAssignments',
    status: openStatus,
    // Get claimsHandlers, or fall back to current user if not available.
    claimsHandlerIds: (claimsHandlers.length < 1) ? [currentUser?.id || ''] : claimsHandlers
  }), [openStatus, claimsHandlers, currentUser]);

  const { data, error } = useSWR<ClaimsHandlerDashboardAssignment[], AxiosError>(fetcherProps, dashboardFetcher);

  return { data, error };
};

export const useSurveyorDashboardAssignments = (openStatus: boolean) => {
  const currentUser = useCurrentUserInfo();
  const url = getSurveyorUrl(currentUser.data?.id || '', openStatus);
  return useSWR<SurveyorDashboardAssignment[], AxiosError>(url);
};
