import { ReadOnlyField } from '@/components/shared/ReadOnlyField';
import {
  FormField, Pane, TextField
} from '@instech/components';
import { useField } from 'formik';
import { FC } from 'react';
import { InformationLayout } from './Components';

export const CoreFields: FC = () => {
  const [field] = useField('metadata');
  return (
    <>
      <Pane title="Report" padding="20px" margin="16px 0px 0px">
        <FormField>
          <TextField name="name" placeholder="Name" label="Name" />
        </FormField>
      </Pane>
      <Pane title="Metadata" padding="20px" margin="16px 0px 0px">
        <InformationLayout>
          <ReadOnlyField
            title="Interest"
            value={field.value.interest} />
          <ReadOnlyField
            title="Conditions"
            value={field.value.conditions.value} />
          <ReadOnlyField
            title="Policy Currency"
            value={field.value.policyCurrency} />
          <ReadOnlyField
            title="Expense Currency"
            value={field.value.expenseCurrency} />
          <ReadOnlyField
            title="Deductible"
            value={field.value.deductible} />
          <ReadOnlyField
            title="Claim Reference"
            value={field.value.claimReference} />
        </InformationLayout>
      </Pane>
    </>
  );
};
