import { ErrorComponentProps } from '@instech/components';
import { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { ErrorView, ErrorViewButtons } from './core/ErrorView';
import { errorStatuses } from './types';

export const ErrorNotAllowed: FC<ErrorComponentProps> = ({ reset }) => {
  const location = useLocation();
  const [prevLocation] = useState(location);

  if (location !== prevLocation) {
    reset(); // Resets error boundary if user navigates away from page
  }

  return (
    <ErrorView
      type={errorStatuses.notAllowed}
      title="Oh no..."
      message="Something went wrong here. It's not you. It's us.">
      <ErrorViewButtons reset={reset} />
    </ErrorView>
  );
};
