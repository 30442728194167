import { TimelineItem } from '@/types';
import { LabelValuePair } from '@instech/components';
import moment from 'moment';
import { eventTypes } from '../EventForm/core/types';
import { TimelineSegment, segmentTypes } from './types';

const isMovementOrOccurrence = (eventType: string) =>
  (eventType === eventTypes.vesselMovement) || (eventType === 'Occurrence');

const compareDateTime = (dateTimeA: string, dateTimeB: string) => {
  if (moment.utc(dateTimeA).isBefore(dateTimeB)) return -1;
  if (moment.utc(dateTimeA).isAfter(dateTimeB)) return 1;
  return 0;
};

const compareEventTypes = (eventTypeA: string, eventTypeB: string) => {
  if (eventTypeA === eventTypeB) return 0;
  if (isMovementOrOccurrence(eventTypeA)) return -1;
  return 1;
};

// Check to see whether the current event should render 'elsewhere', on the opposite side of the
// current timeline path. Refactored out of the main function.
const checkElsewhere = (curEvent: TimelineItem, lastShipLoc: string | null) => {
  const curLocation = curEvent?.location.trim().toUpperCase() || '';
  const elsewhere = curEvent?.eventType === 'Event' && curLocation !== lastShipLoc && lastShipLoc !== null;
  return elsewhere;
};

// Sort by date and time, and if date and time is equal, sort by event type.
// Vessel Movements and Occurrences should go before all other event types.
// eslint-disable-next-line max-len
export const compareEvents = (a: TimelineItem, b: TimelineItem) =>
  compareDateTime(a.utcEventDate, b.utcEventDate) || compareEventTypes(a.eventType, b.eventType);

export const getStructure = (data: TimelineItem[] = []) => {
  const timeline: TimelineSegment[] = [];
  const sortedData = data.concat().sort(compareEvents);

  // Set up loop vars, to track between iterations
  let newYear: number | null = null;
  let lastShipLoc: string | null = null;
  let lastElsewhereLoc: string | null = null;
  let isRightSide = false;

  sortedData.forEach((event, i) => {
    const eventLocation = event.location === null ? '' : event.location.trim().toUpperCase(); // Cache location for this loop
    const isElsewhere = checkElsewhere(event, lastShipLoc); // First round, is always empty. After, check if this loc is different from last loc

    const addYear = new Date(event.eventDate).getFullYear() !== newYear; // If this event's year is not last year, add new year on the route side
    const addLocation = (!isElsewhere && (eventLocation !== lastShipLoc)) // If this location is not the last location, show a location
      || (isElsewhere && (eventLocation !== lastElsewhereLoc));

    const swapSides = !isElsewhere && eventLocation !== lastShipLoc; // If the ship is at a different location from where it was last, swap side
    isRightSide = (swapSides && i !== 0) ? !isRightSide : isRightSide; // Determine if the voyage is on the right side (as opposed to the default left)

    // Open up the timeline with the year and initial small path.
    if (i === 0) {
      timeline.push({ type: segmentTypes.yearStart, date: event.utcEventDate });
      timeline.push({ type: segmentTypes.pathBefore, right: isRightSide });
    }

    // If this is not the first, check if it should add a year or swap the timeline to the other side.
    if (i !== 0) {
      // The year has to invert 'isRight' to render on the right side.
      if (addYear) timeline.push({ type: segmentTypes.year, date: event.utcEventDate, right: isRightSide });
      if (swapSides) timeline.push({ type: segmentTypes.pathLong, right: isRightSide });
    }

    // Add location, if necessary, and event.
    if (addLocation) timeline.push({ type: segmentTypes.location, right: isRightSide, elsewhere: isElsewhere, location: event.location });
    timeline.push({ type: segmentTypes.vessel, right: isRightSide, elsewhere: isElsewhere, event });

    // Close with the last path and the boat.
    if (i === data.length - 1) {
      timeline.push({ type: segmentTypes.pathAfter, right: isRightSide });
      timeline.push({ type: segmentTypes.tail });
    }

    // Update vars for the next loop. This logic is essentially used to flag where the
    // next location should be at and whether it should rendera location tag.
    newYear = new Date(event.utcEventDate).getFullYear();
    if (!isElsewhere) {
      lastShipLoc = eventLocation;
      lastElsewhereLoc = null;
    }
    if (isElsewhere) lastElsewhereLoc = eventLocation;
  });
  return timeline;
};

export const extractLocations = (data: TimelineItem[] | null | undefined) => {
  if (!data) return null;

  const inputLocations = [...new Set(data.map(event => event.location))];
  const locations: LabelValuePair[] = inputLocations
    .filter(location => location.toLowerCase() !== 'replace me')
    .map(newLocation => ({ label: newLocation, value: newLocation }));

  return locations;
};
