import { ImageView } from '@/components/shared/Image';
import { ImagePreview } from '@/services/imageServices/imageServices';
import { Guid } from '@/types';
import { ImagePreview as InsifyImagePreview } from '@instech/components';
import { FC } from 'react';
import { ImageGridLayout, ImageGridOuter } from './Components';

interface ImageGridProps {
  imageIds: Guid[];
  onClick: (image: ImagePreview | InsifyImagePreview) => void;
  showPlaceHolderForPiiImages?: boolean;
}
export const ImageGridLightbox: FC<ImageGridProps> = ({ imageIds, onClick, showPlaceHolderForPiiImages }) => {
  const handleOpenLightbox = (image: InsifyImagePreview) => {
    onClick(image);
  };

  return (
    <ImageGridOuter noSidebar>
      <ImageGridLayout>
        {imageIds.map(id => (
          <ImageView
            key={id}
            imageId={id}
            height="145px"
            onClick={handleOpenLightbox}
            crop="small"
            hidePiiImages={showPlaceHolderForPiiImages}
          />
        ))}
      </ImageGridLayout>
    </ImageGridOuter>
  );
};
