import {
  Switch, Route, Redirect
} from 'react-router';
import {
  dashboardRoute,
  reportViewRoute
} from '@/utils/routeTemplates';
import { CreateCasePage } from '../pages/CreateCasePage';
import { ReportViewPage } from '../pages/report/ReportViewPage';
import { ClaimsHandlerAssignmentsPage } from '../pages/assignments/AssignmentsPage';
import { ClaimsHandlerDashboardPage } from '../pages/Dashboard';

export const ClaimsHandlerRoutes = () => (
  <Switch>
    <Route exact path="/create">
      <CreateCasePage />
    </Route>
    <Route exact path={reportViewRoute(':id', ':version')}>
      <ReportViewPage />
    </Route>
    <Route exact path={dashboardRoute(':id')}>
      <ClaimsHandlerDashboardPage />
    </Route>
    <Route exact path="/">
      <ClaimsHandlerAssignmentsPage />
    </Route>
    <Route path="*" render={() => <Redirect to="/" />} />
  </Switch>
);
