import {
  createRef,
  KeyboardEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react';
import {
  Editor, RichUtils, EditorState
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled, { css } from 'styled-components';
import { headers } from './core/controlOptions';
import { customBlockStyleFn } from '../core/customStyles';
import { Images } from './core/Images';
import {
  DropdownWrapper, InlineStyleControls, BlockStyleControls
} from './core/Controls';
import { editorStateToText, getEditorState } from './utility';

const TextStyleRow = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.theme.lightGray};
`;

interface TextEditorProps {
  padding?: string;
  spaceBetween?: string;
}
const TextEditor = styled.div<TextEditorProps>`
  display: flex;
  flex-wrap: nowrap;
  height: calc(100% - 64px);
  border-radius: 1px;
  padding: ${props => props.padding};
  font-size: 16px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    & > * {
      width: 100%;
    }
  }

  ${props => props.spaceBetween && css`
    & > *:not(:first-child) {
      @media (min-width: 1101px) {
        margin-left: ${props.spaceBetween};
      }
      @media (max-width: 1100px) {
        margin-top: ${props.spaceBetween};
      }
    }
  `}
`;

const TextView = styled.div`
  height: calc(426px);
  width: 100%;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border.gray};
  border-radius: 3px;
  font-size: 14px;
  box-sizing: content-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);

  & .public-DraftEditor-content {
    display: block;
    padding: 16px;
    overflow: auto;
    box-sizing: border-box;
  }
`;

const SelectedImagesArea = styled.div`
  flex-shrink: 0;
  background-color: ${props => props.theme.background.primary};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);

  /* Enforce desktop sidebar sizing for images */
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    height: 426px;
    width: 340px;
  }
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    height: 340px;
  }
`;

interface RichTextProps {
  useImages?: boolean;
  onImageChange?: (newImages: string[]) => void;
  imageState?: string[];
  imageFormId?: string;
  onRichTextChange: (text: string) => void;
  richText: string;
  padding?: string;
  spaceBetween?: string;
  useSpellcheck?: boolean;
}
export const RichText: FunctionComponent<RichTextProps> = (
  { useImages, onImageChange, imageState, onRichTextChange, richText, padding, spaceBetween, useSpellcheck = true, imageFormId }
) => {
  const [editorState, setEditorState] = useState(getEditorState(richText));

  /**
   * Update the contents of the editor if 'richText' is updated from the outside. For example, if
   * Formik swaps two elements in the list this will ensure that the RichText contents is also swapped (SURVEY-845)
   */
  useEffect(() => {
    if (richText !== editorStateToText(editorState)) {
      setEditorState(getEditorState(richText));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [richText]);

  const editorRef = createRef<Editor | null | undefined>();

  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const newText = editorStateToText(newEditorState);
    onRichTextChange(newText);
  };

  const toggleHeadline = (style: string) => {
    const result = RichUtils.toggleBlockType(editorState, style);
    onChange(result);
  };

  const onToggleInline = (style: string) => {
    const result = RichUtils.toggleInlineStyle(editorState, style);
    onChange(result);
  };

  const onToggleBlock = (style: string) => {
    const result = RichUtils.toggleBlockType(editorState, style);
    onChange(result);
  };

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const handleTab = (evt: KeyboardEvent<{}>) => {
    evt.preventDefault();
    const newState = RichUtils.onTab(evt, editorState, 4);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  return (
    <>
      <TextStyleRow>
        <DropdownWrapper options={headers} onToggle={toggleHeadline} editorState={editorState} />
        <InlineStyleControls onToggle={onToggleInline} editorState={editorState} />
        <BlockStyleControls onToggle={onToggleBlock} editorState={editorState} />
      </TextStyleRow>
      <TextEditor padding={padding} spaceBetween={spaceBetween}>
        <TextView onClick={() => editorRef.current?.focus()}>
          <Editor
            ref={editorRef as any}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand as any}
            onChange={onChange}
            onTab={handleTab}
            spellCheck={useSpellcheck}
            blockStyleFn={customBlockStyleFn}
          />
        </TextView>
        {useImages && (
          <SelectedImagesArea>
            <Images onSelect={onImageChange} selectedImages={imageState || []} />
          </SelectedImagesArea>
        )}
      </TextEditor>
    </>
  );
};
