import { Field } from '@/types';
import { FormikValues } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { InputForm } from './InputForm';

const InlineFormContainer = styled.div`
  &:not(:first-child) {
    margin-top: 6px;
  }
  &:not(:last-child){
    margin-bottom: 6px;
  }
`;

type Props<TModel> = {
  model: TModel & FormikValues;
  fields: Field[];
  onSubmit: (model: TModel) => Promise<void>;
  validationSchema: Yup.ObjectSchema<AnyObject>;
  onCancel: () => void;
  hideRestoreDialog?: () => void;
}

export const VesselInformationFormInline = <TModel, >({
  model,
  fields,
  onSubmit,
  validationSchema,
  hideRestoreDialog,
  onCancel
}: Props<TModel>) => {
  const submit = async (newModel: TModel) => {
    await onSubmit(newModel);
    if (hideRestoreDialog) {
      hideRestoreDialog();
    }
  };

  return (
    <InlineFormContainer>
      <InputForm
        model={model}
        fields={fields}
        onSubmit={values => submit(values)}
        closeForm={onCancel}
        validationSchema={validationSchema}
      />
    </InlineFormContainer>
  );
};
