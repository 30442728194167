import { useModalContext } from '@/components/modal/ModalContext';
import { openImmediateFeedbackTool } from '@/components/tools/ImmediateFeedback/ImmediateFeedbackTool';
import { BoxButton } from '@instech/components';
import { InfoCircle } from '@instech/icons';

export const FeedbackButton = () => {
  const { open } = useModalContext();
  const showFeedback = openImmediateFeedbackTool({ readonly: true });

  return (
    <BoxButton startIcon={<InfoCircle />} width="100%" onClick={() => open(showFeedback)}>
      Show Immediate Feedback
    </BoxButton>
  );
};
