import 'styled-components/macro';
import { TextField, FormikWithPrompt as Formik } from '@instech/components';
import styled from 'styled-components';
import { Form } from 'formik';
import useSWR, { useSWRConfig } from 'swr';
import { updateContactAsync } from '@/services/contactsServices';
import { TableCell } from '@/components/shared/Table';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { Contact } from '@/types';
import { contactSchema } from './core/contactSchema';
import { sortByName } from './core/sortByName';

const FormTableCell = styled(TableCell)`
  justify-content: flex-start;
`;

interface Props {
  data: Contact;
  toggleEditMode: () => void;
  caseId: string;
}

export const ContactRowEdit: React.FC<Props> = ({ data, toggleEditMode, caseId }) => {
  const url = `case/${caseId}/contacts`;
  const { data: contacts, isValidating } = useSWR<Contact[]>(url); // TODO: This looks like it should be a service
  const { mutate } = useSWRConfig();

  const onSubmit = async (values:Contact) => {
    if (!contacts || isValidating) return;

    await updateContactAsync(caseId, values);
    const newContacts = contacts.filter(x => x.id !== values.id);
    newContacts.push(values);
    newContacts.sort(sortByName);
    void mutate(url, newContacts);
    toggleEditMode();
  };

  return (
    <Formik
      css="display: contents"
      initialValues={data}
      validationSchema={contactSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form css="display: contents">
          <FormTableCell>
            <TextField
              name="name"
              placeholder="Name"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="function"
              placeholder="Function"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="phone"
              placeholder="Phone"
              noLabel
            />
          </FormTableCell>
          <FormTableCell>
            <TextField
              name="email"
              placeholder="Email"
              noLabel
            />
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel="Cancel"
              onSave={handleSubmit}
              disableSave={!dirty}
              isSubmitting={isSubmitting}
              onClose={toggleEditMode}
              alignRight
              reverse
            />
          </FormTableCell>
          <FormTableCell />
        </Form>
      )}
    </Formik>
  );
};
