import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenPropType } from '../../../../prop-types/custom-prop-types';

const StyledMenuItem = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: 8px; 

  ${props => props.disabled && css`
    color: ${props.theme.lightGray};
    cursor: not-allowed;
  `};

  ${props => !props.disabled && css`
    cursor: pointer;
    &:hover { 
      background: ${props.theme.flatWhite};
    }
  `}
  
  & + & {
    border-top: 1px solid ${props => props.theme.marineBlue50};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px; 
  margin-right: 5px;
  color: ${props => props.theme.marineBlue};

  & svg {
    max-width: 16px;
    max-height: 16px;
  }
`;

const Text = styled.span`
  font-size: 14px;
  text-align: left;
  line-height: 14px;
  margin-top: 2px;
`;

export const MenuItem = ({ icon, label, disabled, onClick }) => (
  <StyledMenuItem
    disabled={disabled}
    onClick={onClick ?
      evt => {
        onClick();
        evt.stopPropagation();
      } : null}
  >
    <IconWrapper>{icon}</IconWrapper>
    <Text>{label}</Text>
  </StyledMenuItem>
);

MenuItem.propTypes = {
  icon: childrenPropType,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
