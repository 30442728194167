import {
  FileCategory,
  FileModel,
  Pii
} from '@/services/fileServices';
import { Dictionary } from '@/types';

export const enclosed = 'Enclosed';

export const sortInformation = (info: FileModel[]) => (
  info.reduce((groups, item) => {
    const newGroups = { ...groups } as Dictionary<FileModel[]>;
    newGroups[item.category] = newGroups[item.category] || [];
    newGroups[item.category].push(item);
    return newGroups;
  }, {} as Dictionary<FileModel[]>)
);

export const defaultFileCategory: FileCategory = 'General information';

/**
 * Groups files by category and modifies deprecated categories to
 * a default fallback category
 */
export const transformFiles = (files: FileModel[], categories: FileCategory[]) => {
  // Add default category if it's not already in the categories array
  const categoriesWithDefault = Array.from(new Set([
    defaultFileCategory,
    ...categories
  ]));

  // Some categories may have been changed. Therefore we map files with
  // old deprecated categories to the 'defaultFileCategory'
  const filesWithValidCategories: FileModel[] = files.map(x => ({
    ...x,
    category: categoriesWithDefault.includes(x.category)
      ? x.category
      : defaultFileCategory
  }));

  const groupedByCategory = Object.groupBy(filesWithValidCategories, ({ category }) => category);

  return Object.entries(groupedByCategory).map(([category, categoryFiles]) => {
    const containsEnclosed = categoryFiles.some(({ status }) => status === enclosed);
    return { category, containsEnclosed, files: categoryFiles };
  });
};

export const getPiiText = (personallyIdentifiableInformation: Pii | null) => {
  if (!personallyIdentifiableInformation) return null;
  if (!personallyIdentifiableInformation.includesEu) return 'contains PII';
  return 'contains PII about EU citizens';
};
