import { BoxButton, ErrorComponentProps } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import { useAccount } from '../../authentication/AuthenticationProvider';
import { ErrorView, ErrorViewButtons } from './core/ErrorView';
import { errorStatuses } from './types';

const errorMessage = `
  The request has not been applied because it lacks valid
  authentication credentials for the target resource.
`;

const Container = styled.div`
  max-width: 412px;
  margin: 0px auto;
  padding: 64px 8px 80px;
`;

interface LogoutProps {
  logout: () => void;
}
const LogoutContent: FC<LogoutProps> = ({ logout }) => (
  <Container>
    <BoxButton width="100%" onClick={logout}>Log Out</BoxButton>
  </Container>
);

export const UnauthorizedPage: FC<ErrorComponentProps> = ({ reset }) => {
  const { logout, isAuthenticated } = useAccount();
  return (
    <ErrorView
      type={errorStatuses.unauthorized}
      title="Unauthorized"
      message={errorMessage}>
      {isAuthenticated
        ? <LogoutContent logout={logout} />
        : <ErrorViewButtons reset={reset} />}
    </ErrorView>
  );
};
