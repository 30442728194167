import { ToggleInlineButton } from '@/components/shared/FormButton/FormButton';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { UseRestoreData } from '@/components/shared/RestoreDeletedItem';
import { textLength } from '@/utils/validation';
import {
  FormikWithPrompt as Formik,
  TextField
} from '@instech/components';
import { Form } from 'formik';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ParticularFormLayout } from './Components';
import {
  companyParticularSchema, CompanyParticularSchema,
  newCompanyParticular
} from './utils';

const Container = styled.div<{ clearMargin?: boolean }>`
  background-color: ${props => props.theme.background.primary};
  padding: 15px;
  margin-top: ${props => !props.clearMargin && '20px'};
`;

interface Props {
  submit: (values: CompanyParticularSchema) => Promise<void>;
  hideRestoreDialog: UseRestoreData['hideRestoreDialog'];
  showWhenEmpty: boolean;
}
export const NewParticularForm: FC<Props> = ({ submit, showWhenEmpty, hideRestoreDialog }) => {
  const [isOpen, setOpen] = useState(showWhenEmpty);
  const open = () => {
    hideRestoreDialog();
    setOpen(true);
  };
  const close = () => setOpen(false);

  if (!isOpen) {
    return (
      <ToggleInlineButton
        label="Company's particulars"
        onClick={open}
        marginTop={!showWhenEmpty ? '16px' : ''}
      />
    );
  }

  return (
    <Container clearMargin={showWhenEmpty}>
      <Formik
        initialValues={newCompanyParticular}
        validationSchema={companyParticularSchema}
        onSubmit={async values => {
          await submit(values);
          close();
        }}>
        {({ dirty, isSubmitting, handleSubmit, resetForm }) => (
          <Form>
            <ParticularFormLayout>
              <TextField
                name="title"
                label="Function"
                placeholder='"Ship Owner"'
                maxLength={textLength.short} />
              <TextField
                name="description"
                label="Company"
                placeholder='"High Sea Shipping LTD"'
                maxLength={textLength.medium} />
            </ParticularFormLayout>
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel={showWhenEmpty ? 'Reset' : 'Cancel'}
              onSave={handleSubmit}
              onClose={() => showWhenEmpty ? resetForm() : close()}
              isSubmitting={isSubmitting}
              disableSave={!dirty}
              disableClose={!dirty && showWhenEmpty}
              marginTop="15px"
              alignRight
              reverse
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
NewParticularForm.propTypes = {

};
