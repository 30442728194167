import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BoxButton, useLightboxContext } from '@instech/components';
import { Add } from '@instech/icons';
import { useTypedModal } from '@/components/modal/ModalContext';
import { ImageCard } from '@/components/shared/Image/ImageCard';
import { Guid } from '@/types';
import { showSelectImagesModal } from '@/components/tools/Images/SelectImageModal';

const headerHeight = '49px';

const HeaderRow = styled.div`
  height: ${headerHeight};
  display: flex;
  > button {
    flex-shrink: 0;
  }
`;

const Header = styled.div`
  flex-grow: 1;
  background-color: ${props => props.theme.flatWhite};
  color: ${props => props.theme.darkGray};
  font-size: 15px;
  font-weight: bold;
  line-height: ${headerHeight};
  padding-left: 20px;
`;

const ImageListOuter = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: auto;
`;

const ImageList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 16px;

  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface Props {
  onSelect?: (newImages: string[]) => void;
  selectedImages: string[];
}
export const Images: FunctionComponent<Props> = ({ onSelect, selectedImages }) => {
  const { open: openInLightbox } = useLightboxContext();
  const { open: openSelectModal } = useTypedModal(showSelectImagesModal({ selectedImages, onSelect }));

  const numberOfImages = selectedImages ? selectedImages.length : 0;

  return (
    <>
      <HeaderRow>
        <Header>{`Images (${numberOfImages})`}</Header>
        <BoxButton startIcon={<Add />} onClick={openSelectModal}>Manage images</BoxButton>
      </HeaderRow>
      <ImageListOuter>
        <ImageList>
          {numberOfImages > 0 && selectedImages.map((id: Guid) => (
            <ImageCard key={id} id={id} openInLightbox={openInLightbox} />
          ))}
        </ImageList>
      </ImageListOuter>
    </>
  );
};
