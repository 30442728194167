import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Dictionary, SurveyorDashboardAssignment } from '@/types';
import { useCaseFavourites, CaseFavorites } from '@/services/caseFavoritesServices';
import { useSurveyorDashboardAssignments } from '@/services/dashboardAssignmentsServices';
import { useUserNewestReports } from '@/services/versionsService';
import {
  PageLoader, SortedTableHeader, sortTableByParameter
} from '@instech/components';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { FilterSelectDropdown } from '@/components/shared/FilterableTable/FilterSelect';
import { FilterRow } from '@/components/shared/FilterableTable';
import { convertToSortableCases } from './core/sortableHeaders';
import { AssignmentsTable } from './core/AssignmentsTable';

const Wrapper = styled.div`
  padding: 60px 40px 40px;
`;

const Container = styled.div`
  background-color: ${props => props.theme.white};
  border-top: 6px solid ${props => props.theme.maritime2};
  border-radius: 2px 2px 0px 0px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

interface InnerProps {
  cases: SurveyorDashboardAssignment[];
  reportVersions: Dictionary<ReportVersion>;
  favourites: CaseFavorites;
}
const SurveyorAssignmentsInner = ({ cases, reportVersions, favourites }: InnerProps) => {
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();

  const sortableCases = useMemo(() => {
    if (!cases || !reportVersions || !favourites) return cases;
    return convertToSortableCases(cases, reportVersions, favourites.caseId);
  }, [cases, reportVersions, favourites]);

  const sortedCases = useMemo(() => {
    if (!sortableCases) return sortableCases;
    return sortTableByParameter(sortableCases, sortedHeader?.propertyName, sortedHeader?.sortOrder);
  }, [sortableCases, sortedHeader]);

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  return (
    <AssignmentsTable
      cases={sortedCases}
      favourites={favourites?.caseId}
      reportVersions={reportVersions}
      allowFilters
      changeFilter={() => {}}
      sortedHeader={sortedHeader}
      setSortedHeader={handleSortedHeader}
    />
  );
};

// Exported this out in a component above, so that the rest can load
// with the current user and claim handlers already loaded
export const SurveyorAssignments = () => {
  const [showOpen, setShowOpen] = useState(true);
  const { data: cases, error } = useSurveyorDashboardAssignments(showOpen);
  const { data: reportVersions } = useUserNewestReports();
  const { data: favourites } = useCaseFavourites();

  if (error) {
    throw error;
  }

  const pending = !cases || !favourites || !reportVersions;

  const changeOpenStatus = (value: string) => {
    const valToBool = value === 'Open';
    setShowOpen(valToBool);
  };

  if (pending) return <PageLoader />;

  return (
    <Wrapper>
      <Heading>
        <Title>My Assignments</Title>
      </Heading>
      <Container>
        <FilterRow>
          <FilterSelectDropdown
            blocked={pending}
            parameterName="CaseStatus"
            entries={['Open', 'Closed']}
            selected={showOpen ? 'Open' : 'Closed'}
            onChange={changeOpenStatus}
          />
        </FilterRow>
        <SurveyorAssignmentsInner
          cases={cases}
          reportVersions={reportVersions}
          favourites={favourites}
        />
      </Container>
    </Wrapper>
  );
};
