// Yup has some ${curly} syntax it uses internally when parsing text,
// that shouldn't be read as a template literal in this file.
/* eslint-disable no-template-curly-in-string */

import * as Yup from 'yup';
import moment from 'moment';
import {
  isValidFileExtension, maxFileSize, maxFileSizeString
} from './file';
import { isRealNumber } from './predicates';

export const regexTestDecimals = (spaces: number = 2) => (value: any) => (
  new RegExp(`^(-?|\\d)\\d+\\.?(\\d{0,${spaces}})$`).test(value as string)
);

export const datePickerOptional = () => (
  Yup.string()
    .nullable()
    .test('Is valid  ISO date', 'Invalid date', value => {
      if (value === null || value === undefined) return true;
      return moment.utc(value, moment.ISO_8601, true).isValid();
    })
    .test('min date', 'The date cannot be before the year 2000', value => {
      if (value === null || value === undefined) return true;
      return moment.utc(value).isAfter('2000-01-01');
    })
);

export const datePickerRequired = (message = 'You must pick a date') => (
  Yup.string()
    .nullable()
    .required(message)
    .test('Is valid  ISO date', 'Invalid date', value => moment.utc(value, moment.ISO_8601, true).isValid())
    .test('min date', 'The date cannot be before the year 2000', value => moment.utc(value).isAfter('2000-01-01'))
);

export const dateRangeOptionalStart = () => (
  Yup.object({
    endDate: datePickerOptional(),
    startDate: datePickerOptional().when('endDate', (endDate: any, yup: any) => (
      // Test should fail if startDate is after endDate, and pass if it is before or same
      yup.test(
        'overflow',
        'The start date cannot be after the end date',
        (startDate: any) => (startDate && endDate) ? !moment.utc(startDate).isAfter(endDate) : true
      )))
  })
);

export const dateRangeRequiredStart = (message?: string) => (
  Yup.object({
    endDate: datePickerOptional(),
    startDate: datePickerRequired(message).when('endDate', (endDate: any, yup: any) => (
      // Test should fail if startDate is after endDate, and pass if it is before or same
      yup.test(
        'overflow',
        'The start date cannot be after the end date',
        (startDate: any) => endDate ? !moment.utc(startDate).isAfter(endDate) : true
      )))
  })
);

export const currencyRequired = () => (
  Yup.number()
    .typeError('Not a valid number')
    .required('Required')
    .min(-2147483647, 'Value is too low')
    .max(2147483647, 'Value is too high')
    .test('decimals', 'Too many decimal spaces', regexTestDecimals())
);

/**
 * String implementation of currencyRequired, as a fallback for some edge-cases
 * where string formatting is used but the value needs a currency-based validation.
 */
export const currencyRequiredString = (requiredMessage: string = 'Required', min = 0, decimals = 2) => (
  Yup.string()
    .required(requiredMessage)
    .test('valid-number', 'Not a valid number', isRealNumber)
    .test('minimum', 'Value is too low', value => {
      if (!value) return true;
      // Dirty catch negative -0, so it doesn't false-positive as 0
      if (min === 0 && value.startsWith('-0')) return false;
      return parseFloat(value) >= min;
    })
    .test('decimals', 'Too many decimal spaces', regexTestDecimals(decimals))
);

export const daysRequired = () => (
  Yup.number()
    .typeError('Not a valid number')
    .integer('No decimals allowed')
    .min(0, 'Number of days cannot be less than 0')
    .max(9999, 'Number of days is too high')
    .required('Days required')
);

export const stringRequired = (message = 'Required') => (
  Yup.string()
    .default('')
    .trim()
    .required(message)
);

export const stringMaxLength = (maxLength = 60) => (
  Yup.string()
    .default('')
    .nullable()
    .trim()
    .max(maxLength, 'Cannot be longer than ${max} characters')
);

export const stringRequiredMaxLength = (maxLength = 60, message = 'Required') => (
  Yup.string()
    .default('')
    .nullable()
    .trim()
    .max(maxLength, 'Cannot be longer than ${max} characters')
    .required(message)
);

// As Yup validates all tests asynchronously, there's no way to conditionally
// validate a rule based on previous rules in the chain. In this case we only want
// to validate the file tests if the file is defined. Therefore, if it's not defined
// we set it to a default value that will pass validation.
export const fileRequired = (validFileExtensions: string[], extendedMessage: boolean = true) => Yup.mixed()
  .nullable()
  .default({ size: 1, name: `valid${validFileExtensions[0]}`, isDefault: true })
  .test('isDefined', 'Required', value => !value.isDefault)
  .test('fileSizeMax', `File size is too large. Max size is ${maxFileSizeString}`, value => value.size <= maxFileSize)
  .test('fileSizeMin', 'File size is too small.', value => value.size > 0)
  .test(
    'fileExtension',
    `Not a valid file extension.${extendedMessage ? ` Valid file extensions are ${validFileExtensions.join(', ')}` : ''}`,
    value => {
      const result = isValidFileExtension(value?.name, validFileExtensions);
      return result;
    }
  );

const supportedImageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png'
];
export const imageRequired = () => Yup.mixed()
  .test('fileSize', `File size is too large. Max size is ${maxFileSizeString}`, value => value.size <= maxFileSize)
  .test('fileSizeMin', 'File size is too small.', value => value.size > 0)
  .test('fileType', 'Unsupported File Format', value => supportedImageTypes.includes(value.type));

export const optionalComment = (includeFieldName: string) => (
  Yup.string()
    .when(includeFieldName, {
      is: true,
      then: stringRequiredMaxLength(500, 'Please add a comment'),
      otherwise: stringMaxLength(500)
    })
);

export const yesNoOption = () => Yup.string().oneOf(['Yes', 'No']);

const LabelValuePairShape = { label: Yup.string().default('').required('Required'), value: Yup.string().default('').required('Required') };
export const dropdownOptionRequired = () => Yup.object(LabelValuePairShape).typeError('Required');

// returns true if the UserPicker array contains no valid items
const noValidUserPickerItems = (array: any[]) => array.every(
  item => !(item && typeof item === 'object' && item.id)
);

/**
 * Validation schema for selecting multiple users in a UserPickerMulti component.
 * TODO: Should later be moved to @instech/component and then used from there instead.
 */
export const userPickerMultiRequired = (message: string = 'Required') => Yup.array()
  .test('has-values', message, array => {
    if (!array?.length) {
      return false;
    }
    if (noValidUserPickerItems(array)) {
      return false;
    }
    return true;
  });

/**
 * Validation schema for selecting a single user in a UserPicker component.
 */
export const userPickerRequired = (message: string = 'Required') => Yup.object()
  .test('has-values', message, item => !noValidUserPickerItems([item]));
