import styled from 'styled-components';
import { ToolButton } from '@/components/shared/ToolButton';
import { useModalContext } from '@/components/modal/ModalContext';
import { useManifest } from '@/services/manifestServices';
import { getReportTools } from '@/services/toolsServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { BoxButton } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useFeatureFlag } from '@/providers/featureFlagProvider';
import { createRemoteSurveyAsync } from '@/services/remoteSurvey';
import { ToolPane } from '../../../shared/ToolPane';

const ToolArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  background: ${props => props.theme.lightBlue};
  padding: 20px;
  @media(max-width: 880px) {
    grid-template-columns: repeat(2, 1fr);
  };
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 20px;
`;

export const NavButtons = () => {
  const { open } = useModalContext();
  const role = useCurrentRole();
  const enableVSightSession = useFeatureFlag('vSightSession');
  const caseId = useCaseIdFromUrl();
  const { data: manifest } = useManifest();
  const tools = getReportTools(manifest?.reportTools, role.name);

  const submitNewRemoteSurvey = async () => {
    const data = await createRemoteSurveyAsync(caseId);
    window.open(data.remoteSurveyLink, '_blank', 'noreferrer');
  };

  // For now we just create the session with the caseId without modal
  // Need to decide the proper flow for this
  // Replace button onclick with the next line function for modal display
  // const showVSightSessionCreation = openVSightTool({ caseId });

  return (
    <ToolPane title="Surveyor's tools">
      <ToolArea>
        {tools.map(({ label, icon: ToolIcon, onclick }) => (
          <ToolButton key={label} max-width="75px" onClick={() => open(onclick)} icon={<ToolIcon />}>
            {label}
          </ToolButton>
        ))}
      </ToolArea>
      {enableVSightSession && (
        <ButtonArea>
          <BoxButton inverted onClick={() => submitNewRemoteSurvey()}>Start Remote Survey</BoxButton>
        </ButtonArea>
      )}
    </ToolPane>
  );
};
