import { axiosStatusCodeFromError } from '@/utils/error';
import { ErrorComponentProps } from '@instech/components';
import { FC, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { ErrorNotAllowed } from './ErrorNotAllowed';
import { ErrorNotFound } from './ErrorNotFound';
import { errorStatuses } from './types';

const unauthorizedPageStatuses = [errorStatuses.unauthorized, errorStatuses.forbidden];

const isUnauthorized = (statusCode: number | undefined) => {
  if (!statusCode) return false;
  return unauthorizedPageStatuses.includes(statusCode);
};

export const ErrorPage: FC<ErrorComponentProps> = ({ error, reset }) => {
  const location = useLocation();
  const [prevLocation] = useState(location);

  const axiosStatusCode = axiosStatusCodeFromError(error);

  // Resets error boundary if user navigates away from page
  if (location !== prevLocation) {
    reset();
  }

  // Unauthorized is a dedicated error page that uses the account API to allow logout
  if (isUnauthorized(axiosStatusCode)) {
    return <Redirect to="/unauthorized" />;
  }

  if (axiosStatusCode === errorStatuses.notFound) {
    return <ErrorNotFound reset={reset} />;
  }

  if (axiosStatusCode === errorStatuses.notAllowed) {
    return <ErrorNotAllowed reset={reset} />;
  }

  return <ErrorNotAllowed reset={reset} />;
};
