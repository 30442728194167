import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { companyParticularsBaseUrl, updateCompanyParticularAsync } from '@/services/companyParticularsServices';
import { CompanyParticular } from '@/types';
import { textLength } from '@/utils/validation';
import { FormikWithPrompt as Formik, TextField } from '@instech/components';
import { Form } from 'formik';
import styled from 'styled-components';
import { useSWRConfig } from 'swr';
import { companyParticularSchema } from '../../core/utils';
import { ParticularFormLayout } from '../../core/Components';

const Container = styled.div`
  background-color: ${props => props.theme.background.primary};
  padding: 15px;
  &:not(:first-child) {
    margin-top: 6px;
  }
  &:not(:last-child){
    margin-bottom: 6px;
  }
`;

interface Props {
  toggleEditMode: () => void;
  initialData: CompanyParticular;
}
export const CompanyParticularRowEdit = ({ initialData, toggleEditMode }: Props) => {
  const { mutate } = useSWRConfig();
  const caseId = useCaseIdFromUrl();

  const submit = async ({ title, description }: { title: string, description: string }) => {
    const submitData = { ...initialData, title, description };
    await updateCompanyParticularAsync(caseId, submitData);
    await mutate(companyParticularsBaseUrl(caseId));
  };

  return (
    <Container>
      <Formik
        initialValues={{ title: initialData.title, description: initialData.description }}
        validationSchema={companyParticularSchema}
        onSubmit={async values => {
          await submit(values);
          toggleEditMode();
        }}>
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Form>
            <ParticularFormLayout>
              <TextField
                name="title"
                label="Function"
                placeholder='"Ship Owner"'
                maxLength={textLength.short} />
              <TextField
                name="description"
                label="Company"
                placeholder='"High Sea Shipping LTD"'
                maxLength={textLength.medium} />
            </ParticularFormLayout>
            <SaveCloseButtons
              saveLabel="Save"
              closeLabel="Cancel"
              onSave={handleSubmit}
              onClose={toggleEditMode}
              disableSave={!dirty}
              isSubmitting={isSubmitting}
              marginTop="15px"
              alignRight
              reverse />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
