import { Case, ClaimOccurrenceOverview } from '@/types';
import { isRelatedToYear } from '@/utils/claimUtils';

export function distinctBy<T>(elements: T[], key: (element: T) => unknown) {
  return Array.from(new Map(elements.map(element => [key(element), element])).values());
}

export function distinct<T>(array: T[]) {
  return distinctBy(array, x => x);
}

export type ClaimWithReplacementOptions = {
  original: ClaimOccurrenceOverview;
  optionsForClaim: ClaimOccurrenceOverview[];
};

export type InsuranceYearWithReplacementOptions = {
  insuranceYear: number;
  claimOptions: ClaimWithReplacementOptions[];
};

const optionsForClaim = (
  toBeReplaced: ClaimOccurrenceOverview,
  claims: ClaimOccurrenceOverview[],
  year: number
): ClaimWithReplacementOptions => ({
  original: toBeReplaced,
  optionsForClaim: claims
    .filter(x => isRelatedToYear(x, year))
    .filter(x => x.claimInterest === toBeReplaced.claimInterest)
});

const optionsForYear = (
  claims: ClaimOccurrenceOverview[],
  claimsToBeReplaced: ClaimOccurrenceOverview[],
  year: number
) : InsuranceYearWithReplacementOptions => {
  const claimOptions = claimsToBeReplaced.map(claim => optionsForClaim(claim, claims, year));

  return {
    insuranceYear: year,
    claimOptions
  };
};

const createOptions = (
  surveyCase: Case,
  claims: ClaimOccurrenceOverview[],
  claimsInUse: ClaimOccurrenceOverview[]
) : InsuranceYearWithReplacementOptions[] => {
  const policyYears = claims
    .map(x => x.insuranceYear);

  const dateOfLossYears = claims
    .map(x => x.dateOfLoss ? new Date(x.dateOfLoss).getFullYear() : null)
    .filter(x => x !== null) as number[];

  const mainClaim = claims.find(claim => claim.id === surveyCase?.claimId);
  const years = distinct([...policyYears, ...dateOfLossYears].filter(x => x !== mainClaim?.insuranceYear));

  return years
    .map(x => optionsForYear(claims, claimsInUse, x))
    .filter(x => x.claimOptions.every(y => y.optionsForClaim.length > 0));
};

export const useChangeInsuranceYearOptions = (
  surveyCase: Case,
  claims: ClaimOccurrenceOverview[],
  claimsInUse: ClaimOccurrenceOverview[]
) => {
  const options = createOptions(surveyCase, claims, claimsInUse);

  return ({
    options,
    years: options.map(x => x.insuranceYear)
  });
};
