import { useCaseStatus } from '@/services/caseStatusServices';
import { transformTOC, useManifest } from '@/services/manifestServices';
import { dashboardRoute } from '@/utils/routeTemplates';
import { PageLoader } from '@instech/components';
import { useState } from 'react';
import { Redirect } from 'react-router';
import { Content, Sidebar } from '../../layout/EditGrid';
import { EditNav } from '../../layout/navigation/EditNav';
import { EditPage as EditLayout } from '../../layout/Page';
import { EditProvider } from './core/EditContext';
import { EditRouter } from './routing/EditRouter';

export const EditPage = () => {
  const { data, error } = useManifest();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { data: caseStatus } = useCaseStatus();

  if (error) {
    throw error;
  }

  if (!data || !caseStatus) {
    return <PageLoader />;
  }

  if (caseStatus.isClosed) {
    return <Redirect to={dashboardRoute(data.caseId)} />;
  }

  const chapters = transformTOC(data);

  return (
    <EditProvider value={{ toc: data, chapters }}>
      <EditLayout title={data.title}>
        <Sidebar>
          <EditNav onMobileOpen={setMobileMenuOpen} />
        </Sidebar>
        <Content visible={!mobileMenuOpen}>
          <EditRouter />
        </Content>
      </EditLayout>
    </EditProvider>
  );
};
