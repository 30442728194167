import { UsersInCase } from './components/pages/admin/adminServices';

export type Nullable<T> = T | undefined | null;

export type Maybe<T> = NonNullable<T> | undefined;

export type Guid = string;

export type TimeStatus = 'Estimate' | 'Quoted / Verified' | 'Final';

export interface ApplicationRole {
  name: string;
  displayName: string;
}

export interface RoleApiRole {
  role: string;
  portal: string;
  displayName?: string;
}

export interface LabelValuePair {
  value: string;
  label: string;
}

export interface PersonallyIdentifiableInformation {
  includesEu: boolean;
}

export const claimInterests = {
  hullAndMachinery: 'HullAndMachinery',
  lossOfHire: 'LossOfHire'
} as const;

export type ClaimInterest = typeof claimInterests[keyof typeof claimInterests];

export interface Interest {
  interest: string;
  claimId: string | null;
  deductible: string | null;
  originalDeductible: { value: string } | null;
  ourShare: number | null;
  claimReference: string;
  interestGroup?: string | null;
  claimInterest: ClaimInterest;
  claimLeaderName?: string | null;
  activeClaimWall: boolean;
  claimEventName?: string | null;
}

export interface Occurrence {
  id: string;
  name: string;
  charter: string;
  location: string;
  dateOfLoss: string;
  timeOfLoss?: string | null;
  timezoneId?: string | null;
  conditions?: string | null;
  claimEventName?: string | null;
  fleetName?: string | null;
  className?: string | null;
  status?: TimeStatus | null;
  interests: Interest[];
}

export interface Option {
  id: string;
  name: string;
}

export interface ValueOption {
  id: string;
  value: string;
}

export interface TimelineEntryOption {
  name: string,
  selectOccurrences: boolean,
  useCustomName: boolean,
}

export interface TimelineEntryGroup {
  name: string,
  options: TimelineEntryOption[]
}

export interface Duration {
  startDate: string | null;
  endDate: string | null;
}
export interface SurveyLocation {
  id: string;
  location: string;
  duration: Duration;
  occurrences: Option[];
  report?: boolean;
}

export interface Contact {
  id: string;
  name: string;
  function: string;
  phone: string;
  email: string;
}

export interface BrokerContact {
  id: string;
  caseId: string;
  externalId: string;
  companyName: string;
  name: string;
  email: string;
  phone: string;
}

export interface Introduction {
  id: string;
  comment: string;
  attendance?: boolean;
  remote?: boolean;
  withoutAttendance?: boolean;
}

// @HN: checkme: rename to description base and rename the widget postfix to only description?
// fixme models in backend to have a base description without title/occurrence
export interface DescriptionBase {
  text: string;
  images: string[];
}

export interface Subcategory {
  widgetId: Guid;
  title: string;
  selected: boolean;
  occurrenceId?: Guid;
}

export interface DescriptionWidget extends DescriptionBase {
  occurrenceId?: Guid;
  showTitle?: boolean;
  title: string;
  hideImages: boolean;
}

export interface ImmediateFeedback extends DescriptionBase {
  isPublished: boolean;
}

export interface ItemDescription {
  description: string;
  details: DescriptionBase;
}

export interface DamageDescription {
  occurrenceId: Guid;
  damages: ItemDescription[];
}

export interface RepairDescription {
  occurrenceId: Guid;
  repairs: ItemDescription[];
}

export interface CompanyParticular {
  id: string;
  title: string;
  description: string;
  source?: {
    name: string;
    date: string;
  }
}

interface ExpenseBase {
  id: Guid,
  name: string;
  cost: number;
  status: string;
  includeInReport: boolean;
}

export interface Expense extends ExpenseBase {
  comment: string;
  includeCommentInReport: boolean;
}

export interface ExpenseFormType extends Omit<Expense, 'status' | 'cost'> {
  status: LabelValuePair;
  cost: string;
}

export interface ArchivedExpense extends ExpenseBase {
  comment: string | null;
}

interface ExpenseGroupBase {
  id: Guid;
  name: string;
  currency: string;
  sum: number;
  status: string;
}

export interface ExpenseGroup extends ExpenseGroupBase {
  items: Expense[];
}

export interface ArchivedExpenseGroup extends ExpenseGroupBase {
  chapterId: string;
  items: ArchivedExpense[];
}

export interface TimelineItem {
  id: string;
  eventType: string;
  timeStatus: string;
  movementType: string;
  typeOfActivity: string;
  eventDate: string;
  utcEventDate: string;
  eventTime?: string;
  timezoneId: string;
  location: string;
  includeInReport: boolean;
  name: string;
  comment: string;
  occurrences: Option[];
  customActivityName?: string;
}

export type WidgetType =
  | 'AttendingRepresentatives'
  | 'Comments'
  | 'Description'
  | 'Expenses'
  | 'ExpensesSummary'
  | 'GeneralExpenses'
  | 'Introduction'
  | 'Occurrences'
  | 'TimeDetails'
  | 'TimeSavedDetails'
  | 'TimeSummary'
  | 'SurveyLocations'
  | 'VesselEvents';

export interface DataSource {
  name: string;
  date: string;
}

export interface Field {
  name: string;
  label: string;
  placeholder: string;
}

export interface ChapterReference {
  caseId: string;
  chapterId: string;
  title: string;
}

export interface WidgetReference extends ChapterReference {
  widgetId?: Guid;
  widgetTitle: string;
  widgetType: string;
  editable?: boolean;
  data?: any;
}

export interface Dictionary<T = any> {
  [key: string]: T;
}
export interface TimeSaved {
  id: string;
  days: number;
  comment: string;
  includeInReport: boolean;
}

export interface Comment {
  comment: string;
  includeInReport: boolean;
}

export interface CaseMetadata {
  interest: string;
  conditions: string;
  policyCurrency: string;
  expenseCurrency: string | null;
  deductible: string;
  claimReference: string;
  vesselName: string;
  claimId: Guid | null;
}

export interface Case {
  id: Guid;
  claimId?: Guid;
  name: string;
  template: string;
  createdAt: string;
  metadata: CaseMetadata;
  finalized: boolean;
  users?: UsersInCase;
  externalReference: string | undefined;
  companyName?: string;
  templateId: string;
  userCannotAccessBehindWall: boolean;
}

export interface CaseTasks {
  caseId: Guid;
  hasAvailableInformationTask: boolean;
  hasOccurrenceTask: boolean;
}

export interface CaseNotifications {
  caseId: Guid;
  hasArchiveVersionNotification: boolean;
  hasImmediateFeedbackNotification: boolean;
}

export interface DashboardAssignment {
  caseId: Guid,
  name: string,
  createdAt: string,
  finalized: boolean;
  occurrences: Occurrence[] | null;
  surveyCompanyName: string;
}

export interface ClaimsHandlerDashboardAssignment extends DashboardAssignment {
  broker: string;
  caseTasks: CaseTasks;
  notifications: CaseNotifications
}

export interface SortableCase extends ClaimsHandlerDashboardAssignment {
  sortableDateOfLoss: string | null;
  sortableFleet?: string | null;
  sortableInterest?: string | null;
  sortableSurveyCompany?: string | null;
  sortableOccurrenceName?: string | null;
  sortableReportStatus?: string | null;
  sortableFavourite: boolean;
  sortableDeductable?: string | null;
  sortableTask: boolean;
}

export interface SurveyorDashboardAssignment extends DashboardAssignment {
  claimsHandler: string;
}

export interface SortableCaseSurveyor extends SurveyorDashboardAssignment {
  sortableClaimNumber: string | null;
  sortableDateOfLoss: string | null;
  sortableFleet?: string | null;
  sortableInterest?: string | null;
  sortableClaimEvent?: string | null;
  sortableReportStatus?: string | null;
  sortableDeductible?: string | null;
  sortableFavourite: boolean;
}

export interface ClaimOccurrence {
  id: string;
  claimReference: string;
  dateOfLoss?: string;
  conditions: string | null;
  interest: string;
  claimLeaderName: string | null;
  ourShare?: number;
  claimEventName: string | null;
  activeClaimWall?: boolean;
  fleetName: string;
  className: string;
  deductible: string | null;
  currency: string;
}

export interface ClaimOccurrenceOverview {
  id: string;
  insuranceYear: number;
  claimReference: string;
  dateOfLoss?: string;
  interest: string;
  claimInterest: ClaimInterest;
}

export interface CaseInstruction {
  id: string;
  instructions: string | null;
}

export interface DockDays {
  dryDock: number;
  afloat: number;
}

export interface SimultaneousRepairs {
  id: Guid;
  days: number;
  status: TimeStatus;
  comment: string;
  includeCommentInReport: boolean;
}

export interface TimeDetailsBase {
  id: string;
  chapterId: string;
  occurrenceId?: string;
  title: string;
  duration?: Duration;
  durationTimeStatus: TimeStatus
  actualDays: DockDays;
  durationComment?: string;

  separateDays: DockDays;

  separateDaysDryDockComment?: string;
  separateDaysDryDockTimeStatus: TimeStatus;

  separateDaysAfloatComment?: string;
  separateDaysAfloatTimeStatus: TimeStatus;
  simultaneousRepairs: SimultaneousRepairs | null;
}

export interface TimeDetails extends TimeDetailsBase {
  includeDurationComment: boolean;
  includeSeparateDaysDryDockComment: boolean;
  includeSeparateDaysAfloatComment: boolean;
}

interface SimultaneousRepairsForm extends Omit<SimultaneousRepairs, 'status'> {
  status: LabelValuePair;
}

export interface TimeDetailsForm
  extends Omit<TimeDetails, 'durationTimeStatus' | 'separateDaysDryDockTimeStatus' | 'separateDaysAfloatTimeStatus' | 'simultaneousRepairs'> {
  durationTimeStatus: LabelValuePair
  separateDaysDryDockTimeStatus: LabelValuePair;
  separateDaysAfloatTimeStatus: LabelValuePair;
  simultaneousRepairs: SimultaneousRepairsForm | null;
}

export interface ArchivedTimeDetails extends TimeDetailsBase {
  widgetText: string | null;
}

interface SummaryBase {
  id: string;
  chapterId: string;
  title: string;
  isOccurrence: boolean;
}

export interface SummaryLine {
  name: string;
  cost: number;
  comment: string | null;
}

export interface SummaryOfCost extends SummaryBase {
  status?: string;
  currency: string;
  sum: number;
  lines?: SummaryLine[];
}

export interface ExpenseSummaryGroup {
  chapterId: Guid;
  title: string;
  expenses: SummaryOfCost[];
}

export interface SummaryOfTime extends SummaryBase, DockDays {
  duration: Duration;
  durationStatus: TimeStatus;
  dryDockStatus: TimeStatus;
  afloatStatus: TimeStatus;
}

export interface SummaryOfTimeGroup {
  chapterId: Guid;
  title: string;
  timeSummaries: SummaryOfTime[];
}

export interface UserInfo {
  id: Guid;
  fullName: string;
  displayName: string;
  company: string;
  companyId?: Guid;
  email: string;
  phoneNumber: string | null;
  title: string | null;
  hasAcceptedTerms: boolean;
  hasSeenWelcomePage: boolean;
}

export interface SignatureUserInfo {
  id: Guid;
  name: string;
}

export interface CompanyInfo {
  id: Guid;
  name: string;
}

export interface CompanyLogoLinkModel {
  id: Guid;
  url: string;
  companyId: Guid;
  archivedVersionId: Guid;
  height: number;
  width: number;
}

export interface CaseStatusHistoryItem {
  isClosed: boolean;
  updatedDate: string;
  lastUpdatedBy: string | null;
  lastUpdatedByUserId: Guid | null;
}

export type YesOrNo = 'Yes' | 'No';

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';

export enum HiddenFlags {
  None = 0,
  ArchivedPdfReport = 1,
  ArchivedReport = 2,
  ReportPreview = 4,
  ReportEdit = 8
}

export interface WidgetTemplate {
  title: string;
  hidden: HiddenFlags
}

export interface ChapterTemplate {
  title: string;
  hidden: HiddenFlags
  widgets: WidgetTemplate[];
}

export interface PartTemplate {
  title: string;
  chapters: ChapterTemplate[];
}

export interface ToolTemplate {
  name: string;
  widgets: WidgetTemplate[];
}

export interface ReportTemplate {
  parts: PartTemplate[];
  tools: ToolTemplate[];
}

export interface RemoteSurvey {
  caseId: string;
  meetingLink: string;
  remoteSurveyLink: string;
}
