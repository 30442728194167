import { OccurrenceSchema } from '@/components/pages/Dashboard/ClaimsHandlerDashboard/Occurrences/UpdateOccurrenceModals/OccurrenceValidator';
import { ClaimOccurrenceOverview } from '@/types';
import { getFriendlyDate } from '@instech/components';
import { useField } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import { ClaimCheckbox } from '../core/ClaimCheckbox';
import { InstructionText } from '../core/FieldComponents';
import { InterestDeductibleField } from './InterestDeductibleField';

const ClaimList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ClaimContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto) min-content;
  column-gap: 32px;
  row-gap: 12px;
  padding: 16px;
  background-color: ${props => props.theme.marineBlue10};
  color: ${props => props.theme.marineBlue};
  border-radius: 4px;
`;

const ClaimValue = styled.div`
  margin-top: 4px;
  font-weight: bold;
`;

interface SelectableInterestsProps {
  claims: ClaimOccurrenceOverview[] | undefined;
  label: string;
}

/**
 * Lists selectable interests interests for occurrence as a list of blocks.
 *
 * Right now, this component takes in both 'claims' from the ClaimsOccurrenceOverview
 * and 'claims' from the Occurrence form. The form only contains the editable values present,
 * but there are additional details concerning each interest that has to be shown as well.
 */
export const SelectableInterests: FC<SelectableInterestsProps> = ({ claims = [], label }) => {
  const [{ value }] = useField<OccurrenceSchema['claims']>('claims');
  return (
    <div>
      <InstructionText>{label}</InstructionText>
      <ClaimList>
        {claims.map((claim, index) => (
          <ClaimContainer key={claim.id}>
            <div>
              <div>Claim Number</div>
              <ClaimValue>{claim.claimReference}</ClaimValue>
            </div>
            <div>
              <div>Date of Loss</div>
              <ClaimValue>{getFriendlyDate(claim.dateOfLoss)}</ClaimValue>
            </div>
            <div>
              <div>Interest</div>
              <ClaimValue>{claim.interest}</ClaimValue>
            </div>
            <ClaimCheckbox name={`claims.${index}.selected`} />
            <InterestDeductibleField
              name={`claims.${index}.deductible`}
              label="Deductible"
              isDisabled={!value[index].selected}
              interest={claim.claimInterest}
            />
          </ClaimContainer>
        ))}
      </ClaimList>
    </div>
  );
};
