import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useCompanyLogo } from '@/services/companiesService';
import { useCurrentUserInfo } from '@/services/userInfoService';
import { Loader, Pane } from '@instech/components';
import { InfoMessage } from '../../core/InfoMessage';

const StyledImage = styled.img`
  height: 100px;
  max-width: 100%;
  object-fit: scale-down;
`;

interface ImagePreviewProps {
  url?: string;
  isValidating: boolean;
}
const ImagePreview: FunctionComponent<ImagePreviewProps> = ({ url, isValidating }) => {
  if (isValidating) {
    return <Loader />;
  }

  if (!url) {
    return null;
  }

  return <StyledImage src={url} alt="Company logo" />;
};

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const CompanyLogo: FunctionComponent = () => {
  const { data: userInfo } = useCurrentUserInfo();
  const { data, error, isValidating } = useCompanyLogo(userInfo?.companyId);

  if (error) {
    throw error;
  }

  return (
    <Pane title="Logo" margin="32px 0px 0px">
      <AlignCenter>
        <InfoMessage message="Only an administrator can upload a company logo." />
        <ImagePreview url={data?.url} isValidating={isValidating} />
      </AlignCenter>
    </Pane>
  );
};
