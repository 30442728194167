import { charterOptionsByGroups } from '@/components/pages/edit/widgets/Occurrences/core/options';
import { Dropdown, TextField } from '@instech/components';

import { useField } from 'formik';
import {
  ComponentProps, FC, PropsWithChildren
} from 'react';
import styled from 'styled-components';

export const OccurrenceFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

const StyledInstructionText = styled.label<{ hasError: boolean }>`
  display: block;
  font-weight: bold;
  color: ${({ theme, hasError }) => hasError ? theme.warning : theme.marineBlue};
  margin-bottom: 8px;
  ::after {
    content: '*';
    color: ${({ theme }) => theme.warning};
  }
`;

// Styles wouldn't apply directly to the dropdown.
const DropdownContainer = styled.div`
  .occurrence-modal-prefix__menu {
    z-index: 200;
  }
`;

export const InstructionText: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [, { touched, error }] = useField<string[]>('claims');
  // Testing typeof error because Deductible error can be nested inside it
  const hasClaimsError = touched && typeof error === 'string';

  return (
    <StyledInstructionText htmlFor="selectedClaims" hasError={hasClaimsError}>
      {children}
    </StyledInstructionText>
  );
};

export const OccurrenceNameInput: FC<ComponentProps<typeof TextField>> = props => (
  <TextField required label="Occurrence Name" placeholder="" name="occurrenceName" {...props} />
);

export const OccurrenceCharterDropdown: FC = () => (
  <DropdownContainer>
    <Dropdown
      placeholder="Select Charter"
      label="Charter"
      name="charter"
      classNamePrefix="occurrence-modal-prefix"
      clearable
      options={charterOptionsByGroups}
    />
  </DropdownContainer>
);
