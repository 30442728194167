import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ChangeInsuranceYearFormState } from './formstate';

export const ClearReplacementsWhenPolicyYearChanges: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ChangeInsuranceYearFormState>();

  useEffect(() => {
    void setFieldValue('claims', values.claims.map(claim => ({ ...claim, replacement: '' })));
  }, [values.insuranceYear]);

  return null;
};
