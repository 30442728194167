/* eslint-disable react/forbid-prop-types */
import { FunctionComponent } from 'react';
import { CertificateListItem } from '@/services/certificatesServices';
import { CertificateWithCheckbox } from './CertificateWithCheckbox';
import { CertificateEntryList } from './CertificateEntryList';
import { CertificateSection } from '../Overview';

interface Props {
  header: string;
  fieldName: string;
  onChange: (newValue: any) => void;
  entries: CertificateListItem[];
  value: boolean;
}
export const RelevantCertificates: FunctionComponent<Props> = (
  { header, fieldName, onChange, entries, value }
) => {
  const showInvalidList = value === false;
  return (
    <CertificateSection>
      <CertificateWithCheckbox
        header={header}
        value={value}
        onChange={onChange}
      />
      {showInvalidList && (
        <CertificateEntryList
          fieldName={fieldName}
          entries={entries}
          nameLabel="Invalid certificate"
          dateLabel="Expiry date"
          placeholder='Identify invalid certificate, e.g. "Bottom survey"'
        />
      )}
    </CertificateSection>
  );
};
