import { AxiosError } from 'axios';
import useSWR from 'swr';

export interface Instruction {
  reference: string;
  description: string;
  details: string;
}

const tenMinutesInMs = 1000 * 60 * 10;

export const useInstructions = (instructionsKey: string | null) => {
  const apiUrl = instructionsKey ? `instructions/${instructionsKey}` : null;

  const { data, error } = useSWR<Instruction, AxiosError>(apiUrl, { dedupingInterval: tenMinutesInMs });

  if (error) {
    throw error;
  }

  if (!instructionsKey) {
    return undefined;
  }

  return data;
};
