import styled, { css } from 'styled-components';

export const FieldLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
  display: block;
  height: 15px;
  margin-top: 5px;
  color: ${props => props.theme.red};
  font-size: 11px;
`;

interface FieldLabelProps {
  isDisabled?: boolean;
}
export const FieldLabel = styled.span<FieldLabelProps>`
  display: inline-block;
  height: 15px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
  ${props => props.isDisabled && css`
    color: ${props.theme.marineBlue60};
    cursor: default;
  `}
`;

export const EmptyLabel = styled.span`
  height: 15px;
  margin-bottom: 12px;
`;

interface InputFieldProps {
  error?: string | boolean;
}
export const InputField = styled.div<InputFieldProps>`
  & select,
  & input {
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.border.gray};
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    appearance: none;
    
    ${props => props.error && css`
      border-color: ${props.theme.red};
      color: ${props.theme.red};
      &::placeholder {
        color: ${props.theme.red};
        opacity: 0.6;
      }
    `};
    &:disabled {
      border-color: ${props => props.theme.marineBlue30};
      color: ${props => props.theme.marineBlue60};
      background-color: ${props => props.theme.disabled25};
    }
  }
`;
