import { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { Form } from 'formik';
import { deleteImageAsync, useUploadFrontpageImage } from '@/services/imageServices';
import { Guid } from '@/types';
import { imageRequired } from '@/utils/validationSchemas';
import { SaveButton, CloseButton } from '@/components/shared/FormButton/FormButton';
import {
  ButtonGroup, Dropzone, FormRow, FormikWithPrompt as Formik
} from '@instech/components';

interface FormModel {
  frontpageImage: any;
}
const imageSchema: FormModel = {
  frontpageImage: null,
};

const validationSchema = Yup.object().shape({
  frontpageImage: imageRequired()
});
interface Props {
  caseId: Guid;
  currentImageId?: Guid;
  handleClose: () => void;
}
export const UploadForm: FunctionComponent<Props> = ({ caseId, currentImageId, handleClose }) => {
  const { uploadFrontpageImageAsync } = useUploadFrontpageImage(caseId);

  const handleFormSubmit = async (values: FormModel) => {
    const submitValues = {
      image: values.frontpageImage
    };
    const deleteTask = currentImageId ? deleteImageAsync(caseId, currentImageId) : Promise.resolve();
    const uploadTask = uploadFrontpageImageAsync(submitValues);
    await Promise.all([deleteTask, uploadTask]);
    handleClose();
  };

  return (
    <Formik
      initialValues={{ ...imageSchema }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}>
      {({ values, isSubmitting, handleSubmit, dirty, resetForm }) => (
        <Form>
          <FormRow marginBottom={values.frontpageImage && '30px'}>
            <Dropzone name="frontpageImage" />
          </FormRow>
          <ButtonGroup alignRight reverse>
            <SaveButton disabled={!values.frontpageImage} isSubmitting={isSubmitting} onClick={handleSubmit} />
            {currentImageId && <CloseButton label="Cancel" onClick={handleClose} />}
            {(!currentImageId && dirty) && <CloseButton label="Reset" onClick={resetForm} />}
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};
