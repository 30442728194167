import { ExpandableThumbnail } from '@/components/shared/Image';
import { ImagePreview, useImageMetaById } from '@/services/imageServices';
import { Guid } from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { formatBytes } from '@/utils/file';
import { Loader } from '@instech/components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
`;

const Text = styled.div`
  margin-left: 20px;
  word-break: break-word;
  color: ${props => props.theme.marineBlue75};
`;

const Title = styled.span` 
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
`;

const Metadata = styled.span`
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
`;

const DeleteButton = styled.button`
  margin-top: 4px;
  cursor: pointer;
  :hover {
    color: red;
    border-color: red;
    background: #fefefe;
  };
  color: ${props => props.theme.marineBlue75};
  background-color: ${props => props.theme.lightBlue};
  border: 1px solid ${props => props.theme.marineBlue75};
  padding: 12px 32px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
`;

interface EditedImageMetaProps {
  image: ImagePreview;
  onDelete?: (imageId: Guid) => void;
}

const MetaText: FunctionComponent<EditedImageMetaProps> = ({ image, onDelete }) => {
  const { data: meta } = useImageMetaById(image.id);

  if (!meta) {
    return <Text><Loader /></Text>;
  }

  return (
    <Text>
      <Title>{image.title}</Title>
      <Metadata>{getFriendlyDate(meta.uploadedAt)}</Metadata>
      <Metadata>{formatBytes(meta.fileSizeInBytes)}</Metadata>
      <Metadata>{`${meta.width} by ${meta.height} pixels`}</Metadata>
      {onDelete && (
        <DeleteButton type="button" onClick={() => onDelete(meta.id)}>
          Delete Permanently
        </DeleteButton>
      )}
    </Text>
  );
};

export const EditedImageMeta: FunctionComponent<EditedImageMetaProps> = ({ image, onDelete }) => (
  <Flex>
    <ExpandableThumbnail image={image} />
    <MetaText image={image} onDelete={onDelete} />
  </Flex>
);
