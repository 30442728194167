import { ImageView } from '@/components/shared/Image';
import { ImagePreview } from '@/services/imageServices/imageServices';
import { Guid } from '@/types';
import { ImagePreview as InsifyImagePreview } from '@instech/components';
import { FC } from 'react';
import { ImageGridLayout, ImageGridOuter } from './Components';

const isSelected = (id: Guid, selectedIds: Guid[]) => (
  !!selectedIds.find(selectedId => selectedId === id)
);

interface ImageGridProps {
  imageIds: Guid[];
  selectedIds?: Guid[];
  onSelectImage: (image: ImagePreview) => void;
  disableSelect: boolean;
  showPlaceHolderForPiiImages?: boolean;
}
export const ImageGridSelect: FC<ImageGridProps> = ({ imageIds, selectedIds = [], onSelectImage, disableSelect, showPlaceHolderForPiiImages }) => {
  const onClick = (image: InsifyImagePreview) => {
    if (disableSelect) return;
    onSelectImage(image as ImagePreview);
  };

  return (
    <ImageGridOuter>
      <ImageGridLayout>
        {imageIds.map(id => (
          <ImageView
            key={id}
            imageId={id}
            height="145px"
            isSelected={isSelected(id, selectedIds)}
            onClick={onClick}
            disableClick={disableSelect}
            crop="small"
            hidePiiImages={showPlaceHolderForPiiImages}
          />
        ))}
      </ImageGridLayout>
    </ImageGridOuter>
  );
};
