import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import useSWR from 'swr';
import { vesselInformationApiUrl, VesselInformation } from '@/services/vesselInformationServices';
import { AxiosError } from 'axios';

export const useVesselInformation = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<VesselInformation[], AxiosError>(vesselInformationApiUrl(caseId));
  return swr;
};
