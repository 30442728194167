import styled from 'styled-components';
import { InformationBox } from '@/components/shared/InformationBox';
import { InputForm } from '@/components/tools/VesselParticulars/shared/InputForm';

export const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 28px;
  color: ${props => props.theme.marineBlue};
`;

export const InformationLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 8px 0px;
`;

export const InformationLayoutPadded = styled(InformationLayout)`
  padding: 28px 20px 16px;
`;

export const InformationWithDelete = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 8px;
`;

export const Particulars = styled.div`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid black;
`;

// Override InputForm styles for use in Vessel Info and Company Particulars
export const StyledInputForm = styled(InputForm)`
  margin-top: 0px;
  padding: 10px 20px 20px;
  background-color: ${props => props.theme.white};
`;

// adjust padding around info box to align with pane padding
const StyledInfoBox = styled(InformationBox)`
  padding: 12px 20px 0px;
`;

export const ClaimWallWarning = () => (
  <StyledInfoBox paragraphs={['This claim is behind a claim wall']} />
);
