import { ClaimOccurrenceOverview } from '@/types';
import { distinct } from './options';

export interface ChangeInsuranceYearFormState {
  insuranceYear: { label: string; value: string } | undefined;
  claims: { original: ClaimOccurrenceOverview; replacement: string }[];
}

export const initialState = (claims: ClaimOccurrenceOverview[]): ChangeInsuranceYearFormState => ({
  insuranceYear: undefined,
  claims: claims.map(claim => ({ original: claim, replacement: '' }))
});

const selectedClaims = (state: ChangeInsuranceYearFormState, claims: ClaimOccurrenceOverview[]) =>
  claims.filter(claim => state.claims.some(x => x.replacement === claim.id));

export const hasMultipleInsuranceYearsSelected = (state: ChangeInsuranceYearFormState, claims: ClaimOccurrenceOverview[]): boolean => {
  const years = distinct(selectedClaims(state, claims).map(x => x.insuranceYear));
  return years.length > 1;
};
