import * as Yup from 'yup';
import { stringRequiredMaxLength } from '@/utils/validationSchemas';
import { TextFieldProps } from '@instech/components';

// This does not feel like it should be like this, this should have been solved
// either by drilling down components or via composing. But solving that would
// require a bigger refactoring of some very old Tools code.
export const fields: TextFieldProps[] = [
  {
    name: 'title',
    label: 'Title',
    placeholder: 'Add title',
    maxLength: 60
  },
  {
    name: 'value',
    label: 'Description',
    placeholder: 'Add description',
    maxLength: 120
  }
];

export const validationSchema = Yup.object().shape({
  title: stringRequiredMaxLength(),
  value: stringRequiredMaxLength(120),
  includeInReport: Yup.boolean(),
  editable: Yup.boolean()
});
