import useSWR from 'swr';

type UserManual = {
  url: string;
}
export const useUserManual = () => {
  const url = 'usermanuals/surveyors/latest';
  const { data } = useSWR<UserManual>(url);
  return data;
};
