import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useClaimsOccurrenceOverview } from '@/services/claimsOccurrenceServices';
import {
  replaceClaimsAsync, ReplaceClaimsRequest,
  useOccurrencesForCase
} from '@/services/occurrenceServices';
import { useCase } from '@/services/dashboardServices';
import { Loader } from '@instech/components';
import { ChangeInsuranceYearForm, } from './ChangeInsuranceYearForm';
import { ChangeInsuranceYearFormState } from './formstate';

const toRequest = (state: ChangeInsuranceYearFormState): ReplaceClaimsRequest => ({
  replacementClaims: state.claims.reduce((acc, claim) => {
    acc[claim.original.id] = claim.replacement;
    return acc;
  }, {} as { [key: string]: string })
});

export const ChangeInsuranceYearModal = () => {
  const caseId = useCaseIdFromUrl();
  const { data: surveyCase, isValidating: isValidatingSurveyCase } = useCase(caseId);
  const { data: occurrences, isValidating: isValidatingOccurrences } = useOccurrencesForCase(caseId);
  const { data: claimsData, isValidating: isValidatingClaims } = useClaimsOccurrenceOverview(surveyCase?.claimId);
  const { close } = useModalContext();

  const claims = claimsData ?? [];

  if (isValidatingSurveyCase || isValidatingOccurrences || isValidatingClaims) {
    return <Loader />;
  }

  const onSubmit = async (values: ChangeInsuranceYearFormState) => {
    await replaceClaimsAsync(surveyCase!.id, toRequest(values));
    close();
  };

  return (
    <ChangeInsuranceYearForm
      surveyCase={surveyCase!}
      occurrences={occurrences!}
      allClaims={claims}
      onSubmit={onSubmit}
      onCancel={close}
    />
  );
};

export const showChangeInsuranceYearModal = (props: {}) => ({
  component: ChangeInsuranceYearModal,
  options: {
    title: 'Change Insurance Year',
    size: 'medium',
    footer: false,
  },
  args: props
});
