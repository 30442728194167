export function isEmptyOrWhitespace(value: string, throwOnTypeError: boolean = true) {
  if (throwOnTypeError && typeof value !== 'string') {
    throw new TypeError('Value is not of type string');
  }
  return value.trim().length === 0;
}

export function isNullishOrWhitespace(value: any): value is (undefined | null | '') {
  const isNullish = value === null || value === undefined || typeof value === 'undefined';
  return isNullish || isEmptyOrWhitespace(value);
}

export function trimString(value: string) {
  const trimmed = value.trim();
  return trimmed;
}

export function setStringOrDash(value: any) {
  return isNullishOrWhitespace(value) ? '-' : value;
}

export function lowercaseIncludes(a: string, b: string) {
  return a.toLowerCase().includes(b.toLowerCase());
}

export const getOccurrencePrefixTitle = (title: string, isOccurrence?: string | boolean | null) => `${isOccurrence ? 'Occurrence - ' : ''}${title}`;

export const getUrlFriendlyString = (value: string) => value
  .replace(/\s/g, '-')
  .replace(/'/g, '')
  .replace(/&/g, '')
  .toLowerCase();

export const numberToDays = (number: number = 0) => {
  const dayString = number === 1 ? 'day' : 'days';
  return `${number} ${dayString}`;
};

export const sortOnNameAlphabetically = (a: string, b: string) => {
  const aName = trimString(a);
  const bName = trimString(b);
  return aName.localeCompare(bName, 'no', { numeric: true });
};

export const getClaimNumberFromClaimReference = (claimReference: string | undefined) => {
  if (!claimReference) {
    return '';
  }
  const claimNumber = claimReference.split('/')[0];
  return claimNumber;
};

export const concatString = (values: string[] | undefined) => values?.join(', ');
