import { Page } from '@/components/layout/Page';
import styled from 'styled-components';
import { PageTitle } from '@/components/pages/profile/core/PageTitle';
import { useUserManual } from '@/components/pages/UserManualPage/useUserManual';
import { PageLoader } from '@instech/components';

const ContentArea = styled.div`
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 25px 0px;
    height: 88vh;
`;

const StyledObject = styled.object`
    width: 100%;
    height: 100%;
    padding-top: 20px;
`;

export const UserManualPage = () => {
  const data = useUserManual();

  if (!data) {
    return <PageLoader />;
  }
  return (
    <Page title="User Manual" noScroll>
      <ContentArea>
        <PageTitle title="User Manual" />
        <StyledObject
              data={data?.url}
              type="application/pdf" />
      </ContentArea>
    </Page>

  );
};
