import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { useFeatureFlag } from '@/providers/featureFlagProvider';
import { hiddenOnPrint, showBackgroundOnPrint } from '@/utils/styles';
import { NhcLogo, NhcLogoSymbol } from '@instech/icons';
import { Notifications, UserMenu } from 'insify-remote-component-loader';
import {
  ComponentProps, FunctionComponent, useState
} from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { theme } from '@/utils/theme';
import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { Link } from 'react-router-dom';
import { UserInfo } from './UserInfo';
import { PortalSelectorLoader } from './PortalSelectorLoader';

const Row = styled.div<{scrolled?: boolean}>`
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.marineBlue};
  ${showBackgroundOnPrint}
  color: #fff;
  position: ${props => props.scrolled && 'sticky'};
  top: 0;
  z-index: 40;
  height: ${props => props.scrolled ? props.theme.header.scrolledHeight : props.theme.header.height};
`;

const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  ${hiddenOnPrint}
`;

interface Props {
  title?: string;
  hideUserInfo?: boolean;
  scrolled?: boolean;
  showHelpAndNotifications?: boolean;
}

interface UserMenuFeatureProps {
  scrolled?: boolean;
}

const UserMenuFeatureGate = ({ scrolled }: UserMenuFeatureProps) => {
  const role = useCurrentRole();
  const enableRemoteUserMenu = useFeatureFlag('remoteUserMenu');
  const history = useHistory();
  const account = useAccount();
  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const updatesConfig = {
    announcements: true,
    notifications: true
  };
  const portal = {
    name: 'survey',
    displayName: 'Survey'
  };
  const profilePageUrl = '/profile';

  if (enableRemoteUserMenu) {
    const links: ComponentProps<typeof UserMenu>['links'] = [
      {
        title: 'Notifications',
        onClick: () => setNotificationsIsOpen(true)
      },
      {
        href: '/feedback-and-support',
        title: 'Feedback & Support'
      },
      {
        href: '/announcements',
        title: 'Announcements'
      },
      {
        href: '/user-manual',
        title: 'User Manual'
      },
    ];
    return (
      <>
        <UserMenu
          account={account}
          history={history}
          role={role}
          portal={portal}
          links={links}
          profilePageUrl={profilePageUrl}
          config={updatesConfig}
        />
        <Notifications
          account={account}
          history={history}
          isOpen={notificationsIsOpen}
          setIsOpen={setNotificationsIsOpen}
          top={scrolled ? theme.header.scrolledHeight : theme.header.height}
        />
      </>
    );
  }

  return <UserInfo />;
};

const StyledImage = styled.div<{scrolled: boolean}>`
  & svg {
    height: ${props => props.scrolled ? '32px' : '64px'};
    width: 130px;
  }
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
`;

const MenuSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const Logo = ({ scrolled = false }) => (
  <StyledImage scrolled={scrolled}>
    {scrolled ? <NhcLogoSymbol alt="NHC logo" /> : <NhcLogo alt="NHC logo" />}
  </StyledImage>
);

const Name = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

export const Header: FunctionComponent<Props> = (
  {
    title,
    hideUserInfo,
    showHelpAndNotifications = true,
    scrolled = false
  }) => {
  const portalSelectorIsEnabled = useFeatureFlag('portalSelector');
  return (
    <Row scrolled={scrolled}>
      <Brand>
        <Link to="/">
          <Logo scrolled={scrolled} />
        </Link>
        <Name>Survey</Name>
      </Brand>
      {title && <div>{ title }</div>}
      {showHelpAndNotifications && (
        <MenuSection>
          {!hideUserInfo && (
          <StyledUser>
            <UserMenuFeatureGate scrolled={scrolled} />
          </StyledUser>
          )}
          {portalSelectorIsEnabled && <PortalSelectorLoader />}
        </MenuSection>
      )}
    </Row>
  );
};
