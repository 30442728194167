import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ToolButton } from '@/components/shared/ToolButton';
import { useManifest } from '@/services/manifestServices';
import { getReportTools } from '@/services/toolsServices';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { useModalContext } from '../../../modal/ModalContext';

const ToolArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  background: ${props => props.theme.flatWhite};
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    grid-template-columns: repeat(6, 1fr);
  };
`;

export const EditTools = () => {
  const { id: caseId } = useParams();
  const { open } = useModalContext();
  const role = useCurrentRole();
  const { data: manifest } = useManifest();
  const tools = getReportTools(manifest?.reportTools, role.name);

  return (
    <ToolArea>
      {tools.map(each => (
        <ToolButton
          key={each.label}
          onClick={each.onclick ? () => open({
            ...each.onclick,
            args: {
              ...each.onclick.args,
              caseId
            }
          }) : null}
          icon={<each.icon />}
          editTools
        >
          {each.label}
        </ToolButton>
      ))}
    </ToolArea>
  );
};
