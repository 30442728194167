import useSWR, { mutate } from 'swr';
import {
  CompanyInfo, CompanyLogoLinkModel,
  Guid
} from '@/types';
import { postFormAsync } from '@/services/client';
import { AxiosError } from 'axios';

interface CompanyLogoImageForm {
  image: File;
}

const logoApiRoute = (companyId: Guid) => `companylogo/${companyId}`;

export const useCompanies = () => useSWR<CompanyInfo[], AxiosError>('companyinfo');

export const useCompanyLogo = (companyId?: Guid) => (
  useSWR<CompanyLogoLinkModel, AxiosError>(companyId ? logoApiRoute(companyId) : null)
);

export const uploadLogoImageAsync = async (companyId: Guid, form: CompanyLogoImageForm) => {
  const companyLogUrl = logoApiRoute(companyId);

  const formData = new FormData();
  formData.append('image', form.image);

  const result = await postFormAsync(companyLogUrl, formData);
  await mutate(logoApiRoute(companyId));

  return result;
};
