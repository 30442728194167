import { ImagePreview } from '@/services/imageServices';
import { Guid } from '@/types';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useState } from 'react';
import styled from 'styled-components';
import { ContentGrid, Sidebar } from '../core/ContentGrid';
import { EditImageForm } from '../core/ImageForm/EditImageForm';
import { ImageGridSelect } from '../core/ImageGrid/ImageGridSelect';
import { NoImagesUploaded } from '../core/NoImagesUploaded';
import { SelectedImages, SelectImageButton } from '../core/SelectedImages';

const SelectImageContainer = styled.div`
  padding: 10px 0px;
`;

const addOrRemove = (entries: Guid[], itemId: Guid) => {
  const alreadySelected = entries.find(e => e === itemId);
  if (alreadySelected) {
    return entries.filter(e => e !== itemId);
  }
  return [...entries, itemId];
};

interface ImageInsertProps {
  imageIds: Guid[];
  selectedImages: Guid[];
  insertImages: (images: Guid[]) => void;
}
export const ImageInsert = ({ imageIds, selectedImages = [], insertImages }: ImageInsertProps) => {
  const [selectedIds, setSelectedIds] = useState<Guid[]>(selectedImages);
  const [editingImage, setEditingImage] = useState<ImagePreview | null>(null);

  const onSelectImage = (image: ImagePreview) => {
    setSelectedIds(prev => addOrRemove(prev, image.id));
  };

  const onRemoveImage = ({ id }: ImagePreview) => {
    setSelectedIds(prev => prev.filter(e => e !== id));
  };

  const onRearrangeImages = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) return;
    setSelectedIds(prev => {
      const oldIndex = prev.findIndex(id => id === active.id);
      const newIndex = prev.findIndex(id => id === over?.id);
      return arrayMove(prev, oldIndex, newIndex);
    });
  };

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  const sidebarTitle = editingImage ? 'Edit image' : 'Selected images';

  return (
    <ContentGrid>
      <ImageGridSelect
        imageIds={imageIds}
        selectedIds={selectedIds}
        onSelectImage={onSelectImage}
        disableSelect={!!editingImage}
      />
      <Sidebar title={sidebarTitle}>
        {!editingImage && (
          <SelectImageContainer>
            <SelectedImages
              imageIds={selectedIds}
              onEdit={setEditingImage}
              onRemove={onRemoveImage}
              onRearrange={onRearrangeImages} />
            <SelectImageButton
              selectedImages={selectedImages}
              disabled={selectedIds === selectedImages}
              onClick={() => insertImages(selectedIds)} />
          </SelectImageContainer>
        )}
        {editingImage && (
          <EditImageForm
            image={editingImage}
            onSave={() => setEditingImage(null)}
            onClose={() => setEditingImage(null)}
          />
        )}
      </Sidebar>
    </ContentGrid>
  );
};
