import { useModalContext } from '@/components/modal/ModalContext';
import { useClaimsOccurrenceOverview } from '@/services/claimsOccurrenceServices';
import { updateOccurrencesWithInterestsAsync } from '@/services/occurrenceServices';
import {
  Case, ClaimOccurrenceOverview, Interest
} from '@/types';
import { toLabelValuePair } from '@/utils/form';
import { Loader } from '@instech/components';
import { Formik } from 'formik';
import {
  FC, useMemo, useState
} from 'react';
import { isRelatedToYear } from '@/utils/claimUtils';
import { EditableOccurrence, UpdateOccurrenceFunc } from '../../types';
import {
  OccurrenceSchema,
  occurrenceValidator,
  toRequest
} from '../OccurrenceValidator';
import {
  NoClaimsMessage,
  OccurrenceCharterDropdown,
  OccurrenceFormLayout,
  OccurrenceNameInput
} from '../core';
import {
  ModalActionButtonGroup,
  ModalFooter
} from '../core/ModalFooterComponents';
import { SelectableInterests } from '../SelectableInterests';
import { conditionalErrorLineMessage, ErrorLine } from '../core/ErrorLine';

const updateErrorMessage = 'Could not update the occurrence. Please try again later.';

const claimsForOccurrence = (
  occurrence: EditableOccurrence,
  selectedClaims: string[] = [],
  claimsData: ClaimOccurrenceOverview[] = []
) => {
  const availableClaims: ClaimOccurrenceOverview[] = claimsData.map(claim => ({
    ...claim,
    disabled: false
  }));

  const parseInsuranceYear = (interest: Interest) => parseInt(interest.claimReference.split('/')[1]);
  const missingSelectedClaims: ClaimOccurrenceOverview[] = occurrence.interests
    .filter(interest => (
      selectedClaims
        .filter(selectedId => availableClaims.every(available => available.id !== selectedId))
        .includes(interest.claimId ?? '')
    ))
    .map(interest => ({
      id: interest.claimId ?? interest.claimReference,
      claimReference: interest.claimReference,
      dateOfLoss: occurrence.dateOfLoss,
      interest: interest.interest,
      claimInterest: interest.claimInterest,
      insuranceYear: parseInsuranceYear(interest),
    }));

  return [...availableClaims, ...missingSelectedClaims].sort((a, b) => (
    a.claimInterest.localeCompare(b.claimInterest)) || a.claimReference.localeCompare(b.claimReference)
  );
};

const claimsToInitialValues = (claims: ClaimOccurrenceOverview[], interests: Interest[]) => (
  claims.map(claim => {
    const interest = interests.find(f => f.claimId === claim.id);
    return ({
      claimId: claim.id,
      deductible: interest?.deductible ?? '',
      claimInterest: interest?.claimInterest,
      selected: !!interest
    });
  })
);

export interface EditOccurrenceModalProps {
  surveyCase: Case,
  occurrence: EditableOccurrence,
  onOccurrencesUpdate: UpdateOccurrenceFunc;
}
export const EditOccurrence: FC<EditOccurrenceModalProps> = ({ surveyCase, occurrence, onOccurrencesUpdate }) => {
  const {
    data: claimsData,
    isValidating
  } = useClaimsOccurrenceOverview(occurrence.claimId || '');
  const { close } = useModalContext();
  const [submitError, setSubmitError] = useState(false);

  const selectedClaims = useMemo(() => (
    occurrence.interests.map(interest => interest.claimId).filter(id => id !== null) ?? []
  ), [occurrence.interests]);

  const mainClaim = claimsData?.find(claim => claim.id === surveyCase.claimId);
  const mainInsuranceYear = mainClaim?.insuranceYear ?? 0;
  const mainDateOfLossYear = mainClaim?.dateOfLoss ? new Date(mainClaim.dateOfLoss).getFullYear() : 0;

  const relevantClaims = claimsData?.filter(claim => isRelatedToYear(claim, mainInsuranceYear) || isRelatedToYear(claim, mainDateOfLossYear));

  const claims = useMemo(() => (
    claimsForOccurrence(occurrence, selectedClaims, relevantClaims)
  ), [occurrence, relevantClaims, selectedClaims]);

  const initialValues: OccurrenceSchema = {
    occurrenceName: occurrence.occurrenceName,
    charter: occurrence.charter ? toLabelValuePair([occurrence.charter])[0] : null,
    claims: claimsToInitialValues(claims, occurrence.interests)
  };

  const handleSubmit = async (formData: OccurrenceSchema) => {
    setSubmitError(false);
    if (claimsData === undefined) {
      return;
    }

    const request = toRequest(formData);
    try {
      const updatedOccurrence = await updateOccurrencesWithInterestsAsync(surveyCase.id, occurrence.id, request);

      await onOccurrencesUpdate(occurrences => {
        const updatedOccurrences = [...occurrences];
        const occurrenceIndex = occurrences.findIndex(o => o.id === updatedOccurrence.id);
        updatedOccurrences[occurrenceIndex] = updatedOccurrence;
        return updatedOccurrences;
      });

      close();
    } catch {
      setSubmitError(true);
    }
  };

  if (!claimsData && isValidating) {
    return <Loader />;
  }

  if (claims.length === 0) {
    return <NoClaimsMessage closeModal={close} />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={occurrenceValidator}>
      {({ errors, touched }) => (
        <div>
          <OccurrenceFormLayout>
            <OccurrenceNameInput />
            <OccurrenceCharterDropdown />
            <SelectableInterests
              claims={claims}
              label="Select at least one (or more) interest type(s) for this occurrence." />
          </OccurrenceFormLayout>
          <ModalFooter>
            <ErrorLine
              hasError={submitError || !!errors.claims}
              message={conditionalErrorLineMessage([
                { condition: submitError, message: updateErrorMessage },
                { condition: !!(errors.claims && touched.claims), message: errors.claims }
              ])}
            />
            <ModalActionButtonGroup submitButtonText="UPDATE" />
          </ModalFooter>
        </div>
      )}
    </Formik>
  );
};

export const showEditOccurrenceModal = (props: EditOccurrenceModalProps) => ({
  component: EditOccurrence,
  options: {
    title: 'Update Occurrence and Interest(s)',
    size: 'medium',
    footer: false,
  },
  args: props
});
