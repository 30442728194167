import { ImagePreview } from '@/services/imageServices';
import { Guid } from '@/types';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { SelectedImage } from './SelectedImage';

const NoSelectionDescription = styled.div`
  padding: 10px 20px;
  font-style: italic;
`;

interface Props {
  imageIds: Guid[];
  onEdit: (image: ImagePreview) => void;
  onRemove: (image: ImagePreview) => void;
  onRearrange: (event: DragEndEvent) => void;
}
export const SelectedImages = ({ imageIds, onEdit, onRemove, onRearrange }: Props) => {
  if (imageIds.length === 0) {
    return <NoSelectionDescription>No images have been selected.</NoSelectionDescription>;
  }

  return (
    <DndContext onDragEnd={onRearrange}>
      <SortableContext strategy={verticalListSortingStrategy} items={imageIds}>
        {imageIds.map(id => (
          <SelectedImage key={id} id={id} onEdit={onEdit} onRemove={onRemove} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
