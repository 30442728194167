import { NotifyIcon } from '@/components/shared/Tooltip';
import {
  Case, CaseMetadata, Occurrence
} from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { isNullishOrWhitespace } from '@/utils/text';
import { FunctionComponent, useState } from 'react';
import { RowBackground, RowContent } from './Components';
import { InterestRowsList } from './InterestRows';
import { OccurrenceField } from './OccurrenceField';
import { EditOccurrenceButton } from './UpdateOccurrenceModals';
import {
  EditableOccurrence, UpdateInterestFunc, UpdateOccurrenceFunc
} from './types';

const toReadableDate = (date?: string) => {
  if (!date) return '-';
  return getFriendlyDate(date);
};

interface Props {
  occurrence: Occurrence;
  surveyCase: Case;
  index: number;
  onOccurrencesUpdate: UpdateOccurrenceFunc;
  updateInterest: UpdateInterestFunc;
  userCanEdit: boolean;
}
export const OccurrenceRow: FunctionComponent<Props> = ({ occurrence, index, onOccurrencesUpdate, updateInterest, userCanEdit, surveyCase }) => {
  // Simple (ticker + useEffect) approach to reset RefreshClaim button state after editing the occurrence
  const [hasEditedOccurrence, setHasEditedOccurrence] = useState(0);

  const updateOccurrenceCallback: UpdateOccurrenceFunc = update => {
    setHasEditedOccurrence(prev => prev + 1);
    return onOccurrencesUpdate(update);
  };

  const alert = occurrence.interests.some(x => isNullishOrWhitespace(x.claimReference));

  const occurrenceModel: EditableOccurrence = {
    ...occurrence,
    occurrenceName: occurrence.name,
    id: occurrence.id ?? '',
    claimId: occurrence.interests[0].claimId ?? surveyCase.claimId!,
    charter: occurrence.charter
  };

  return (
    <>
      <RowBackground alert={alert}>
        {alert && <NotifyIcon id="occurrence-list-notify" message="Missing Claim Number" />}
        <RowContent>
          <OccurrenceField label={`Occurrence ${index + 1}`} value={occurrence.name ?? ''} />
          <OccurrenceField label="Date of Loss" value={toReadableDate(occurrence.dateOfLoss) ?? ''} />
          <OccurrenceField label="Location" value={occurrence.location ?? ''} />
          <OccurrenceField label="Charter" value={occurrence.charter ?? ''} />
          <OccurrenceField label="Conditions" value={occurrence.conditions ?? ''} />
          <OccurrenceField label="Claim event" value={occurrence.claimEventName ? occurrence.claimEventName : ''} />
          {userCanEdit && (
            <EditOccurrenceButton
              surveyCase={surveyCase}
              onOccurrencesUpdate={updateOccurrenceCallback}
              occurrence={occurrenceModel}
            />
          )}
        </RowContent>
      </RowBackground>
      <InterestRowsList
        updateInterest={updateInterest}
        interests={occurrence.interests ?? []}
        occurrenceId={occurrence.id}
        userCanEdit={userCanEdit}
        hasEdited={hasEditedOccurrence}
      />
    </>
  );
};
