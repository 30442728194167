/* eslint-disable react/no-array-index-key */
/* We don't have any unique identifiers to use as keys for ItemDescriptions, therefore we must use the array index */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import deepEqual from 'deep-equal';
import { stringRequiredMaxLength } from '@/utils/validationSchemas';
import { initializeRichText, RichTextState } from '@/components/shared/Form/RichTextForm/utility';
import { Table, TableHeader } from '@/components/shared/Table';
import { AddSaveButtons } from '@/components/shared/FormButton/FormButtons';
import { Divider, FormikWithPrompt as Formik } from '@instech/components';
import { ItemDescription as ItemDescriptionType } from '@/types';
import { ItemDescription } from './ItemDescription';

interface EntryModel {
  description: string;
  details: RichTextState;
}
const entryModel = () => ({
  description: '',
  details: initializeRichText({ text: '', images: [] })
});

const entryModelSchema = Yup.object().shape({
  description: stringRequiredMaxLength(120)
});

const entrySchema = Yup.object().shape({
  entries: Yup.array().of(entryModelSchema)
    .test('Is array', '\'entries\' is required', value => Array.isArray(value))
});

const deepEqualsDefaultModel = (item: EntryModel) => {
  const { id, ...defaultModel } = initializeRichText({ text: '', images: [] });
  const { id: _id1, ...detailsWithoutId } = item.details;
  return item.description === '' && deepEqual(defaultModel, detailsWithoutId);
};

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Title = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 20px;
`;

interface Props {
  title?: string;
  data: ItemDescriptionType[];
  addLabel: string;
  submit: (newValues: any) => void;
  onDelete: (itemArrayIndex: number) => void;
  formId: string;
}
export const ItemDescriptionTable: FunctionComponent<Props> = ({ title, data, addLabel, submit, onDelete, formId }) => {
  function initializeElements(values: ItemDescriptionType[]) {
    return values.length
      ? values.map(item => (
        { ...item, details: initializeRichText(item.details) }
      ))
      : [entryModel()];
  }

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Formik
        formId={formId}
        initialValues={{ entries: initializeElements(data) }}
        validationSchema={entrySchema}
        onSubmit={async (values, { resetForm }) => {
          const newEntries = values.entries.map(entry => (
            { ...entry }
          ));
          submit(newEntries);
          const newFormState = { values: { entries: initializeElements(newEntries) } };
          resetForm(newFormState);
        }}
      >
        {({ values, setFieldValue, dirty, handleSubmit, isSubmitting }) => (
          <FieldArray
            name="entries"
            render={({ form, push, remove, swap }) => (
              <Form>
                <Table layout="50px 1fr 200px 60px">
                  <TableHeader />
                  <TableHeader id="damageheader-description">Description</TableHeader>
                  <TableHeader id="damageheader-details">Details</TableHeader>
                  <TableHeader />
                  {values.entries.map((item, i) => (
                    <ItemDescription
                      key={i}
                      form={form}
                      arrayId={`entries.${i}`}
                      number={i}
                      length={values.entries.length}
                      setFieldValue={setFieldValue}
                      onDelete={() => {
                        remove(i);
                        onDelete(i);
                        if (values.entries.length === 1) {
                          push(entryModel());
                        }
                      }}
                      disableDelete={values.entries.length === 1 && deepEqualsDefaultModel(item)}
                      onMoveDown={() => swap(i, i + 1)}
                      onMoveUp={() => swap(i, i - 1)}
                    />
                  ))}
                </Table>
                <Divider />
                <AddSaveButtons
                  addLabel={addLabel}
                  onAdd={() => push({ ...entryModel() })}
                  onSave={dirty ? handleSubmit : undefined}
                  isSubmitting={isSubmitting}
                  disableSave={!dirty}
                  spaceBetween
                />
              </Form>
            )}
          />
        )}
      </Formik>
    </Wrapper>
  );
};
