import { ClaimInterest, claimInterests } from '@/types';

export type LOHDeductible = {
  days: number;
  claimDays: number;
  policyDays: number;
}

export type LOHKeys = keyof LOHDeductible;

export const interestIsLOH = (interest: ClaimInterest) => (interest === claimInterests.lossOfHire);

export const splitLOHDeductible = (deductible: string): LOHDeductible => {
  const [days, claimDays, policyDays] = deductible.split('/');
  return {
    days: Number(days),
    claimDays: Number(claimDays),
    policyDays: Number(policyDays)
  };
};

export const deductibleToString = (deductible: LOHDeductible): string => (
  `${deductible.days}/${deductible.claimDays}/${deductible.policyDays}`
);
