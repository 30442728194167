import styled from 'styled-components';

export const EditedBy = styled.span`
  display: block;
  height: 15px;
  margin-top: 4px;
  font-size: 12px;
  text-align: right;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const ParticularFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;
