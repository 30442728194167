import { useUserEditAccess } from '@/services/userInfoService';
import { PageLoader } from '@instech/components';
import React, { FunctionComponent } from 'react';
import { NoAccessPage } from '../AppRouting/NoAccess';

export const RequireEditAccess: FunctionComponent = ({ children }) => {
  const { data: userHasEditAccess, error } = useUserEditAccess();

  if (error) {
    throw error;
  }

  if (userHasEditAccess === undefined) {
    return <PageLoader />;
  }

  if (!userHasEditAccess) {
    return <NoAccessPage />;
  }

  return (children as React.ReactElement);
};
