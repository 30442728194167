import React from 'react';
import { ClaimOccurrenceOverview } from '@/types';
import { useField } from 'formik';
import { toReadableDate } from '@/components/pages/Dashboard/caseInformation/utils';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.marineBlue05};
  padding: 0px 32px;
  border-radius: 4px;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.marineBlue};
`;

const Bold = styled.p`
  font-weight: 700;
`;

export const ReplacementClaim: React.FC<{claim: ClaimOccurrenceOverview, name: string}> = ({ claim, name }) => {
  const [field, , helpers] = useField(name);
  const isSelected = field.value === claim.id;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label style={{ cursor: 'pointer' }}>
      <Container>
        <div>
          <p>Claim number</p>
          <Bold>{claim.claimReference}</Bold>
        </div>
        <div>
          <p>Date of Loss</p>
          <Bold>{toReadableDate(claim.dateOfLoss)}</Bold>
        </div>
        <div>
          <p>Interest</p>
          <Bold>{claim.interest}</Bold>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <span>Select</span>
            <input
              type="radio"
              name={field.name}
              value={claim.id}
              checked={isSelected}
              onChange={() => helpers.setValue(claim.id)}
            />
          </div>
        </div>
      </Container>
    </label>
  );
};
