import { Guid } from '@/types';
import { BoxButton, ButtonGroup } from '@instech/components';
import { FC, memo } from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled(ButtonGroup)`
  padding: 10px 20px 20px;
`;

interface Props {
  selectedImages: Guid[];
  disabled: boolean;
  onClick: () => void;
}
export const SelectImageButton: FC<Props> = memo(({ selectedImages, disabled, onClick }) => {
  const title = selectedImages.length ? 'Update' : 'Include';

  const handleClick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <StyledButtonGroup alignRight>
      <BoxButton disabled={disabled} onClick={handleClick}>
        {title}
      </BoxButton>
    </StyledButtonGroup>
  );
});
