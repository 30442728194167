import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import client, {
  getJsonAsync,
  getWithRetry, postFormAsync, putJsonAsync, RetryConfig
} from '@/services/client';
import { Guid, PersonallyIdentifiableInformation } from '@/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useReportIdFromUrl } from '@/hooks/useReportIdFromUrl';
import { trimString } from '@/utils/text';
import { AxiosResponse } from 'axios';
import { ImagePreview as InsifyImagePreview } from '@instech/components';

export type Crop = 'small' | 'medium' | 'large' | 'original';

export type DeleteImageStatus = 'Inuse' | 'Error' | 'Success';

export interface ImageForm {
  image: File;
  title: string;
  description: string;
  pii: boolean;
  piiIncludesEu?: boolean;
}

interface FrontpageImageForm {
  image: File;
}

export interface ImageModel {
  url?: string;
  id: Guid;
  caseId: Guid;
  title: string;
  description: string;
  uploadedAt: string;
  uploadedBy: string;
  fileName: string;
  fileSizeInBytes: number;
  width: number;
  height: number;
}

export interface UpdateImageRequest {
  title: string;
  description: string;
  personallyIdentifiableInformation?: PersonallyIdentifiableInformation;
}

export type CropStatus = 'Pending' | 'Error' | 'Success';

export interface ImagePreview extends InsifyImagePreview {
  caseId: Guid;
  crop: Crop;
}

export type ImageTag = 'Logo' | 'Frontpage' | 'Signature';

const apiRoute = (caseId: Guid) => `case/${caseId}/images`;
const imageRoute = (caseId: Guid, imageId: Guid) => `case/${caseId}/images/${imageId}`;

// Return true if the image is in use, false if it isn't
const isImageInUseAsync = async (caseId: Guid, imageId: Guid) => {
  const route = imageRoute(caseId, imageId);
  const check = await getJsonAsync(`${route}/inuse`);
  return check.inUse;
};

export const deleteImageAsync = async (caseId: Guid, imageId: Guid) => {
  const route = imageRoute(caseId, imageId);
  const result = await client.delete(route);
  return result;
};

export const useUploadImage = (caseId: Guid) => {
  const { mutate } = useSWRConfig();

  const uploadImageAsync = async (form: ImageForm) => {
    const imageListUrl = apiRoute(caseId);

    const formData = new FormData();
    formData.append('image', form.image);
    formData.set('title', trimString(form.title));
    formData.set('description', form.description);
    if (form.pii) {
      formData.set('personallyIdentifiableInformation.includesEu', form.piiIncludesEu ? 'true' : 'false');
    }

    return mutate(
      imageListUrl,
      async (images: Guid[] = []) => {
        const result: ImageModel = await postFormAsync(imageListUrl, formData);
        return [result.id, ...images];
      }
    );
  };

  return { uploadImageAsync };
};

export const useUploadFrontpageImage = (caseId: Guid) => {
  const { mutate } = useSWRConfig();

  const uploadFrontpageImageAsync = async (form: FrontpageImageForm) => {
    const imageListUrl = apiRoute(caseId);

    const formData = new FormData();
    formData.append('image', form.image);
    formData.set('title', 'Frontpage image');
    formData.set('imageTag', 'Frontpage');

    const frontpageImageListUrl = `${imageListUrl}?imageTag=Frontpage`;

    return mutate(
      frontpageImageListUrl,
      async (images: Guid[] = []) => {
        const result: ImageModel = await postFormAsync(imageListUrl, formData);
        return [...images, result.id];
      }
    );
  };

  return { uploadFrontpageImageAsync };
};

function getImageTagQueryParams(tags?: ImageTag[]) {
  if (!tags) return '';

  const tagList = tags.map(tag => (`imageTag=${tag}`)).join('&');

  return `?${tagList}`;
}

export const useImageList = (caseId: Guid, tags?: ImageTag[]) => {
  const tagQueryParams = getImageTagQueryParams(tags);

  const swrImageListUrl = `${apiRoute(caseId)}${tagQueryParams}`;

  const { data, mutate, error } = useSWR<Guid[]>(swrImageListUrl);

  const deleteImage = async (imageId: Guid): Promise<DeleteImageStatus> => {
    const imageInUse = await isImageInUseAsync(caseId, imageId);
    if (imageInUse) {
      return 'Inuse';
    }
    const result = await deleteImageAsync(caseId, imageId);
    if (result) {
      await mutate(prev => prev?.filter(i => i !== imageId));
      return 'Success';
    }
    return 'Error';
  };

  return {
    images: data,
    deleteImage,
    error
  };
};

export const useArchivedImageList = (caseId: Guid, archivedVersionId: Guid, tags?: ImageTag[]) => {
  const tagQueryParams = getImageTagQueryParams(tags);

  const swrImageListUrl = `${apiRoute(caseId)}/archived/${archivedVersionId}${tagQueryParams}`;

  const { data, error } = useSWR<Guid[]>(swrImageListUrl);

  return {
    images: data,
    error
  };
};

export const useUpdateImageMetadata = (caseId: Guid) => {
  const { mutate } = useSWRConfig();

  const updateCropMetadataAsync = (imageKey: Guid) => mutate(key => key && key.includes(`${imageKey}/link?crop`));

  const updateImageMetadataAsync = async (imageId: Guid, data: UpdateImageRequest) => {
    const swrKey = imageRoute(caseId, imageId);
    const result = await putJsonAsync(swrKey, data);
    await updateCropMetadataAsync(swrKey);
    return result;
  };

  return { updateImageMetadataAsync };
};

const imagePreviewUrl = (caseId: string, id: string, crop: Crop) =>
  `${imageRoute(caseId, id)}/link?crop=${crop}`;

const imageReportUrl = (caseId: string, id: string, reportId: string, crop: Crop) => `${imageRoute(caseId, id)}/archived/${reportId}/link?crop=${crop}`;

const swrCropConfiguration: SWRConfiguration = {
  suspense: false
};

export const defaultFetchConfig = {
  retryCount: 10,
  errorRetryInterval: 800,
  retryCondition: (response: AxiosResponse<ImagePreview>) => response.data.cropStatus === 'Pending',
};

const getImagePreview = (config?: RetryConfig<ImagePreview>) => async (url: string) => {
  const configOrDefault = config ?? {};
  const response = await getWithRetry<ImagePreview>(url, {
    ...defaultFetchConfig,
    ...configOrDefault,
  });

  return response.data;
};

// Fetch ImagePreview crop data
export const useImagePreviewById = (id: string, crop: Crop, fetchConfig?: RetryConfig<ImagePreview>) => {
  const caseId = useCaseIdFromUrl();
  const reportId = useReportIdFromUrl();
  const url = !reportId ? imagePreviewUrl(caseId, id, crop) : imageReportUrl(caseId, id, reportId, crop);
  return useSWR<ImagePreview>(
    url,
    getImagePreview(fetchConfig),
    swrCropConfiguration
  );
};

// Fetch ImageModel metadata
export const useImageMetaById = (id: string) => {
  const caseId = useCaseIdFromUrl();
  const swrImageMeta = imageRoute(caseId, id);
  const { data } = useSWR<ImageModel>(swrImageMeta);
  return { data };
};
