/* eslint-disable no-console */
import {
  FileCategory, useArchivedCategories, useCategories, useFileList
} from '@/services/fileServices';
import { ReportPane } from '@/components/shared/Pane';
import { openAvailableInformationTool } from '@/components/tools/AvailableInformation';
import { transformFiles } from '@/components/tools/AvailableInformation/utils/availableInfoUtils';
import { getToolWidget, useManifest } from '@/services/manifestServices';
import { Manifest } from '@/utils/manifestTypes';
import { InfoTable } from './Components';

// Fall back to these categories for backwards compatibility where category list are not archived
const fallbackCategories = [
  'General information',
  'Log books',
  'Reports',
  'Other'
];

export const AvailableInformationReportView = ({ title }: { title: string }) => {
  const { data: { categories: archivedCategories } } = useArchivedCategories() as { data: { categories: FileCategory[] } };
  const { files } = useFileList();

  const categories = archivedCategories ?? fallbackCategories;

  const categoryData = transformFiles(files, categories);

  return (
    <ReportPane title={title}>
      {categoryData.map(({ category, files: info }) => (
        <InfoTable key={category} info={info} category={category} />
      ))}
    </ReportPane>
  );
};

interface ViewProps {
  editable?: boolean;
}
export const AvailableInformationReportPreview = ({ editable }: ViewProps) => {
  const { files } = useFileList();
  const { data: manifest } = useManifest() as { data: Manifest };
  const { data: categories } = useCategories() as { data: FileCategory[] };

  const categoryData = transformFiles(files, categories);

  const widget = getToolWidget(manifest, 'AvailableInformation');

  const title = widget?.title ?? 'Available Information';

  return (
    <ReportPane title={title} open={openAvailableInformationTool} editable={editable}>
      {categoryData.map(({ category, files: info }) => (
        <InfoTable key={category} info={info} category={category} />
      ))}
    </ReportPane>
  );
};
