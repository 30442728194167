export const textLength = {
  short: 60,
  medium: 120,
  long: 240
};

export const textAreaLength = {
  medium: 5_000,
  long: 60_000
};
