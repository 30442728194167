import { claimInterests } from '@/types';
import { currencyRequiredString } from '@/utils/validationSchemas';
import * as Yup from 'yup';

const yupInterestSelected = Yup.array(Yup.object({
  claimId: Yup.string().required(),
  claimInterest: Yup.mixed().oneOf([
    claimInterests.hullAndMachinery,
    claimInterests.lossOfHire
  ]),
  deductible: Yup.mixed().when('selected', {
    is: true,
    then: a => a.when('claimInterest', {
      is: claimInterests.hullAndMachinery,
      then: currencyRequiredString('Deductible cannot be empty', 0, 2),
      otherwise: b => b.required('Deductible cannot be empty'),
    })
  }),
  selected: Yup.bool()
})).test(
  'is-selected',
  'At least one interest type must be selected.',
  interests => !!interests && interests.filter(x => !!x.selected).length > 0
);

export const occurrenceValidator = Yup.object({
  occurrenceName: Yup.string()
    .required('Please enter an occurrence name.')
    .max(110, 'Cannot be longer than 110 characters.'),
  claims: yupInterestSelected.required(),
  charter: Yup.object({
    label: Yup.string(),
    value: Yup.string()
  }).nullable().optional(),
});

export const occurrenceRequestValidator = Yup.object({
  name: Yup.string().required().max(110),
  claims: Yup.array(Yup.object({
    claimId: Yup.string().required(),
    deductible: Yup.string().nullable(),
  })).min(1).required(),
  charter: Yup.string().nullable()
});

export type OccurrenceSchema = Yup.InferType<typeof occurrenceValidator>;
export type OccurrenceRequest = Yup.InferType<typeof occurrenceRequestValidator>;

export const toRequest = (occurrence: OccurrenceSchema): OccurrenceRequest => ({
  name: occurrence.occurrenceName,
  charter: occurrence.charter?.value ?? '',
  claims: occurrence.claims
    .filter(claim => claim.selected)
    .map(claim => ({
      claimId: claim.claimId,
      deductible: claim.deductible === '' ? null : claim.deductible
    }))
});
