import { SlimButton } from '@instech/components';
import {
  Approved,
  Refresh
} from '@instech/icons';
import styled, { css, keyframes } from 'styled-components';

const iconStyling = css<{ disabled: boolean; }>`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  color: ${props => props.disabled ? props.theme.mediumGray : props.theme.blue};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const refreshAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
`;

const StyledRefresh = styled(Refresh) <{ $isLoading: boolean; }>`
  ${iconStyling}
  ${props => props.$isLoading && css`
    animation: ${refreshAnimation} infinite 5s cubic-bezier(0.1, 0, 0.5, 1);
  `};
`;

const StyledApproved = styled(Approved)`
  ${iconStyling};
  color: ${props => props.theme.green};
`;

interface RefreshClaimDataProps {
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
  hasRefreshed: boolean;
}
export const RefreshClaimButton = ({ onClick, disabled, isLoading, hasRefreshed }: RefreshClaimDataProps) => (
  <SlimButton
    variant="secondary"
    startIcon={hasRefreshed ? <StyledApproved /> : <StyledRefresh $isLoading={isLoading} disabled={disabled} />}
    onClick={onClick}
    disabled={disabled}>
    UPDATE WITH VALUES FROM INS+
  </SlimButton>
);
