import { useModalContext } from '@/components/modal/ModalContext';
import { ButtonGroup, SlimButton } from '@instech/components';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;

interface ModalActionButtonGroupProps {
  submitButtonText: string;
}
export const ModalActionButtonGroup: FC<ModalActionButtonGroupProps> = ({ submitButtonText }) => {
  const { close } = useModalContext();
  const { submitForm, isSubmitting } = useFormikContext();
  return (
    <ButtonGroup alignRight>
      <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
      <SlimButton type="submit" disabled={isSubmitting} loading={isSubmitting} onClick={submitForm}>{submitButtonText}</SlimButton>
    </ButtonGroup>
  );
};
