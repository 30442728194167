import React from 'react';
import { Form, Formik, } from 'formik';
import styled from 'styled-components';
import {
  ButtonGroup, Dropdown, SlimButton, WarningNotification
} from '@instech/components';
import {
  Case, ClaimOccurrenceOverview, Occurrence
} from '@/types';
import * as Yup from 'yup';
import { OriginalClaim } from './OriginalClaim';
import { ReplacementClaim } from './ReplacementClaim';
import { useChangeInsuranceYearOptions }
  from './options';
import { ClearReplacementsWhenPolicyYearChanges } from './ClearReplacementsWhenPolicyYearChanges';
import {
  ChangeInsuranceYearFormState,
  hasMultipleInsuranceYearsSelected,
  initialState
} from './formstate';

const Claims = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
`;

const schema = Yup.object().shape({
  claims: Yup.array().of(
    Yup.object().shape({
      replacement: Yup.string().required()
    })
  )
});

interface Props {
  surveyCase: Case;
  occurrences: Occurrence[];
  allClaims: ClaimOccurrenceOverview[];
  onSubmit: (values: ChangeInsuranceYearFormState) => Promise<void>;
  onCancel: () => void;
}

function distinctBy<T>(elements: T[], key: (element: T) => unknown) {
  return Array.from(new Map(elements.map(element => [key(element), element])).values());
}

function distinct<T>(array: T[]) {
  return distinctBy(array, x => x);
}

export const ChangeInsuranceYearForm: React.FC<Props> = ({
  surveyCase,
  allClaims,
  occurrences,
  onSubmit,
  onCancel
}) => {
  const claimIdsInUse = occurrences
    .flatMap(occurrence => occurrence.interests)
    .map(interest => interest.claimId);

  const claimsInUse = distinctBy(
    allClaims.filter(claim => claimIdsInUse.includes(claim.id)),
    claim => claim.id
  );

  const { options, years } = useChangeInsuranceYearOptions(surveyCase, allClaims, claimsInUse);

  return (
    <Formik
      initialValues={initialState(claimsInUse)}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, isValid }) => {
        const year = values.insuranceYear?.value ? parseInt(values.insuranceYear.value) : null;

        const selectableYears = years.map(x => ({
          label: x.toString(),
          value: x.toString()
        }));

        return (
          <Form>
            <Dropdown
              name="insuranceYear"
              label="INS+ Insurance Year"
              placeholder="Select"
              options={selectableYears}
            />
            { hasMultipleInsuranceYearsSelected(values, allClaims)&& (
            <WarningNotification
              size="small"
              headingText="You are about to update the case to include occurrences from multiple insurance years"
            />
            )}
            <Claims>
              {values.insuranceYear?.value && values.claims.map((claim, i) => {
                const valueName = `claims.${i}.replacement`;

                const optionsForYear = options.find(x => x.insuranceYear === year);
                const selectableClaims = optionsForYear?.claimOptions.find(x => x.original.id === claim.original.id)?.optionsForClaim ?? [];

                return (
                  <div key={claim.original.id}>
                    <p>Select interest to replace this interest:</p>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px'
                    }}>
                      <OriginalClaim claim={claim.original} />
                      {selectableClaims.map(replacement => (
                        <ReplacementClaim
                            key={replacement.id}
                            claim={replacement}
                            name={valueName}
                          />
                      ))}
                      {selectableClaims.length === 0 && (
                      <p>There are no valid replacements</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </Claims>
            <ClearReplacementsWhenPolicyYearChanges />
            <ButtonGroup alignRight>
              <SlimButton variant="secondary" onClick={onCancel}>CANCEL</SlimButton>
              <SlimButton type="submit" disabled={isSubmitting || !isValid} loading={isSubmitting}>SAVE</SlimButton>
            </ButtonGroup>
          </Form>
        );
      }}
    </Formik>
  );
};
