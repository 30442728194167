import { WarningFilled } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const ErrorText = styled.p`
  color: ${({ theme }) => theme.warning};
  display: flex;
  gap: 4px;
  margin: 0;
  > svg {
    flex-shrink: 0;
  }
`;

/**
 * Checks a list of conditionals, and returns the corresponding message to the first true found.
 * Message is deliberately 'any' and filtering for strings is left to <ErrorLine />.
 *
 * Probably a bit unintuitive, but one of its uses is catching an error thrown by Yup on a
 * Yup.array(Yup.object()) situation. if the error is on the array itself, Yup returns
 * a string. If it is on a value inside the array, it returns the entire error tree.
 */
export const conditionalErrorLineMessage = (errors: { condition: boolean, message: any }[]) => {
  const error = errors.find(err => !!err.condition);
  return error?.message;
};

interface ErrorLineProps {
  hasError?: boolean;
  message?: string;
}
/**
 * Conditionally renders a provided error message, if 'hasError' is true.
 * The containing element is always rendered, so that it can be a stable presence for
 * layout calculations without needing to do any workarounds.
 */
export const ErrorLine: FC<ErrorLineProps> = ({ hasError, message }) => {
  if (!hasError || typeof message !== 'string') {
    return <ErrorText />;
  }
  return (
    <ErrorText>
      <WarningFilled />
      {message}
    </ErrorText>
  );
};
