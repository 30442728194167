import { FunctionComponent, Suspense } from 'react';
import styled from 'styled-components';
import { Loader, Pane } from '@instech/components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useNewestReport } from '@/services/versionsService';
import { NavPreviewIcon } from '@/components/shared/LinkButton/LinkButtons';
import { reportViewRoute } from '@/utils/routeTemplates';

const IconWrapper = styled.div`
  // Remove additional spacing in the button component
  & button, span {
    padding: 0px;
    margin: 0px;
  }
`;

interface Props {
  title: string | JSX.Element;
  icon: JSX.Element;
  chapterLink?: string;
  padding?: string;
}
export const DashboardPane: FunctionComponent<Props> = ({ title, icon, padding, chapterLink, children }) => {
  const caseId = useCaseIdFromUrl();
  const { data: archivedReport } = useNewestReport(caseId);

  const reportRoute = (archivedReport && chapterLink) && `${reportViewRoute(caseId, archivedReport.archivedId)}#${chapterLink}`;

  return (
    <Pane
      title={title}
      icon={icon}
      button={
        reportRoute ? <IconWrapper><NavPreviewIcon to={reportRoute} title="View chapter" /></IconWrapper> : undefined
      }
      padding={padding}
    >
      <Suspense fallback={<Loader />}>
        {children}
      </Suspense>
    </Pane>
  );
};
