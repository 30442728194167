/* eslint-disable react/jsx-props-no-spreading */
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SaveCloseButtons } from '@/components/shared/FormButton/FormButtons';
import { Checkbox } from '@/components/shared/Form/FormFields';
import { Field } from '@/types';
import {
  FormField, FormRow, TextField
} from '@instech/components';

const Container = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.background.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: auto;
`;

interface Props {
  fields: Field[];
  onSave: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
  disableSave?: boolean;
  cancelIsReset?: boolean;
  className?: string;
}
export const NewEntryInput: FunctionComponent<Props> = ({
  fields,
  onSave,
  onCancel,
  isSubmitting,
  disableSave,
  cancelIsReset,
  className
}) => (
  <Container className={className}>
    <FormRow gutter="10px">
      {fields.map(field => (
        <FormField key={field.name}>
          <TextField {...field} />
        </FormField>
      ))}
    </FormRow>
    <ButtonContainer>
      <StyledCheckbox name="includeInReport" rightLabel="Include in report?" noErrors />
      <SaveCloseButtons
        isSubmitting={isSubmitting}
        saveLabel="Save"
        disableSave={disableSave}
        // If cancelIsReset, turn Close button into a Reset
        // that is disabled when there are no cahnges to reset
        closeLabel={cancelIsReset ? 'Reset' : 'Cancel'}
        disableClose={cancelIsReset && disableSave}
        onSave={onSave}
        onClose={onCancel}
        reverse
      />
    </ButtonContainer>
  </Container>
);
