/* eslint-disable jsx-a11y/label-has-associated-control -- False positive; Control is inside JSX component */

import { CheckboxControlled } from '@instech/components';
import { useField } from 'formik';
import { FC, useCallback } from 'react';
import styled from 'styled-components';

const LabelContainer = styled.label`
  justify-content: center;
  cursor: pointer;
`;

const CenteredLabel = styled.div`
  text-align: center;
`;

const CenteredCheckbox = styled(CheckboxControlled)`
  margin-top: 4px;
  justify-content: center;
  & > div {
    justify-content: center;
  }
`;

interface ClaimCheckboxProps {
  name: string;
}
export const ClaimCheckbox: FC<ClaimCheckboxProps> = ({ name }) => {
  const [{ value: checked }, , helpers] = useField<boolean>(name);

  const handleChange = useCallback(() => {
    void helpers.setTouched(true);
    void helpers.setValue(!checked);
  }, [checked, helpers]);

  return (
    <LabelContainer htmlFor={name}>
      <CenteredLabel>Select</CenteredLabel>
      <CenteredCheckbox
        name={name}
        id={name}
        selected={checked}
        onChange={handleChange}
        noTopLabel
        noPadding
        noErrors
      />
    </LabelContainer>
  );
};
