import { textLength } from '@/utils/validation';
import { stringRequiredMaxLength } from '@instech/components';
import * as Yup from 'yup';

export const companyParticularSchema = Yup.object().shape({
  title: stringRequiredMaxLength(textLength.short),
  description: stringRequiredMaxLength(textLength.medium)
});

export type CompanyParticularSchema = {
  title: string;
  description: string;
}

export const newCompanyParticular: CompanyParticularSchema = {
  title: '',
  description: ''
};
