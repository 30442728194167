import {
  NotFound,
  OhNo,
  Stop
} from '@instech/icons';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { errorStatuses } from '../types';

interface ImageProps {
  errorUnauthorized?: boolean;
  errorNotAllowed?: boolean;
}
const ErrorImageContainer = styled.div<ImageProps>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  svg {
    display: block;
    height: auto;
    margin: auto;
    
    @media screen and (max-width: 600px) {
      max-width: 240px;
    }
  }

  /* The Unauthorized error graphic needs to 
  be shifted in order to align it on the page */
  ${props => props.errorUnauthorized && css`
    svg {
      transform: translateX(-68px);
      
      @media screen and (max-width: 600px) {
        transform: translateX(-22px);
      }

      @media screen and (max-width: 320px) {
        transform: translateX(-4px);
      }
    }
  `}

  /* The Not Allowed error graphic needs to be 
  shifted in order to align it on the page */
  ${props => props.errorNotAllowed && css`
    svg {
      transform: translateX(25px);

      @media screen and (max-width: 600px) {
        transform: translateX(11px);
      }
    }
  `}
`;

interface Props {
  error: number;
}
export const ErrorImage: FC<Props> = ({ error }) => {
  switch (error) {
    case errorStatuses.unauthorized:
      return (
        <ErrorImageContainer errorUnauthorized>
          <Stop />
        </ErrorImageContainer>
      );
    case errorStatuses.notFound:
      return (
        <ErrorImageContainer>
          <NotFound />
        </ErrorImageContainer>
      );
    case errorStatuses.notAllowed:
    default:
      return (
        <ErrorImageContainer errorNotAllowed>
          <OhNo />
        </ErrorImageContainer>
      );
  }
};
