import { useState } from 'react';
import styled from 'styled-components';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { NewBoxButton } from '@/components/shared/BoxButton/NewBoxButton';
import { Check } from '@instech/icons';
import { CaseStatusHistoryItem } from '@/types';
import { useCloseCase } from '@/services/caseStatusServices';
import { RadioButtonControlled } from '@/components/shared/Form/fields/RadioButtons/RadioButtonControlled';
import { ButtonGroup } from '@instech/components';
import { ChangeLog } from './ChangeLog';
import { ConfirmationFrame } from './ConfirmationFrame';

const Header = styled.div`
  font-size: 22px;
  font-weight: bold;  
`;

const Row = styled.div<{ odd?: boolean; }>`
  padding: 0px 8px;
  background-color: ${props => props.odd ? props.theme.ultraLightGray2 : props.theme.white};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 20px;
  padding: 0px;
`;

// ButtonGroup needs spacing both and below
const StyledButtonGroup = styled(ButtonGroup)`
  margin: 10px 0px;
`;

// These styles are the same for all buttons rendered in the component,
// so applying them as a style extension instead of inline props
const StyledBoxButton = styled(NewBoxButton)`
  width: 100px;
  height: 40px;
`;

interface EditFrameProps {
  daysText: string;
  closed: boolean;
  canApply: boolean;
  close: () => void;
  onChangeStatus: () => void;
  onApplyChange: () => void;
  changelog: CaseStatusHistoryItem[];
}

const ClaimHandlerEditFrame = ({ daysText, closed, canApply, close, onChangeStatus, onApplyChange, changelog }: EditFrameProps) => (
  <>
    <HeaderRow>
      <Header>Status</Header>
      <div>{daysText}</div>
    </HeaderRow>
    <Row odd>
      <div>Open</div>
      <RadioButtonControlled
        name="isOpen"
        id="open"
        checked={!closed}
        label="Open"
        locked={false}
        onChange={onChangeStatus} />
    </Row>
    <Row>
      <div>Closed</div>
      <RadioButtonControlled
        name="isClosed"
        id="closed"
        checked={closed}
        label="Close"
        locked={false}
        onChange={onChangeStatus} />
    </Row>
    <StyledButtonGroup alignRight>
      <StyledBoxButton inverted onClick={close}>
        Cancel
      </StyledBoxButton>
      <StyledBoxButton startIcon={<Check />} onClick={onApplyChange} disabled={!canApply}>
        Apply
      </StyledBoxButton>
    </StyledButtonGroup>
    <ChangeLog changelog={changelog} />
  </>
);

interface ContentProps {
  daysText: string;
  closed: boolean;
  close: () => void;
  changelog: CaseStatusHistoryItem[];
}

export const ClaimsHandlerFrame = ({ daysText, closed, close, changelog }: ContentProps) => {
  const caseId = useCaseIdFromUrl();
  const [isClosed, setClosed] = useState(closed);
  const [changesApplied, setChangesApplied] = useState(false);
  const { closeCase, reopenCase } = useCloseCase(caseId);

  const onChangeStatus = () => setClosed(previous => !previous);

  const onApplyChange = async () => {
    if (isClosed) {
      await closeCase();
    } else {
      await reopenCase();
    }
    setChangesApplied(true);
  };

  return changesApplied
    ? (
      <ConfirmationFrame
        closed={isClosed}
        close={close}
      />
    )
    : (
      <ClaimHandlerEditFrame
        daysText={daysText}
        close={close}
        closed={isClosed}
        canApply={isClosed !== closed}
        onApplyChange={onApplyChange}
        onChangeStatus={onChangeStatus}
        changelog={changelog} />
    );
};
