import * as Yup from 'yup';
import { stringRequiredMaxLength } from '@/utils/validationSchemas';
import { TextFieldProps } from '@instech/components';

export const validationSchema = Yup.object().shape({
  equipment: stringRequiredMaxLength(),
  make: stringRequiredMaxLength(),
  type: stringRequiredMaxLength(),
  includeInReport: Yup.boolean()
});

// This does not feel like it should be like this, this should have been solved
// either by drilling down components or via composing. But solving that would
// require a bigger refactoring of some very old Tools code.
export const fields: TextFieldProps[] = [
  {
    name: 'equipment',
    label: 'Equipment',
    placeholder: 'Add equipment',
    maxLength: 60
  },
  {
    name: 'make',
    label: 'Make / Manufacturer',
    placeholder: 'Add make / manufacturer',
    maxLength: 60
  },
  {
    name: 'type',
    label: 'Type / Model',
    placeholder: 'Add type / model',
    maxLength: 60
  }
];
