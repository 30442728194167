import styled from 'styled-components';

export const ImageGridOuter = styled.div<{ noSidebar?: boolean }>`
  min-height: 588px;
  padding-right: ${props => props.noSidebar ? '0px' : '32px'};
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;

export const ImageGridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`;
