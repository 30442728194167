import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import {
  EquipmentParticular,
  equipmentParticularApiUrl,
  updateEquipmentParticularsAsync
} from '@/services/equipmentsParticularsServices';
import { FunctionComponent } from 'react';
import { useSWRConfig } from 'swr';
import { VesselInformationFormInline } from '../shared/VesselInformationFormInline';
import { fields, validationSchema } from './core';

interface InformationProps extends EquipmentParticular {
  toggleEditMode: () => void;
}

interface Model {
  equipment: string;
  make: string;
  type: string;
  includeInReport: boolean;
}

export const EquipmentRowEdit: FunctionComponent<InformationProps> = ({
  id,
  equipment,
  make,
  type,
  includeInReport,
  toggleEditMode
}) => {
  const { mutate } = useSWRConfig();
  const caseId = useCaseIdFromUrl();

  const onSubmit = async (model: Model) => {
    const submitValues = { ...model, id };
    await updateEquipmentParticularsAsync(caseId, submitValues);
    await mutate(equipmentParticularApiUrl(caseId));
    toggleEditMode();
  };

  const model: Model = {
    equipment,
    make,
    type,
    includeInReport
  };
  return (
    <VesselInformationFormInline
      model={model}
      fields={fields}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      onCancel={toggleEditMode} />
  );
};
