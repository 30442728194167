import { useModalContext } from '@/components/modal/ModalContext';
import { ModalFunction } from '@/components/modal/types';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useImageList } from '@/services/imageServices';
import { Guid } from '@/types';
import { Loader } from '@instech/components';
import { UploadImageContainer } from './core/UploadImageContainer';
import { ImageInsert } from './views/ImageInsert';

interface SelectImagesModalProps {
  selectedImages: Guid[];
  onSelect?: (ids: Guid[]) => void;
}
const SelectImagesModal = ({ selectedImages, onSelect }: SelectImagesModalProps) => {
  const caseId = useCaseIdFromUrl();
  const { images } = useImageList(caseId);
  const { close } = useModalContext();

  const insertImagesHandler = async (ids: Guid[]) => {
    if (onSelect) onSelect(ids);
    close();
  };

  if (!images) return <Loader />;

  return (
    <>
      <UploadImageContainer />
      <ImageInsert
        imageIds={images}
        selectedImages={selectedImages}
        insertImages={insertImagesHandler}
      />
    </>
  );
};

export const showSelectImagesModal: ModalFunction<SelectImagesModalProps> = ({ selectedImages, onSelect }) => ({
  component: SelectImagesModal,
  options: {
    title: 'Image Library - Select'
  },
  args: {
    selectedImages,
    onSelect
  }
});
