import { FC } from 'react';
import {
  Image, ImagePreview, useLightboxContext
} from '@instech/components';
import styled from 'styled-components';
import { ExpandView } from '@instech/icons';

interface ExpandWrapperProps {
  $width: string;
  $height: string;
}
const ExpandWrapper = styled.div<ExpandWrapperProps>`
  display: grid;
  grid-template-areas: 'content';
  width: ${props => props.$width};
  height: ${props => props.$height};
  cursor: pointer;
  & > div {
    grid-area: content;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.white};
  background-color: ${props => `${props.theme.black}33`}; // 20% opacity black
  transition: background-color 0.2s ease;
  z-index: 1;
  & svg {
    filter: drop-shadow(0px 0px 2px #000);
    transition: transform 0.4s ease;
  }
  &:hover {
    background-color: ${props => `${props.theme.black}80`}; // 50% opacity black
    & svg {
      transform: scale(1.1);
    }
  }
`;

interface Props {
  image: ImagePreview;
  width?: string;
  height?: string;
}
/**
 * Thumbnail-sized Image component that implements the Lightbox handler. This component
 * differs from a regular <Image /> view component in that it renders an overlay to cue
 * the user that it can be clicked on to view the full image.
 */
export const ExpandableThumbnail: FC<Props> = ({ image, width = '120px', height = '120px' }) => {
  const { open: openInLightbox } = useLightboxContext();

  const handleExpand = () => openInLightbox(image);

  return (
    <ExpandWrapper onClick={handleExpand} $width={width} $height={height}>
      <Image url={image.url} alt={image.title} width="100%" height="100%" darkBorder />
      <IconWrapper>
        <ExpandView width="24px" height="24px" />
      </IconWrapper>
    </ExpandWrapper>
  );
};
