import { FunctionComponent, useState } from 'react';
import { LinkedTableRow } from '@/components/shared/FilterableTable';
import { dashboardRoute } from '@/utils/routeTemplates';
import {
  concatString, getClaimNumberFromClaimReference, setStringOrDash
} from '@/utils/text';
import { addFavourite, removeFavourite } from '@/services/caseFavoritesServices';
import { Notification } from '@/services/caseNotificationsServices';
import { useModalContext } from '@/components/modal/ModalContext';
import { ReportVersion } from '@/utils/archivedReportTypes';
import { CaseTasks, Occurrence } from '@/types';
import { getFriendlyDate } from '@/utils/date';
import { getTasksList, Task } from '@/services/caseTasksService';
import { showMaximumFavouritesModal } from '../MaximumFavoritesModal';
import { TaskBadge } from '../badge';
import { ReportStatus, TableCell } from '../../../Components';

interface Props {
  even: boolean;
  fields: {
    id: string;
    dateOfLoss?: string;
    vessel?: string;
    broker?: string;
    surveyCompanyName?: string;
    dateRegistered?: string;
  }
  occurrence?: Occurrence;
  favourite: boolean | undefined;
}

interface PrimaryProps extends Props {
  notifications?: Notification[];
  reportVersion?: ReportVersion;
  tasks?: Task[];
}

const PrimaryTableRow: FunctionComponent<PrimaryProps> = ({ even, fields, occurrence, favourite, notifications, reportVersion, tasks }) => {
  const { open, close } = useModalContext();
  const [starred, setStarred] = useState(favourite);
  const caseId = fields.id;
  const modal = showMaximumFavouritesModal({ close });

  const starHandler = async () => {
    if (starred) {
      await removeFavourite(caseId);
      setStarred(!starred);
      return;
    }

    const added = await addFavourite(caseId);
    if (!added) open(modal);
    setStarred(added);
  };

  return (
    <LinkedTableRow
      even={even}
      to={dashboardRoute(fields.id)}
      starred={starred}
      starHandler={starHandler}
    >
      <TableCell>
        {(tasks && tasks?.length > 0) && <TaskBadge tasks={tasks} caseId={caseId} />}
      </TableCell>
      <TableCell favourite={starred}>
        <ReportStatus caseId={caseId} reportVersion={reportVersion} notifications={notifications} />
      </TableCell>
      <TableCell favourite={favourite}>
        {setStringOrDash(concatString(occurrence?.interests.map(x => getClaimNumberFromClaimReference(x.claimReference))))}
      </TableCell>
      <TableCell favourite={starred}>{getFriendlyDate(occurrence?.dateOfLoss)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(fields.vessel)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(occurrence?.fleetName)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(fields.surveyCompanyName)}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(concatString(occurrence?.interests.map(x => x.interest)))}</TableCell>
      <TableCell favourite={starred}>{setStringOrDash(occurrence?.name)}</TableCell>
    </LinkedTableRow>
  );
};

const SecondaryTableRow: FunctionComponent<Props> = ({ even, fields, occurrence, favourite }) => (
  <LinkedTableRow
    even={even}
    to={dashboardRoute(fields.id)}
  >
    <TableCell />
    <TableCell />
    <TableCell
        favourite={favourite}>
      {setStringOrDash(concatString(occurrence?.interests.map(x => getClaimNumberFromClaimReference(x.claimReference))))}
    </TableCell>
    <TableCell favourite={favourite}>{getFriendlyDate(occurrence?.dateOfLoss)}</TableCell>
    <TableCell />
    <TableCell favourite={favourite}>{setStringOrDash(occurrence?.fleetName)}</TableCell>
    <TableCell />
    <TableCell favourite={favourite}>{setStringOrDash(concatString(occurrence?.interests.map(x => x.interest)))}</TableCell>
    <TableCell favourite={favourite}>{setStringOrDash(occurrence?.name)}</TableCell>
    <TableCell />
  </LinkedTableRow>
);

export interface MappedAssignment {
  id: string;
  vessel: string;
  isClosed: boolean;
  dateRegistered: string;
  surveyCompanyName: string;
  occurrences: Occurrence[];
  favorite: boolean;
  notifications: Notification[],
  tasks: CaseTasks,
  reportVersion: ReportVersion
}

interface TableRowProps {
  item: MappedAssignment;
  index: number;
}

export const TableRow: FunctionComponent<TableRowProps> = ({ item, index }) => {
  const tasks = getTasksList(item.tasks);

  if (item.occurrences.length > 1) {
    return (
      <>
        <PrimaryTableRow
          even={index % 2 === 0}
          fields={item}
          occurrence={item.occurrences[0]}
          favourite={item.favorite}
          notifications={item.notifications}
          reportVersion={item.reportVersion}
          tasks={tasks}
        />
        {item.occurrences.slice(1).map(occurrence => (
          <SecondaryTableRow
            key={occurrence.id}
            even={index % 2 === 0}
            fields={{ id: item.id }}
            occurrence={occurrence}
            favourite={item.favorite}
          />
        ))}
      </>
    );
  }
  return (
    <PrimaryTableRow
      even={index % 2 === 0}
      fields={item}
      occurrence={item.occurrences && item.occurrences[0]}
      favourite={item.favorite}
      notifications={item.notifications}
      reportVersion={item.reportVersion}
      tasks={tasks}
    />
  );
};
