import { PropsWithChildren } from 'react';
import * as Yup from 'yup';
import { FormIdProvider } from '@/components/shared/Form/core/useFieldId';
import { Form, FormikValues } from 'formik';
import { Field } from '@/types';
import { FormikWithPrompt as Formik } from '@instech/components';
import { AnyObject } from 'yup/lib/types';
import { NewEntryInput } from './NewEntryInput';

interface Props<TModel> {
  formId?: string; // Need this for places that render multiple of these forms
  model: TModel & FormikValues;
  fields: Field[];
  onSubmit: (values: TModel) => Promise<void> | void;
  closeForm: () => void;
  validationSchema: Yup.ObjectSchema<AnyObject>;
  cancelIsReset?: boolean;
  className?: string;
}
function InputForm<TModel>({
  formId,
  model,
  fields,
  onSubmit,
  closeForm,
  validationSchema,
  cancelIsReset,
  className
}: PropsWithChildren<Props<TModel>>) {
  return (
    <Formik
      formId={formId}
      validationSchema={validationSchema}
      initialValues={model}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm();
      }}>
      {({ handleSubmit, isSubmitting, resetForm, dirty }) => (
        <FormIdProvider formId={formId || ''}>
          <Form data-testid="vessel-information-form">
            <NewEntryInput
              fields={fields}
              onSave={handleSubmit}
              disableSave={!dirty}
              onCancel={() => cancelIsReset ? resetForm() : closeForm()}
              isSubmitting={isSubmitting}
              cancelIsReset={cancelIsReset}
              className={className}
            />
          </Form>
        </FormIdProvider>
      )}
    </Formik>
  );
}

export { InputForm };
