import {
  Switch, Route, Redirect
} from 'react-router';
import {
  editRoute, dashboardRoute, reportPreviewRoute, reportViewRoute
} from '@/utils/routeTemplates';
import { ReportPreviewPage } from '../pages/report/ReportPreviewPage';
import { ReportViewPage } from '../pages/report/ReportViewPage';
import { EditPage } from '../pages/edit/EditPage';
import { SurveyorAssignmentsPage } from '../pages/assignments/AssignmentsPage';
import { SurveyorDashboardPage } from '../pages/Dashboard';
import { RequireEditAccess } from '../authentication/RequireEditAccess';

export const SurveyorRoutes = () => (
  <Switch>
    <Route exact path={reportPreviewRoute(':id')}>
      <ReportPreviewPage />
    </Route>
    <Route path={editRoute(':id')}>
      <RequireEditAccess>
        <EditPage />
      </RequireEditAccess>
    </Route>
    <Route exact path={reportViewRoute(':id', ':version')}>
      <ReportViewPage />
    </Route>
    <Route exact path={dashboardRoute(':id')}>
      <SurveyorDashboardPage />
    </Route>
    <Route exact path="/">
      <SurveyorAssignmentsPage />
    </Route>
    <Route path="*" render={() => <Redirect to="/" />} />
  </Switch>
);
