import { Nullable } from '@/types';
import { isNullishOrWhitespace } from '@/utils/text';
import { TruncatingText } from '@instech/components';
import { FC } from 'react';
import {
  FieldRow, FieldText, FieldTitle
} from './Components';

interface OccurrenceFieldProps {
  label: string;
  value: Nullable<string>;
}
export const OccurrenceField: FC<OccurrenceFieldProps> = ({ label, value = '-' }) => {
  const displayValue = (isNullishOrWhitespace(value)) ? '-' : value;
  return (
    <FieldRow>
      <FieldTitle>{label}</FieldTitle>
      <FieldText>
        <TruncatingText text={displayValue} padding="0px 10px 0px 0px" wrap />
      </FieldText>
    </FieldRow>
  );
};
