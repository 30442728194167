import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from 'styled-components';
import {
  FieldLayout, FieldLabel, ErrorMessage
} from '@/components/shared/Form/core/Components';

const ToggleGroup = styled.div`
  display: flex;
`;

const ToggleField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  line-height: 21px;

  & input[type="radio"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.border.gray};
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    box-sizing: border-box;
    appearance: none;

    &:checked {
      border-color: ${props => props.theme.marineBlue};
    }

    &:checked::after {
      content: '';
      display: block;
      padding: 5px;
      background: ${props => props.theme.marineBlue};
      border-radius: 50%;
    }
  }
`;

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.label
 * @param {boolean} [props.noErrors=false]
 * @param {Array<Object>} [props.options=[]]
 * @param {boolean} [props.isTouched=false]
 * @param {boolean} [props.isError=false]
 * @param {Function} props.onClickCallback
 */
export const ToggleButtons = ({ name, label, noErrors, options = [], isTouched = false, isError = false, onClickCallback }) => (
  <FieldLayout error={isTouched && isError}>
    <FieldLabel>{label}</FieldLabel>
    <ToggleGroup>
      {options.map(option => {
        const fieldName = `${name}-${option}`;
        return (
          <ToggleField key={fieldName} as="label" htmlFor={fieldName}>
            <Field id={fieldName} name={name} value={option} type="radio" onClick={onClickCallback ? () => onClickCallback(option) : null} />
            {option}
          </ToggleField>
        );
      })}
    </ToggleGroup>
    {noErrors ? '' : (
      <ErrorMessage>
        {isError && isTouched ? isError : ''}
      </ErrorMessage>
    )}
  </FieldLayout>
);
ToggleButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  noErrors: PropTypes.bool,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isTouched: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClickCallback: PropTypes.func
};
