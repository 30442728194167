import styled from 'styled-components';
import React from 'react';
import { ClaimOccurrenceOverview } from '@/types';
import { toReadableDate } from '@/components/pages/Dashboard/caseInformation/utils';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: ${props => props.theme.marineBlue};
    background-color: ${props => props.theme.marineBlue05};
    padding: 0px 32px;
    border-radius: 4px;
`;

const Bold = styled.p`
  font-weight: 700;
`;

export const OriginalClaim: React.FC<{claim: ClaimOccurrenceOverview}> = ({ claim }) => (
  <Container key={claim.id}>
    <div>
      <p>Claim number</p>
      <Bold>{claim.claimReference}</Bold>
    </div>
    <div>
      <p>Date of Loss</p>
      <Bold>{toReadableDate(claim.dateOfLoss)}</Bold>
    </div>
    <div>
      <p>Interest</p>
      <Bold>{claim.interest}</Bold>
    </div>
  </Container>
);
