import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.white};
`;

interface RowBackgroundProps {
  dark?: boolean;
  alert?: boolean;
  interestRow?: boolean;
}
export const RowBackground = styled.div<RowBackgroundProps>`
  position: relative;
  border: 3px solid transparent;
  // Sums to 32px when including border
  padding: 29px;
  ${props => props.dark && css`
    background-color: rgba(127,157,184,0.1);
  `};
  ${props => props.alert && css`
    background-color: ${props.theme.orange50};
    border: 3px solid ${props.theme.orange};
  `};
  ${props => props.interestRow && css`
    background-color: ${props.theme.background};
  `};
`;

export const RowContent = styled.div`
  display: grid;
  // 6 field columns and edit button
  grid-template-columns: repeat(6, 1fr) 40px;
`;
