import { useModalContext } from '@/components/modal/ModalContext';
import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { useClaimsOccurrenceOverview } from '@/services/claimsOccurrenceServices';
import { createOccurrenceAsync } from '@/services/occurrenceServices';
import { ClaimOccurrenceOverview, Guid } from '@/types';
import { Loader } from '@instech/components';
import { Formik } from 'formik';
import {
  FC, useMemo, useState
} from 'react';
import { isRelatedToYear } from '@/utils/claimUtils';
import { UpdateOccurrenceFunc } from '../../types';
import {
  OccurrenceSchema,
  occurrenceValidator,
  toRequest
} from '../OccurrenceValidator';
import {
  NoClaimsMessage,
  OccurrenceCharterDropdown,
  OccurrenceFormLayout,
  OccurrenceNameInput
} from '../core';
import {
  ModalActionButtonGroup,
  ModalFooter
} from '../core/ModalFooterComponents';
import { SelectableInterests } from '../SelectableInterests';
import { conditionalErrorLineMessage, ErrorLine } from '../core/ErrorLine';

const updateErrorMessage = 'Could not add an occurrence. Please try again later.';

export interface AddNewOccurrenceModalProps {
  caseMetaClaimId?: Guid | null;
  occurrenceClaimId: string | null;
  onOccurrencesUpdate: UpdateOccurrenceFunc;
  userCanEdit: boolean;
}

export const AddNewOccurrence: FC<AddNewOccurrenceModalProps> = ({ caseMetaClaimId, occurrenceClaimId, onOccurrencesUpdate, userCanEdit }) => {
  const claimIdToFetch = occurrenceClaimId || caseMetaClaimId;

  const [submitError, setSubmitError] = useState(false);
  const caseId = useCaseIdFromUrl();
  const { close } = useModalContext();
  const {
    data: claimsData,
    isValidating
  } = useClaimsOccurrenceOverview(claimIdToFetch);

  const claims: ClaimOccurrenceOverview[] = useMemo(() => (
    claimsData?.map(claim => ({ ...claim, disabled: false })) ?? []
  ), [claimsData]);

  const mainClaim = claimsData?.find(claim => claim.id === claimIdToFetch);
  const mainInsuranceYear = mainClaim?.insuranceYear ?? 0;
  const mainDateOfLossYear = mainClaim?.dateOfLoss ? new Date(mainClaim.dateOfLoss).getFullYear() : 0;

  const relevantClaims = claims
    .filter(claim => isRelatedToYear(claim, mainInsuranceYear) || isRelatedToYear(claim, mainDateOfLossYear));

  if (isValidating) {
    return <Loader />;
  }

  const handleSubmit = async (formData: OccurrenceSchema) => {
    setSubmitError(false);
    try {
      const occurrence = await createOccurrenceAsync(caseId, toRequest(formData));
      await onOccurrencesUpdate(occurrences => [...occurrences, occurrence]);
      close();
    } catch {
      setSubmitError(true);
    }
  };

  if (isValidating) {
    return <Loader />;
  }

  if (claims.length === 0) {
    return <NoClaimsMessage closeModal={close} />;
  }

  const initialValues: OccurrenceSchema = {
    occurrenceName: '',
    charter: null,
    claims: relevantClaims.map(x => ({
      claimId: x.id,
      deductible: '',
      claimInterest: x.claimInterest,
      selected: false
    }))
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={occurrenceValidator}>
      {({ errors, touched }) => (
        <div>
          <OccurrenceFormLayout>
            <OccurrenceNameInput />
            <OccurrenceCharterDropdown />
            <SelectableInterests claims={relevantClaims}
              label="Select interest type(s) to the occurrence." />
          </OccurrenceFormLayout>
          <ModalFooter>
            <ErrorLine
              hasError={submitError || !!errors.claims}
              message={conditionalErrorLineMessage([
                { condition: submitError, message: updateErrorMessage },
                { condition: !!(errors.claims && touched.claims), message: errors.claims }
              ])}
            />
            <ErrorLine hasError={submitError} message="" />
            <ModalActionButtonGroup submitButtonText="SAVE" />
          </ModalFooter>
        </div>
      )}
    </Formik>
  );
};

export const showAddNewOccurrenceModal = (props: AddNewOccurrenceModalProps) => ({
  component: AddNewOccurrence,
  options: {
    title: 'Add Occurrence and Interest(s)',
    size: 'medium',
    footer: false,
  },
  args: props
});
