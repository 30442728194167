import styled from 'styled-components';

export const NavContainer = styled.div`
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};  
`;

type NavWrapperOuterProps = {
  visible: boolean
}

export const NavWrapperOuter = styled.div<NavWrapperOuterProps>`
  display: ${props => props.visible && props.theme.breakpoints.tablet ? 'block' : 'none'};
  position: absolute;
  bottom: 0;
  top: 50px;
  left: 0;
  right: 0;
  min-height: calc(100vh - 100px);
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.tabletMax}) {
    background: white;
    overflow-y: auto;
  }
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
    position: relative;
    top: auto;
  };
`;

export const NavWrapperInner = styled.div`
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    position: relative;
  };
`;

export const ToolsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.border.white};
  background: ${props => props.theme.flatWhite};
`;

export const NavSections = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  };
`;
