import { ButtonGroup } from '@instech/components';
import { FC } from 'react';
import {
  AddButton,
  CloseButton,
  SaveButton
} from './FormButton';

interface AddSaveButtonsProps {
  addLabel?: string;
  saveLabel?: string;
  onAdd?: () => void;
  onSave?: () => void;
  disableSave?: boolean;
  isSubmitting?: boolean;
  inverted?: boolean;
  alignRight?: boolean;
  spaceBetween?: boolean;
  reverse?: boolean;
  marginTop?: string;
}
export const AddSaveButtons: FC<AddSaveButtonsProps> = ({
  addLabel,
  saveLabel,
  disableSave,
  isSubmitting,
  onAdd,
  onSave,
  inverted = false,
  alignRight,
  spaceBetween,
  reverse,
  marginTop
}) => (
  <ButtonGroup alignRight={alignRight} spaceBetween={spaceBetween} reverse={reverse} marginTop={marginTop}>
    <AddButton label={addLabel} onClick={onAdd} inverted={inverted} />
    <SaveButton label={saveLabel} disabled={disableSave} isSubmitting={isSubmitting} onClick={onSave} />
  </ButtonGroup>
);

interface SaveCloseButtonsProps {
  saveLabel?: string;
  closeLabel?: string;
  onSave?: () => void;
  onClose?: () => void;
  disableSave?: boolean;
  disableClose?: boolean;
  isSubmitting?: boolean;
  alignRight?: boolean;
  reverse?: boolean;
  marginTop?: string;
  hideCloseButton?: boolean;
}
export const SaveCloseButtons: FC<SaveCloseButtonsProps> = ({
  saveLabel,
  closeLabel,
  disableSave,
  disableClose,
  isSubmitting,
  onSave,
  onClose,
  alignRight,
  reverse,
  marginTop,
  hideCloseButton,
}) => (
  <ButtonGroup alignRight={alignRight} reverse={reverse} marginTop={marginTop}>
    <SaveButton label={saveLabel} disabled={disableSave} isSubmitting={isSubmitting} onClick={onSave} />
    {!hideCloseButton && <CloseButton label={closeLabel} disabled={disableClose} onClick={onClose} />}
  </ButtonGroup>
);
