import { StatusTypeDropdownField } from '@/components/shared/Form/FormFields';
import { AddSaveButtons } from '@/components/shared/FormButton/FormButtons';
import { TableHeader, TableRow } from '@/components/shared/Table';
import { ArrayTextField } from '@/components/shared/Table/ArrayFields';
import { useStatusTypes } from '@/services/configurationServices';
import { Guid, Occurrence } from '@/types';
import {
  DatePicker,
  FormikWithPrompt as Formik,
  Loader
} from '@instech/components';
import { FieldArray, Form } from 'formik';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CharterSelect } from './core/CharterSelect';
import { InterestRows } from './core/InterestRows';
import {
  OccurrenceContainer,
  OccurrenceListContainer
} from './core/OccurrenceContainer';
import { OccurrenceDeleteButton } from './core/OccurrenceDeleteButton';
import {
  HeaderText,
  SingleColumnTableHeader,
  Table,
  TableCell as Cell
} from './core/TableComponents';
import {
  OccurrenceFormSchema,
  occurrenceFormSchema,
  OccurrenceSchema
} from './core/occurrenceFormSchema';
import { charterOptions } from './core/options';
import { defaultOccurrence } from './core/utils';

const TableCell = styled(Cell)`
  padding: 20px 20px 4px;
`;

// Create initial state of existing entries or one new empty entry
const setInitialState = (occurrences: Occurrence[], initialElementId: Guid): OccurrenceFormSchema => {
  if (occurrences.length === 0) {
    return { occurrences: [defaultOccurrence(initialElementId)] };
  }

  const initialOccurrences = occurrences.map<OccurrenceSchema>(x => ({
    ...x,
    status: {
      label: x.status ?? '',
      value: x.status ?? '',
    }
  }));
  return occurrenceFormSchema.cast({ occurrences: initialOccurrences }, { stripUnknown: true });
};

interface OccurrenceTableProps {
  occurrences: Occurrence[];
  onSave: (newOccurrences: Occurrence[]) => Promise<Occurrence[]>;
  onDeleteOnServer: (occurrenceId: string) => Promise<void>;
}
export const OccurrenceTable: FunctionComponent<OccurrenceTableProps> = ({ occurrences = [], onSave, onDeleteOnServer }) => {
  /** 'initialItemId' is used to set the id of the default model - the empty
   * input row that is used if there are no items in the list. */
  const [initialItemId] = useState(uuidv4());
  const { data: statusTypes } = useStatusTypes();

  const existingOccurrences = occurrences.map(x => x.id);

  if (!statusTypes) return <Loader />;
  return (
    <Formik
      initialValues={setInitialState(occurrences, initialItemId)}
      onSubmit={async (values, { resetForm }) => {
        const payload = values.occurrences.map(x => ({
          ...x, status: x.status!.value,
        })) as Occurrence[];
        const result = await onSave(payload);
        if (result) {
          resetForm({ values: setInitialState(payload, initialItemId) });
        }
      }}
      validationSchema={occurrenceFormSchema}>
      {({ values, handleSubmit, dirty, isSubmitting }) => (
        <FieldArray
          name="occurrences"
          render={({ push, remove }) => (
            <Form>
              <OccurrenceListContainer>
                {values.occurrences.map((row, i) => (
                  <OccurrenceContainer key={row.id}>
                    <Table top>
                      <SingleColumnTableHeader>
                        <HeaderText>
                          Name for Occurrence #
                          {i+1}
                        </HeaderText>
                        <OccurrenceDeleteButton
                          row={row}
                          occurrences={existingOccurrences}
                          removeFromArray={() => remove(i)}
                          onDeleteOnServer={onDeleteOnServer}
                        />
                      </SingleColumnTableHeader>
                      <TableRow even>
                        <TableCell>
                          <ArrayTextField name={`occurrences.${i}.name`} placeholder="Name of new occurrence" />
                        </TableCell>
                      </TableRow>
                    </Table>
                    <Table bottom layout="repeat(4, 1fr)">
                      <TableHeader>Date of loss</TableHeader>
                      <TableHeader>Loss Location</TableHeader>
                      <TableHeader>Charter</TableHeader>
                      <TableHeader>Status</TableHeader>
                      <TableRow even>
                        <TableCell>
                          <DatePicker name={`occurrences.${i}.dateOfLoss`} />
                        </TableCell>
                        <TableCell>
                          <ArrayTextField name={`occurrences.${i}.location`} placeholder="Place and country" />
                        </TableCell>
                        <TableCell>
                          <CharterSelect name={`occurrences.${i}.charter`} options={charterOptions} />
                        </TableCell>
                        <TableCell>
                          <StatusTypeDropdownField name={`occurrences.${i}.status`} noLabel />
                        </TableCell>
                      </TableRow>
                    </Table>
                    <InterestRows occurrenceId={row.id} interests={row.interests ?? []} />
                  </OccurrenceContainer>
                ))}
              </OccurrenceListContainer>
              <AddSaveButtons
                addLabel="Occurrence"
                onAdd={() => push({ ...defaultOccurrence(uuidv4()) })}
                onSave={dirty ? handleSubmit : undefined}
                isSubmitting={isSubmitting}
                disableSave={!dirty}
                spaceBetween />
            </Form>
          )} />
      )}
    </Formik>
  );
};
