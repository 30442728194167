import { useCaseStatus } from '@/services/caseStatusServices';
import { Guid } from '@/types';
import { useLightboxContext } from '@instech/components';
import styled from 'styled-components';
import { FilesHiddenGdprMessage } from '../../shared/FilesHiddenGdprMessage';
import { ImageGridLightbox } from '../core/ImageGrid/ImageGridLightbox';
import { NoImagesUploaded } from '../core/NoImagesUploaded';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

interface ImageLibraryProps {
  imageIds: Guid[];
}
export const ImageLibraryReadOnly = ({ imageIds }: ImageLibraryProps) => {
  const { open: openInLightbox } = useLightboxContext();
  const { data: status } = useCaseStatus(true);
  const showGdprMessage = imageIds.length > 0 && status?.isClosed;

  if (imageIds.length < 1) {
    return <NoImagesUploaded />;
  }

  return (
    <Container>
      {showGdprMessage && <FilesHiddenGdprMessage />}
      <ImageGridLightbox
        imageIds={imageIds}
        onClick={openInLightbox}
        showPlaceHolderForPiiImages={status?.isClosed}
      />
    </Container>
  );
};
