import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Guid } from '@/types';
import useSWR from 'swr';
import { AxiosError } from 'axios';
import client from './client';

export interface EquipmentParticular {
  id: string;
  equipment: string;
  make: string;
  type: string;
  includeInReport: boolean;
}
export type NewEquipmentParticular = Omit<EquipmentParticular, 'id'>;

export const equipmentParticularApiUrl = (caseId: string) => `/case/${caseId}/equipmentparticulars`;

export const getEquipmentsParticularsAsync = async (caseId: string) => {
  const result = await client.get<EquipmentParticular[]>(equipmentParticularApiUrl(caseId));
  return result.data;
};

export const useEquipmentsParticulars = () => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<EquipmentParticular[], AxiosError>(equipmentParticularApiUrl(caseId));
  return swr;
};

export const addEquipmentsParticularsAsync = async (caseId: string, equipment: NewEquipmentParticular) => {
  const result = await client.post<EquipmentParticular>(equipmentParticularApiUrl(caseId), equipment);
  return result.data;
};

export const deleteEquipmentsParticularsAsync = async (caseId: string, itemId: string) => {
  await client.delete<null>(`${equipmentParticularApiUrl(caseId)}/${itemId}`);
};

export const updateEquipmentParticularsAsync = async (caseId: Guid, information: EquipmentParticular) => {
  const url = `${equipmentParticularApiUrl(caseId)}/${information.id}`;
  await client.put<EquipmentParticular>(url, information);
};

export const restoreDeletedEquipmentsParticularsAsync = async (caseId: string, itemId: string) => {
  const result = await client.post<EquipmentParticular[]>(`${equipmentParticularApiUrl(caseId)}/${itemId}`);
  return result.data;
};
