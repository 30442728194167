import styled, { css } from 'styled-components';

interface FieldRowProps {
  gridStart?: number;
  gridEnd?: number;
}
export const FieldRow = styled.div<FieldRowProps>`
  ${props => props.gridStart && css`
    grid-column-start: ${props.gridStart};
  `}
  ${props => props.gridEnd && css`
    grid-column-end: ${props.gridEnd};
  `}
  // This is needed to make the text truncation work in the children
  min-width: 0;
`;

export const FieldText = styled.div`
  color: ${props => props.theme.blue};
  font-size: 16px;
  line-height: 20px;
`;

export const FieldTitle = styled.div`
  display: flex;
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const FieldIcon = styled.div`
  height: 14px;
  margin-left: 4px;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
  svg {
    margin-top: -1px;
    height: 16px;
    width: 16px;
  }
`;
