import { EquipmentParticular } from '@/services/equipmentsParticularsServices';
import { Guid } from '@/types';
import { Loader } from '@instech/components';
import {
  FunctionComponent, useCallback, useState
} from 'react';
import styled from 'styled-components';
import { EquipmentRowEdit } from './EquipmentRowEdit';
import { EquipmentRowView } from './EquipmentRowView';

const ListContainer = styled.div`
  display: grid;
  grid-gap: 6px;
`;

interface EquipmentRowProps extends EquipmentParticular {
  onDelete: (id: string) => void;
  readonly?: boolean;
  toggleEditMode: () => void;
  isEditMode: boolean;
}
const EquipmentRow: FunctionComponent<EquipmentRowProps> = props => {
  if (props.isEditMode) {
    return <EquipmentRowEdit {...props} />;
  }
  return <EquipmentRowView {...props} />;
};

interface Props {
  entries: EquipmentParticular[];
  onDelete: (id: string) => void;
  readonly?: boolean;
}
export const EquipmentList: FunctionComponent<Props> = ({ entries, onDelete, readonly }) => {
  const [editItem, setEditItem] = useState<Guid | undefined>(undefined);
  const toggleEdit = useCallback(
    (id: Guid) => id === editItem
      ? setEditItem(undefined)
      : setEditItem(id),
    [editItem],
  );

  if (!entries) return <Loader />;

  return (
    <ListContainer>
      {entries.map(entry => (
        <EquipmentRow
          key={entry.id}
          onDelete={onDelete}
          readonly={readonly}
          toggleEditMode={() => toggleEdit(entry.id)}
          isEditMode={editItem === entry.id}
          {...entry}
        />
      ))}
    </ListContainer>
  );
};
