import { Interest } from '@/types';
import { FC } from 'react';
import styled from 'styled-components';
import { RefreshClaim } from '../ClaimsField';
import { OccurrenceField, DeductibleField } from '../OccurrenceField';
import { UpdateInterestFunc } from '../types';

const InterestList = styled.div`
  background-color: ${({ theme }) => theme.marineBlue05};
  padding-bottom: 0;
  box-shadow: inset 0 0 2px ${({ theme }) => theme.marineBlue50};
`;

const InterestRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.marineBlue20};
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto 2fr;
  padding: 16px 32px;
  align-items: center;

  :last-child {
    border: 0;
  }
`;

interface InterestRowsListProps {
  interests: Interest[];
  occurrenceId: string;
  updateInterest: UpdateInterestFunc;
  userCanEdit: boolean;
  hasEdited: number;
}
export const InterestRowsList: FC<InterestRowsListProps> = ({
  interests,
  occurrenceId,
  updateInterest,
  userCanEdit,
  hasEdited
}) => {
  if (interests.length === 0) {
    return null;
  }

  return (
    <InterestList>
      {interests.map(interest => (
        <InterestRow key={interest.claimReference}>
          <OccurrenceField label="Interest" value={interest.interest ?? ''} />
          <DeductibleField label="Deductible" interest={interest} />
          <OccurrenceField label="Our share" value={interest.ourShare ? `${interest.ourShare} %` : ''} />
          <OccurrenceField label="Claim Number" value={interest.claimReference} />
          <RefreshClaim
            occurrenceClaimId={interest.claimId}
            occurrenceId={occurrenceId}
            updateInterest={updateInterest}
            userCanEdit={userCanEdit}
            hasEdited={hasEdited} />
        </InterestRow>
      ))}
    </InterestList>
  );
};
