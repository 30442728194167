import { useCaseIdFromUrl } from '@/hooks/useCaseIdFromUrl';
import { Guid, UserInfo } from '@/types';
import { AxiosError } from 'axios';
import useSWR, { mutate } from 'swr';
import client, { getJsonAsync } from './client';

const apiRoute = (userInfoId: Guid | undefined, caseId: Guid) => userInfoId ? `/userinfo/${userInfoId}/cases/${caseId}` : null;

export const useUserInfo = (userInfoId?: Guid) => {
  const caseId = useCaseIdFromUrl();
  const swr = useSWR<UserInfo>(apiRoute(userInfoId, caseId));
  return swr;
};

export const useUserEditAccess = () => {
  const caseId = useCaseIdFromUrl();
  const url = `/userinfo/${caseId}/access`;
  return useSWR<boolean, AxiosError>(url);
};

const currentUserInfoApiUrl = '/userinfo/current';
export const useCurrentUserInfo = () => useSWR<UserInfo>(currentUserInfoApiUrl);

export const mutateCurrentUserInfoAsync = (newUserInfo: UserInfo, shouldRevalidate?: boolean) => mutate(currentUserInfoApiUrl, newUserInfo, shouldRevalidate);

export const acceptTermsOfUseCurrentUserAsync = async () => {
  const requestUrl = '/userinfo/current/accept-terms';

  return client.put<UserInfo>(requestUrl);
};

export const useAllowedUserInfoList = (role: string, claimId?: string | null, caseId?: string) => {
  const endpoint = 'userinfo';
  const casePrefix = caseId ? `/${caseId}` : '';
  // If case is created manually it does not have a claimId and should allow all registered users
  const url = claimId ? `${endpoint}/${claimId}/${role}${casePrefix}` : endpoint;

  const { data, error } = useSWR<UserInfo[]>(url, (args: string) => getJsonAsync(args)
    .then(response => response.map((user: UserInfo) => ({
      ...user,
      fullName: user.displayName
    })))
  );

  if (error) {
    throw error;
  }

  return data;
};

export const useAllSurveyors = () => {
  const url = '/userInfo/surveyors/';
  const { data, error } = useSWR<UserInfo[]>(url);
  if (error) {
    throw error;
  }
  return data;
};

export const useAllClaimsHandlers = () => {
  const url = '/userInfo/claimhandlers/assigned';
  const { data, error } = useSWR<UserInfo[]>(url);
  if (error) {
    throw error;
  }
  return data;
};

interface UpdateSurveyorsProps {
  surveyors: Guid[];
  primarySurveyorId: Guid;
}
export const updateCaseSurveyors = async (caseId: string, surveyors: Guid[], primarySurveyorId: Guid) => {
  const requestUrl = `/case/${caseId}/surveyors`;
  const response = await client.put<UpdateSurveyorsProps>(requestUrl, {
    surveyors,
    primarySurveyorId
  }).catch(exception => exception.response);
  await mutate(`/case/${caseId}`);
  return response;
};

export const updatePrimaryClaimsHandler = async (caseId: string, userId: Guid) => {
  await client.put(`case/${caseId}/primaryclaimshandler/${userId}`);
  await mutate(`/case/${caseId}`);
};
